import {
    Grid,
    FormHelperText,
    IconButton,
    FormControlLabel,
    Checkbox,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import React, { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { defaultDisaggregation, ADD_NEW_DISAGGREGATION_ID } from './constants';

const DisaggregationNode = (props: any) => {
    // const [disaggregationList, setDisaggregationList] = useState<any>([defaultDisaggregation]);

    const handleDisaggregationChange = (index: number, value: any) => {
        if (value == ADD_NEW_DISAGGREGATION_ID) {
            props.openNewNode();
            return;
        }
        console.log(' value: ', value);
        const list = [...props.data.disaggregationList];

        const option = _disaggregationTypes(index).find((d: any) => d.id === value);
        list.splice(index, 1, { ...option, index });
        props.onChange({ disaggregationList: list });
    };

    const addDisaggreagation = () => {
        const list = [...props.data.disaggregationList];
        list.push(defaultDisaggregation);
        props.onChange({ disaggregationList: list });
    };

    const removeDisaggregation = (index: number) => {
        const list = [...props.data.disaggregationList];
        list.splice(index, 1);
        props.onChange({ disaggregationList: list });
    };

    const _disaggregationTypes = (index: number): any[] => {
        console.log(props.data.disaggregationList);
        let needToRemove = props.data.disaggregationList.filter((d: any) => d.index != index).map((d: any) => d.id);
        needToRemove = new Set(needToRemove);
        return props.dTypes.filter((d: any) => !needToRemove.has(d.id));
    };

    return (
        <Grid item xs={12}>
            <SubCard>
                <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControlLabel control={<Checkbox defaultChecked color="secondary" />} label="Mandatory" />

                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={props.deleteNode}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>

                    {props.data.disaggregationList.map((value: any, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={4}>
                                    {' '}
                                </Grid>
                                <Grid item xs={6} key={index}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Type"
                                            // open={false}
                                            value={value.id}
                                            onChange={(e: any) => handleDisaggregationChange(index, e.target.value)}
                                        >
                                            {_disaggregationTypes(index).map((d) => {
                                                return (
                                                    <MenuItem value={d.id} key={d.id}>
                                                        {d.label}
                                                    </MenuItem>
                                                );
                                            })}
                                            <MenuItem
                                                value={ADD_NEW_DISAGGREGATION_ID}
                                                key={ADD_NEW_DISAGGREGATION_ID}
                                                style={{ color: '#673ab7' }}
                                            >
                                                {' '}
                                                <AddIcon /> Add New Disaggregation Type
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText>Select Disaggregation Type</FormHelperText>
                                </Grid>
                                <Grid item lg={2}>
                                    {index !== 0 && (
                                        <IconButton color="primary" onClick={() => removeDisaggregation(index)}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }} />
                                <Grid item xs={12} sm={9} lg={6}>
                                    {props.dValues
                                        .filter((d: any) => d.parent == value.id)
                                        .map((d: any, dValueIndex: number) => (
                                            <FormControlLabel
                                                key={dValueIndex}
                                                control={<Checkbox defaultChecked disabled />}
                                                label={d.label}
                                            />
                                        ))}
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                    <Grid item xs={4}>
                        {' '}
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" startIcon={<AddIcon />} fullWidth onClick={addDisaggreagation}>
                            Add New
                        </Button>
                    </Grid>
                </Grid>
            </SubCard>
        </Grid>
    );
};

export default DisaggregationNode;
