import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const defaultConditions = {
    description: '',
    value: 0,
    year: "",

}
export default function Conditions(props: any) {

    const [conditions, setConditions] = useState<any>([defaultConditions]);
    const optionValueChange = (index: number, e: any) => {
        const tempOptions = [...conditions];
        let opt = tempOptions[index];
        opt = { ...opt, [e.target.name]: e.target.value };
        tempOptions[index] = opt;
        setConditions(tempOptions);
    }

    const removeOption = (index: any) => {
        const opt = [...conditions];
        opt.splice(index, 1);
        setConditions(opt);
    }

    const addOption = () => {
        const opt = [...conditions];
        opt.push({
            id: conditions.length + 1,
            description: '',
            value: 0,
            year: "",

        });
        setConditions(opt);
    }
    return (
        <Grid item xs={12}>

            <SubCard title="Conditions">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>#</TableCell>
                                <TableCell align='center'>Column</TableCell>
                                <TableCell align='center'>Function</TableCell>
                                <TableCell align='center'>Value(s)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                conditions.map((row: any, i: any) => (
                                    <TableRow hover key={i}>
                                        <TableCell sx={{ pl: 3 }}>{i + 1}</TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                value={row.description}
                                                type='text'
                                                name="description"
                                                fullWidth
                                                onChange={(e: any) => optionValueChange(i, e)}
                                                variant="standard"
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <TextField
                                                value={row.year}
                                                name="year"
                                                fullWidth
                                                onChange={(e: any) => optionValueChange(i, e)}
                                                variant="standard"
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between' }}>
                                                <TextField
                                                    value={row.value}
                                                    name="value"
                                                    fullWidth

                                                    onChange={(e: any) => optionValueChange(i, e)}
                                                    variant="standard"
                                                />
                                                {/* <TextField
                                                    value={row.value}
                                                    name="value"
                                                    fullWidth
                                                    style={{ marginLeft: '2%' }}
                                                    onChange={(e: any) => optionValueChange(i, e)}
                                                    variant="standard"
                                                /> */}
                                            </div>

                                        </TableCell>
                                        <TableCell align='center'>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <Tooltip placement="top" title="Delete">
                                                    <IconButton color="error" aria-label="delete" size="large" onClick={() => removeOption(i)}>
                                                        <DeleteForeverIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container xs={12} justifyContent='end'>
                    <Grid item marginY={1} marginRight='26px'>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <Tooltip placement="top" title="Add">
                                <IconButton color="primary" aria-label="add" size="large" onClick={addOption}>
                                    <AddBoxIcon sx={{ fontSize: '1.5rem' }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </SubCard>

        </Grid>
    );
}
