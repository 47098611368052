import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import SubCard from 'ui-component/cards/SubCard';

const Milestones = (props: any) => {
    const deleteData = (index: number) => {
        const list = [...props.data.data];
        list.splice(index, 1);
        props.setData({ ...props.data, data: list });
    };

    const addData = () => {
        const list = [...props.data.data];
        list.push({ title: '', planDate: '' });
        props.setData({ ...props.data, data: list });
    };

    const editData = (event: any, index: number) => {
        const list = [...props.data.data];
        const item = list[index];
        list.splice(index, 1, { ...item, [event.target.name]: event.target.value });
        props.setData({ ...props.data, data: list });
    };

    console.log('milestones', props.data.data);
    return (
        <SubCard>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.data.map((row: any, i: number) => (
                            <TableRow hover key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell align="center">
                                    <TextField
                                        name="title"
                                        fullWidth
                                        variant="standard"
                                        value={row.title}
                                        onChange={(e: any) => editData(e, i)}
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </TableCell>

                                <TableCell align="center">
                                    <TextField
                                        name="planDate"
                                        type="date"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e: any) => editData(e, i)}
                                        value={row.planDate}
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton color="error" aria-label="delete" size="large" onClick={() => deleteData(i)}>
                                                <DeleteForeverIcon sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <IconButton color="success" aria-label="delete" size="large" onClick={addData}>
                    <AddIcon sx={{ fontSize: '1.3rem' }} />
                </IconButton>
            </div>
        </SubCard>
    );
};

export default Milestones;
