import {ExpenditurePayload} from "../types";

export const BASEURL = process.env.REACT_APP_SERVICE_URL;
export const IMAGEPATH = process.env.IMAGE_BASE_URL;

export const PAGE_SIZE = 10;
export const CURRENT_PAGE = 1;
export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

export const STATUS_COLOR: { [key: string]: string } = {
    "new": 'secondary',
    "inProgress": "primary",
    "done": "success"
}


export const EXPENDITURES: ExpenditurePayload[] = [
    {
        "id": "id_1",
        "title": "Grocery Shopping",
        "date": "2023-12-12 08:30:00 AM",
        "amount": "50.00",
        "activityId": "activityId_1"
    },
    {
        "id": "id_2",
        "title": "Gas Refill",
        "date": "2023-12-11 03:45:00 PM",
        "amount": "30.00",
        "activityId": "activityId_2"
    },
    {
        "id": "id_3",
        "title": "Dinner with Friends",
        "date": "2023-12-10 07:00:00 PM",
        "amount": "75.50",
        "activityId": "activityId_3"
    },
    {
        "id": "id_4",
        "title": "Movie Night",
        "date": "2023-12-09 09:15:00 PM",
        "amount": "20.00",
        "activityId": "activityId_4"
    },
    {
        "id": "id_5",
        "title": "Weekend Getaway",
        "date": "2023-12-08 12:00:00 PM",
        "amount": "200.00",
        "activityId": "activityId_5"
    },
    {
        "id": "id_6",
        "title": "Home Decor",
        "date": "2023-12-07 10:30:00 AM",
        "amount": "120.75",
        "activityId": "activityId_6"
    },
    {
        "id": "id_7",
        "title": "Fitness Class",
        "date": "2023-12-06 06:00:00 PM",
        "amount": "35.00",
        "activityId": "activityId_7"
    },
    {
        "id": "id_8",
        "title": "Tech Gadgets",
        "date": "2023-12-05 02:45:00 PM",
        "amount": "90.50",
        "activityId": "activityId_8"
    },
    {
        "id": "id_9",
        "title": "Coffee Meeting",
        "date": "2023-12-04 04:15:00 PM",
        "amount": "10.25",
        "activityId": "activityId_9"
    },
    {
        "id": "id_10",
        "title": "Bookstore Haul",
        "date": "2023-12-03 11:30:00 AM",
        "amount": "45.75",
        "activityId": "activityId_10"
    }
]
