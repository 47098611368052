import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from 'react_query/query-client-configuration';
import { collectionFrequencyKeys } from 'react_query/query-keys';
import { deleteCollectionFrequency } from 'services/reactQueryservices';

const useCollectionFrequencyDelete = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: deleteCollectionFrequency,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to delete Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: collectionFrequencyKeys.all });
                snackbar('Successfully deleted');
            }
        }
    });

    return {
        deleteCollectionFrequencySynchronously: mutate,
        deleteCollectionFrequencyAsynchronously: mutateAsync,
        isCollectionFrequencyDeleteLoading: isLoading,
        collectionFrequencyDeleteError: error,
        isCollectionFrequencyDeleteError: isError,
        isCollectionFrequencyDeleteSuccess: isSuccess
    };
};

export default useCollectionFrequencyDelete;
