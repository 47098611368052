import Jsona from 'jsona';

const dataFormatter = new Jsona();

const confIconStyle = { marginLeft: '5px' };

const IconBtnStyle = { padding: 0, margin: 0 };

const logHeaderCellStyle: any = {
    minWidth: '17vh',
    borderRight: '1px solid lightgrey',
    padding: '2vh',
    fontWeight: 'bold',
    textAlign: 'right'
};

const logHeaderSectionStyle: any = { display: 'flex', justifyContent: 'space-between', backgroundColor: '#B3E5FC' };

const rowCellStyle: any = { minWidth: '16vh', textAlign: 'right' };

const rowCellBarStyle: any = { paddingLeft: '30px' };

const defaultConfig: any = {
    config: {
        form_json: {
            form_url: ''
        },
        name: ''
    },
    node: { name: '' }
};

const TreeData: any = {
    log_frame: [
        {
            lf_node_type: 'program',
            lf_node_code: '',
            lf_node_type_id: 51,
            lf_node: 'USAIDs Youth Entrepreneurship and Empowerment Support Activity for CoxsBazar  ',
            lf_node_id: 1,
            child: [
                {
                    lf_node_type: 'Goal',
                    lf_node_type_id: 52,
                    lf_node:
                        'Increased market-oriented vocational skills for in school and out of school youth, delivered within a conflict-sensitive framework that promotes social cohesion.',
                    lf_node_code: '01',
                    lf_node_id: 1,
                    child: [
                        {
                            lf_node_type: 'outcome',
                            lf_node_type_id: 53,
                            lf_node: '# youth participants that have increased market-oriented vocational skills',
                            lf_node_code: '1.1',
                            lf_node_id: 15
                        },
                        {
                            lf_node_type: 'outcome',
                            lf_node_type_id: 53,
                            lf_node:
                                'Youth receive market relevant and conflict-sensitive vocational training and are linked to employment opportunities',
                            lf_node_code: '1.1',
                            lf_node_id: 15,
                            child: [
                                {
                                    lf_node_type: 'activity',
                                    lf_node_type_id: 54,
                                    lf_node: 'Provide training on MEAL Platform',
                                    lf_node_code: '1.1.1',
                                    lf_node_id: 29
                                },
                                {
                                    lf_node_type: 'activity',
                                    lf_node_type_id: 54,
                                    lf_node:
                                        'Provide training on CLOTH Platform  Platform  Platform Platform Platform Platform Platform Platform Platform Platform Platform',
                                    lf_node_code: '1.1.2',
                                    lf_node_id: 30
                                },
                                {
                                    lf_node_type: 'activity',
                                    lf_node_type_id: 54,
                                    lf_node: 'Provide training on SOCIETY Platform',
                                    lf_node_code: '1.1.1',
                                    lf_node_id: 30
                                }
                            ]
                        },
                        {
                            lf_node_type: 'outcome',
                            lf_node_type_id: 53,
                            lf_node: 'Youth work with community leaders to implement initiatives that promote social cohesion.',
                            lf_node_code: '1.2',
                            lf_node_id: 15
                        }
                    ]
                },
                {
                    lf_node_type: 'Goal',
                    lf_node_type_id: 52,
                    lf_node:
                        'Enhanced, applicable research on innovative approaches to tackle social and economic drivers leading to discrimination, marginalization and conflict between communities. i',
                    lf_node_code: '02',
                    lf_node_id: 15
                },
                {
                    lf_node_type: 'Goal',
                    lf_node_type_id: 52,
                    lf_node:
                        'Enhanced, applicable research on innovative approaches to tackle social and economic drivers leading to discrimination, marginalization and conflict between communities. ion and conflict between communities.ion and conflict between communities. tackle social and economic drivers leading to discrimination, marginalization and conflict between communities. ion and conflict between communities.ion and conflict between communities.',
                    lf_node_code: '03',
                    lf_node_id: 18
                }
            ]
        }
    ],
    meta: {
        lf_node_type: 'program',
        lf_node_type_id: 51,
        text_color: 'grey',
        background_color: 'purple',
        text_style: ['bole', 'italic'],
        child: [
            {
                lf_node_type: 'Goal',
                lf_node_type_id: 52,
                text_color: 'grey',
                background_color: 'green',
                text_style: ['bold', 'italic'],
                child: [
                    {
                        lf_node_type: 'outcome',
                        lf_node_type_id: 53,
                        text_color: 'grey',
                        background_color: 'yellow',
                        text_style: [],
                        child: [
                            {
                                lf_node_type: 'activity',
                                lf_node_type_id: 54,
                                text_color: 'grey',
                                background_color: 'brown',
                                text_style: []
                            },
                            {
                                lf_node_type: 'indicator',
                                lf_node_type_id: 55,
                                text_color: 'grey',
                                background_color: 'blue',
                                text_style: []
                            }
                        ]
                    }
                ]
            }
        ]
    }
};

const deserialize = (data: any) => {
    const newOb: any = dataFormatter.deserialize(data);
    if (newOb.child.length > 0) {
        const child: any = [];
        for (let i = 0; i < newOb.child.length; i += 1) {
            child.push(deserialize(newOb.child[i]));
        }
        delete newOb.child;
        newOb.child = child;
        return newOb;
    }
    return newOb;
};

/**
 *  this method find the parent-chain of a specific node.
 */
const getNodeParents = (logframe: any, desiredNodeId: any, result: any) => {
    if (logframe.length === 0) return result;
    for (let i = 0; i < logframe.length; i += 1) {
        if (desiredNodeId == logframe[i].id) {
            result.push({ type: logframe[i].type, title: logframe[i] });
            return result;
        }
        if (getNodeParents(logframe[i].child, desiredNodeId, result).length > 0) {
            result.push({ type: logframe[i].type, title: logframe[i] });
            return result;
        }
    }
    return result;
};

const goToSettings = (navigate: any, projectId: number, row: any) => {
    console.log(' going to settings page ', row);
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/indicator-settings`);
};

const goToDataCollection = (navigate: any, projectId: number, row: any) => {
    console.log(' going to settings page ', row);
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/data-collection`);
};

const goToProfile = (navigate: any, projectId: number, row: any) => {
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/indicator-profile`);
};

const goToActivityProfile = (navigate: any, projectId: number, row: any) => {
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/activity-profile`);
};

const goToTargets = (navigate: any, projectId: number, row: any) => {
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/targets`);
};

const goToDataUploadConfig = (navigate: any, projectId: number, row: any) => {
    navigate(`/projects/${projectId}/profile/logframe/${row.node.uid}/data-upload`);
};

const getFormattedDate = (date: any) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}/${mm}/${dd}`;
};

export default TreeData;

export {
    deserialize,
    getNodeParents,
    defaultConfig,
    confIconStyle,
    logHeaderCellStyle,
    logHeaderSectionStyle,
    rowCellStyle,
    rowCellBarStyle,
    IconBtnStyle,
    goToSettings,
    goToDataCollection,
    goToProfile,
    goToActivityProfile,
    goToTargets,
    goToDataUploadConfig,
    getFormattedDate
};

// export const treeConfig: any = [{
//     "id": "1",
//     "name": "Outcome",
//     "child": [
//         {
//             "id": "2",
//             "name": "Output",
//             "child": [
//                 {
//                     "id": "4",
//                     "name": "Output Indicator",
//                     "form_json": {
//                         "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//                         "form_response": {
//                             "id": "330",
//                             "url": "dynamic-bahis.mpower-social.com",
//                             "username": "fao_admin"
//                         }
//                     }
//                 },
//                 {
//                     "id": "5",
//                     "name": "Activity",
//                     "form_json": {
//                         "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//                         "form_url_request_body": {
//                             "id": "320",
//                             "url": "dynamic-bahis.mpower-social.com",
//                             "username": "fao_admin"
//                         }
//                     }
//                 }
//             ],
//             "form_json": {
//                 "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//                 "form_url_request_body": {
//                     "id": "330",
//                     "url": "dynamic-bahis.mpower-social.com",
//                     "username": "fao_admin"
//                 }
//             }
//         },
//         {
//             "id": "3",
//             "name": "Outcome Indicator",
//             "form_json": {
//                 "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//                 "form_url_request_body": {
//                     "id": "330",
//                     "url": "dynamic-bahis.mpower-social.com",
//                     "username": "fao_admin"
//                 }
//             }
//         }
//     ],
//     "form_json": {
//         "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//         "form_url_request_body": {
//             "id": "330",
//             "url": "dynamic-bahis.mpower-social.com",
//             "username": "fao_admin"
//         }
//     }
// },
// {
//     "id": "10",
//     "name": "Objective",
//     "form_json": {
//         "form_url": "http://dynamic-bahis.mpower-social.com/fao_admin/form_attributes",
//         "form_url_request_body": {
//             "id": "330",
//             "url": "dynamic-bahis.mpower-social.com",
//             "username": "fao_admin"
//         }
//     }
// }]
// export treeConfig;

export const treeConfig = {
    data: {
        type: 'logframeStructures',
        id: '38',
        attributes: {
            id: 38,
            nodeJson: {
                id: '1',
                data: {
                    name: 'Root',
                    color: '#f1c751',
                    level: 1,
                    width: 150,
                    description: 'root node'
                },
                type: 'root',
                children: ['1_kxfpf4p088cfvqibwkm'],
                position: {
                    x: 598,
                    y: 2
                },
                sourcePosition: 'bottom'
            },
            child: [
                {
                    data: {
                        type: 'logframeStructures',
                        id: '41',
                        attributes: {
                            id: 41,
                            nodeJson: {
                                id: '1_kxfpf4p088cfvqibwkm',
                                data: {
                                    name: 'huawei',
                                    color: 'red',
                                    level: 2,
                                    parent: 'Root',
                                    description: ''
                                },
                                type: 'childNode',
                                children: [],
                                position: {
                                    x: 300,
                                    y: 92
                                }
                            },
                            child: []
                        }
                    }
                },
                {
                    data: {
                        type: 'logframeStructures',
                        id: '40',
                        attributes: {
                            id: 40,
                            nodeJson: {
                                id: '1_kxerp1yocgisb4ifbn',
                                data: {
                                    name: 'google2',
                                    color: 'red',
                                    level: 2,
                                    parent: 'Root',
                                    description: ''
                                },
                                type: 'childNode',
                                children: [],
                                position: {
                                    x: 300,
                                    y: 92
                                }
                            },
                            child: [
                                {
                                    data: {
                                        type: 'logframeStructures',
                                        id: '44',
                                        attributes: {
                                            id: 44,
                                            nodeJson: {
                                                id: '1_kxerp1yocgisb4ifbn_kxfnwxan0xuilda9whfr',
                                                data: {
                                                    name: 'pixel series',
                                                    color: 'red',
                                                    level: 3,
                                                    width: 640,
                                                    parent: 'google2',
                                                    form_url: '',
                                                    description: ''
                                                },
                                                type: 'childNode',
                                                children: [],
                                                position: {
                                                    x: 304,
                                                    y: 280
                                                }
                                            },
                                            child: []
                                        }
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    data: {
                        type: 'logframeStructures',
                        id: '39',
                        attributes: {
                            id: 39,
                            nodeJson: {
                                id: '1_kxeroqig63i8krpaq0g',
                                data: {
                                    name: 'samsung',
                                    color: 'red',
                                    level: 2,
                                    width: 1302,
                                    parent: 'Root',
                                    description: ''
                                },
                                type: 'childNode',
                                children: [],
                                position: {
                                    x: 0,
                                    y: 92
                                }
                            },
                            child: [
                                {
                                    data: {
                                        type: 'logframeStructures',
                                        id: '45',
                                        attributes: {
                                            id: 45,
                                            nodeJson: {
                                                id: '1_kxeroqig63i8krpaq0g_kxfo2cm7o2t3ry3c34',
                                                data: {
                                                    name: 'galaxy',
                                                    color: 'red',
                                                    level: 3,
                                                    width: '150',
                                                    parent: 'samsung',
                                                    form_url: '',
                                                    description: ''
                                                },
                                                type: 'childNode',
                                                children: [],
                                                position: {
                                                    x: 300,
                                                    y: 232
                                                }
                                            },
                                            child: []
                                        }
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    data: {
                        type: 'logframeStructures',
                        id: '42',
                        attributes: {
                            id: 42,
                            nodeJson: {
                                id: '1_kxfnrz6503iypxve3l9c',
                                data: {
                                    name: 'dell',
                                    color: 'red',
                                    level: 2,
                                    width: 640,
                                    parent: 'Root',
                                    description: ''
                                },
                                type: 'childNode',
                                children: [],
                                position: {
                                    x: 662,
                                    y: 92
                                }
                            },
                            child: [
                                {
                                    data: {
                                        type: 'logframeStructures',
                                        id: '43',
                                        attributes: {
                                            id: 43,
                                            nodeJson: {
                                                id: '1_kxfnrz6503iypxve3l9c_kxfnscyx76dh4wicoup',
                                                data: {
                                                    name: 'xps series',
                                                    color: 'red',
                                                    level: 3,
                                                    width: 419,
                                                    parent: 'dell',
                                                    form_url: '',
                                                    description: ''
                                                },
                                                type: 'childNode',
                                                children: [],
                                                position: {
                                                    x: 738,
                                                    y: 264
                                                }
                                            },
                                            child: []
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }
            ]
        }
    }
};

export const sampleForm: any = {
    json: {
        name: 'staff',
        title: 'Staff',
        sms_keyword: 'staff',
        default_language: 'English',
        id_string: 'staff',
        type: 'survey',
        children: [
            {
                type: 'start',
                name: 'start'
            },
            {
                type: 'end',
                name: 'end'
            },
            {
                type: 'username',
                name: 'username'
            },
            {
                control: {
                    appearance: "w6 search('geo') minimal, searchDB@division"
                },
                name: 'division',
                bind: {
                    required: 'TRUE'
                },
                label: {
                    Bangla: 'Division',
                    English: '2. Division'
                },
                type: 'select one',
                children: [
                    {
                        name: 'division_code',
                        label: {
                            Bangla: 'division_name',
                            English: 'division_name'
                        }
                    }
                ]
            },
            {
                control: {
                    appearance: "w6 search('geo', 'matches', 'division_code', ${division}) minimal, searchDB@district"
                },
                name: 'district',
                bind: {
                    required: 'TRUE'
                },
                label: {
                    Bangla: 'District',
                    English: '3. District'
                },
                type: 'select one',
                children: [
                    {
                        name: 'district_code',
                        label: {
                            Bangla: 'district_name',
                            English: 'district_name'
                        }
                    }
                ]
            },
            {
                control: {
                    appearance: "w6 search('geo', 'matches', 'district_code', ${district}) minimal, searchDB@upazila"
                },
                name: 'upazila',
                bind: {
                    required: 'TRUE'
                },
                label: {
                    Bangla: 'Upazila',
                    English: '4. Upazila'
                },
                type: 'select one',
                children: [
                    {
                        name: 'upazila_code',
                        label: {
                            Bangla: 'upazila_name',
                            English: 'upazila_name'
                        }
                    }
                ]
            },
            {
                bind: {
                    required: 'TRUE'
                },
                type: 'integer',
                name: 'staffid',
                label: {
                    Bangla: 'Staff ID',
                    English: 'Staff ID'
                }
            },
            {
                bind: {
                    required: 'TRUE'
                },
                type: 'text',
                name: 'staffname',
                label: {
                    Bangla: 'Staff Name',
                    English: 'Staff Name'
                }
            },
            {
                control: {
                    bodyless: true
                },
                type: 'group',
                children: [
                    {
                        bind: {
                            readonly: 'true()',
                            calculate: "concat('uuid:', uuid())"
                        },
                        type: 'calculate',
                        name: 'instanceID'
                    }
                ],
                name: 'meta'
            }
        ]
    },
    uuid: 'ec0e6ae182b242b1a88738ee90aaae10',
    id_string: 'staff',
    submission_url: 'http://dynamic-bahis.mpower-social.com/fao_admin/submission',
    csv: 'http://dynamic-bahis.mpower-social.com/bhmodule/get-datasource-csv/327/',
    data_json: {
        username: 'fao_admin'
    }
};
