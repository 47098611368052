import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import LinearProgressBar from 'components/common/LinearProgressBar';
import LinearProgressBarWithLabel from 'components/common/LinearProgressBarWithLabel';
import SDGTagList from 'features/Logframe/SDGTagList';
import React, { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import { indicatorSettingsDataConverter } from './constant';

export default function IndicatorInfo({ indicatorInfo, indicatorDetail }: any) {
    const [settings, setSettings] = useState<any>([]);

    useEffect(() => {
        if ('settings' in indicatorInfo) {
            let uomList = [];
            if ('uomList' in indicatorInfo) {
                uomList = indicatorInfo.uomList;
            }
            let frequencyList = [];
            if ('frequencyList' in indicatorInfo) {
                frequencyList = indicatorInfo.frequencyList;
            }
            setSettings(indicatorSettingsDataConverter(indicatorInfo.settings, uomList, frequencyList));
        }
    }, [indicatorInfo]);

    return (
        <>
            <SubCard title="Indicator Info">
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                sx={{
                                    '& td': {
                                        borderBottom: 'none'
                                    }
                                }}
                                size="small"
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell variant="head">Indicator Name</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{indicatorDetail.title}</TableCell>
                                    </TableRow>
                                    {settings.map((data: any) => (
                                        <TableRow key={data.id}>
                                            <TableCell variant="head">{data.name}</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>{data.value}</TableCell>
                                        </TableRow>
                                    ))}
                                    {indicatorDetail?.logframeStructure?.isSdgBased && (
                                        <TableRow>
                                            <TableCell variant="head">SDG</TableCell>
                                            <TableCell>:</TableCell>
                                            <TableCell>
                                                {indicatorDetail.logframeSDGs && <SDGTagList sdgList={indicatorDetail.logframeSDGs} />}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell variant="head">Progress</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>
                                            <LinearProgressBarWithLabel
                                                name={'Achieved'}
                                                value={indicatorDetail.progress}
                                                designObj={{ p: 1 }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </SubCard>
        </>
    );
}
