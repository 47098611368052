import React from "react";
import IndicatorActivity from "./Indicators";
import Milestones from "./Milestones";
import SubActivities from "./SubActivities";
import IndicatorActivityView from "./Indicators/view";
import MilestonesView from "./Milestones/view";
import SubActivitiesView from "./SubActivities/view";

const AddImplementationMeasure = (props: any) => {

    const renderImplementation = () => {
        const type = props.type && JSON.parse(props.type).id;
        switch (type) {
            case 1:
                return <IndicatorActivity {...props} />;

            case 2:
                return <Milestones {...props} />

            case 3:
                return <SubActivities {...props} />

            default:
                return null;

        }
    }
    return <> {renderImplementation()} </>;
}

const ViewImplementationMeasure = (props: any) => {

    const renderImplementation = () => {
        const type = props.activity.ActivityImplementationMeasure.id;
        switch (type) {
            case 1:
                return <IndicatorActivityView {...props} />;

            case 2:
                return <MilestonesView {...props} />

            case 3:
                return <SubActivitiesView {...props} />

            default:
                return null;

        }
    }
    return (<>{renderImplementation()}</>);
}

export { AddImplementationMeasure, ViewImplementationMeasure };