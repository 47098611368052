import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IReportingPeriod } from 'types';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ReportingPeriodModal from './Modal';
import ClientPagingTable from 'components/Table/ClientPagingTable';
import Loader from 'ui-component/Loader';
import useGetReportingPeriodListByProjectId from 'hooks/consume_api/query/useGetReportingPeriodListByProjectId';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import useReportingPeriodDelete from 'hooks/consume_api/mutation/useReportingPeriodDelete';
import { columnListNameReportingPeriod, dataColumns } from '../Constant';

interface ReportingPeriodComponentType {
    reportingPeriodTypeId: string;
}

const ReportingPeriod = ({ reportingPeriodTypeId }: ReportingPeriodComponentType) => {
    const theme = useTheme();
    const { reportingPeriodList, isFetching } = useGetReportingPeriodListByProjectId(reportingPeriodTypeId, true);
    const { deleteReportingPeriodAsynchronously } = useReportingPeriodDelete();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedData, setSelectedData] = useState<IReportingPeriod | null>(null);

    const handleDeleteConfirm = async () => {
        await deleteReportingPeriodAsynchronously({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsEditMode(false);
    };

    const modalStyle = {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        overflowY: 'auto' // Enable vertical scrolling if content exceeds the modal height
    };

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx={{ padding: 0, margin: 2 }}
            style={modalStyle}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Reporting Period List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Reporting Period
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : reportingPeriodList.length !== 0 ? (
                <CardContent>
                    <ClientPagingTable
                        data={reportingPeriodList}
                        columns={dataColumns(
                            theme,
                            columnListNameReportingPeriod,
                            setSelectedData,
                            setIsModalOpen,
                            setIsDeleteModalOpen,
                            setIsEditMode
                        )}
                        showSL
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Reporting Period data found</Typography>
                </CardContent>
            )}

            {isModalOpen && (
                <ReportingPeriodModal
                    obj={selectedData}
                    isEditMode={isEditMode}
                    setIsModalOpen={setIsModalOpen}
                    handleModalClose={handleModalClose}
                    isModalOpen={isModalOpen}
                    reportingPeriodTypeId={reportingPeriodTypeId!}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Reporting Period '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default ReportingPeriod;
