import { Card, Container, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { GET_INDICATOR_COLLECTION_DATA, INDICATOR_TARGET, LOGFRAME_INFO_URL } from 'utils/serverURLS';
import IndicatorInfo from './IndicatorInfo';
import Jsona from 'jsona';
import { getLogframeDetail } from 'features/Logframe/Service';
import {
    getCollectionFrequencyListOfProject,
    getIndicatorSettingOfLogframe,
    getUOMListOfProject
} from 'features/IndicatorSettings/Service';
import { defaultIndicatorInfoData, IndicatorInfoType } from './constant';
import Targets from 'features/Targets';
import ContributingIndicator from './ContributingIndicator';
import { StatusCodes } from 'http-status-codes';
import Wrapper from 'components/Card/Wrapper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const dataFormatter = new Jsona();

export default function IndicatorProfile() {
    const { projectId, logframeId } = useParams();
    const [indicatorDetail, setIndicatorDetail] = useState<any>({});
    const [isAggregatedIndicator, setIsAggregatedIndicator] = useState<boolean>(false);
    const [targets, setTarget] = useState<any>([]);
    const [collectionData, setCollectionData] = useState<any>([]);
    const [indicatorInfo, setIndicatorInfo] = useState<IndicatorInfoType>(defaultIndicatorInfoData);
    const auth = useAuth();

    const logFrameInfo = (indicator: any) => {
        const indicatorInfoData: any = [];
        const getinfo = (log: any) => {
            console.log('log ===>>> ', log);
            for (const l of log) {
                const serialized: any = dataFormatter.deserialize(l);
                console.log('serialized ===>>> ', serialized);

                indicatorInfoData.push({ label: serialized.title, type: serialized.type.name });
            }

            // if (!_.isEmpty(serialized.child)) getinfo(serialized.parent);
        };
        getinfo(indicator.data);
        console.log('tree', indicatorInfoData);
        return indicatorInfoData;
    };

    useEffect(() => {
        const init = async () => {
            const settingsInfoResponse = await getIndicatorSettingOfLogframe(logframeId);
            if (settingsInfoResponse.status === StatusCodes.OK) {
                setIndicatorInfo((prev) => {
                    return { ...prev, settings: settingsInfoResponse.data.setting };
                });
            }

            const uomListResponse = await getUOMListOfProject(projectId);
            if (uomListResponse.status === StatusCodes.OK) {
                setIndicatorInfo((prev) => {
                    return { ...prev, uomList: uomListResponse.data.data };
                });
            }

            const frequencyListResponse = await getCollectionFrequencyListOfProject(projectId);
            if (frequencyListResponse.status === StatusCodes.OK) {
                setIndicatorInfo((prev) => {
                    return { ...prev, frequencyList: frequencyListResponse.data.data };
                });
            }

            const indicatorDetailResponse = await getLogframeDetail(logframeId);
            if (indicatorDetailResponse.status === StatusCodes.OK) {
                setIndicatorDetail(indicatorDetailResponse.data);
                setIsAggregatedIndicator(indicatorDetailResponse.data.isAggregatedIndicator);
            }
        };
        init();
    }, []);

    // const getTarget = () => {
    //     const t = targets[0];
    //     return t ? t.value : '';
    // };

    useEffect(() => {
        console.log('indicatorDetail ::: ', indicatorDetail);
    }, [indicatorDetail]);

    return (
        <Wrapper
            title={'Indicator Profile'}
            icon={<AccountCircleIcon />}
            backLink={`/project/${projectId}?tabIndex=7&lastVisited=${logframeId}`}
        >
            <IndicatorInfo indicatorInfo={indicatorInfo} indicatorDetail={indicatorDetail} />
            <Card sx={{ marginY: 2 }}>
                <Container sx={{ display: 'flex', marginY: 3, marginX: 0 }}>
                    <Typography variant="subtitle1" color="initial">
                        Indicator Target:
                    </Typography>
                </Container>
                <Targets editMode={false} />
            </Card>
            {isAggregatedIndicator ? (
                <ContributingIndicator logframeId={logframeId} />
            ) : (
                <Targets editMode={false} useAsDataCollectionModule={true} />
            )}
        </Wrapper>
    );
}
