import { useEffect, useState } from 'react';
import { UserResponse } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { userKeys } from '../../../react_query/query-keys';
import { DEFAULT_STALE_TIME } from '../../../utils/Constants';
import { getTeamUserList } from '../../../services/reactQueryservices';

const useGetTeamUserList = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingUserList, setInitiateFetchingUserList] = useState(true);
    const [userList, setUserList] = useState<UserResponse[]>([]);

    const result = useQuery<UserResponse[], Error>({
        queryKey: userKeys.all,
        queryFn: () => getTeamUserList(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingUserList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setUserList(result.data);
        }
    }, [result.data]);

    return {
        userList,
        ...result,
        setInitiateFetchingUserList
    };
};

export default useGetTeamUserList;
