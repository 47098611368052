// material-ui
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import DisaggregationNode from './disaggregationNode';
import Fab from '@mui/material/Fab';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DisaggregationForm from './newDisaggregationForm';
import { indicatorTypes, dataEntryInputs, relationships, getDtypes, getDvalues, defaultDisaggregation, initialValues } from './constants';
import Jsona from 'jsona';
import { useParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { useFormik } from 'formik';
import BasicForm from './basicForm';
import { SNACKBAR_OPEN } from 'store/actions';
import {
    createDisaggregationItem,
    createDisaggregationType,
    getCollectionFrequencyListOfProject,
    getDisaggregationListOfProject,
    getIndicatorSettingOfLogframe,
    getUOMListOfProject,
    updateIndicatorSetting
} from './Service';
import { _deserailize } from 'utils/Deserialize';
import Wrapper from 'components/Card/Wrapper';

const dataFormatter = new Jsona();

const IndicatorSettings = () => {
    const [disaggregationGroupList, setDisaggregationGroupList] = useState<any>([{ disaggregationList: [defaultDisaggregation] }]);
    const [openNewDisaggregationForm, setOpenNewDisaggregationForm] = useState<boolean>(false);
    const [newTypeValues, setNewTypeValues] = useState<any[]>([]);
    const [dTypes, setdTypes] = useState<any>([]);
    const [dValues, setdValues] = useState<any>([]);
    const [measurements, setMeasurements] = useState<any>([]);
    const [frequencies, setFrequencies] = useState<any>([]);
    const dispatch = useDispatch();
    const params = useParams();
    const projectId = params.projectId;
    const [formValues, setFormValues] = useState<any>(initialValues);

    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        onsubmit: (values: any) => {
            console.log('in formik submit ', values);
            // props.handleSubmit(values);
        }
    } as any);

    useEffect(() => {
        console.log('dValues ====>?>>', dValues);
    }, [dValues]);

    const createNewDisaggregationType = async (name: any) => {
        let dResponse: any = await createDisaggregationType({ name, projectId });
        console.log('dResponse ::: ', dResponse.data);

        console.log('---------------disaggregation response: ', dResponse, await _deserailize(dResponse.data));

        if (dResponse.status == StatusCodes.OK) {
            dResponse = await _deserailize(dResponse.data);
            setdTypes([...dTypes, { id: dResponse.id, label: dResponse.name }]);

            const dItemResponse: any = await createDisaggregationItem({
                items: newTypeValues,
                disaggregationId: parseInt(dResponse.id, 10)
            });
            if (dItemResponse.status == StatusCodes.OK) {
                const parsedItemResponse = await _deserailize(dItemResponse.data);
                const result = parsedItemResponse.disaggregationItems.map((i: any) => ({
                    label: i.item,
                    id: i.id,
                    code: i.code,
                    parent: dResponse.id
                }));
                setdValues([...dValues, ...result]);
                setOpenNewDisaggregationForm(false);
                setNewTypeValues([]);
            }
        }
    };

    const init = async () => {
        const measurementResposne = await getUOMListOfProject(projectId);
        const frequencyResponse = await getCollectionFrequencyListOfProject(projectId);
        const dTypeRespose = await getDisaggregationListOfProject(projectId);
        const indicatorSettings = await getIndicatorSettingOfLogframe(params.logframeId);
        const disaggregations = dataFormatter.deserialize(dTypeRespose.data);

        console.log('indicator settings: ', indicatorSettings.data.setting);

        if (indicatorSettings.data.setting) setFormValues(indicatorSettings.data.setting);
        setdTypes(getDtypes(disaggregations));
        setdValues(getDvalues(disaggregations));

        setMeasurements(dataFormatter.deserialize(measurementResposne.data));
        setFrequencies(dataFormatter.deserialize(frequencyResponse.data));

        if (indicatorSettings.data.setting) {
            const dGroupResult = indicatorSettings.data.setting.disaggregation.map((d: any) => {
                return {
                    disaggregationList: d.map((dItem: any, index: number) => {
                        return { id: dItem.id, label: dItem.title, index };
                    })
                };
            });
            setDisaggregationGroupList(dGroupResult);
            console.log(dGroupResult);
        }
    };

    useEffect(() => {
        init();
    }, []);

    const onSubmit = async () => {
        const disaggregation = disaggregationGroupList.map((d: any) => {
            return d.disaggregationList.map((dItem: any) => {
                return {
                    id: dItem.id,
                    title: dItem.label
                };
            });
        });
        const setting = {
            ...formik.values,
            disaggregation
        };
        const response = await updateIndicatorSetting({ logframeId: params.logframeId, setting });
        if (response.status === StatusCodes.OK) {
            showToast('Successfully Saved.', 'success');
        }
    };

    const showToast = (message: string, alertSeverity: string) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: alertSeverity,
            transition: 'Fade'
        });
    };

    const handleDisaggregation = (index: number, item: any) => {
        const dGroupList = [...disaggregationGroupList];
        dGroupList.splice(index, 1, item);
        setDisaggregationGroupList(dGroupList);
    };

    const deleteDisaggregationNode = (index: number) => {
        const dGroupList = [...disaggregationGroupList];
        dGroupList.splice(index, 1);
        setDisaggregationGroupList(dGroupList);
    };

    return (
        <Wrapper
            title={'Indicator Setting'}
            icon={<SettingsIcon />}
            backLink={`/project/${projectId}?tabIndex=7&lastVisited=${params.logframeId}`}
        >
            <MainCard title="Settings">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <BasicForm
                            formik={formik}
                            measurements={measurements}
                            frequencies={frequencies}
                            indicatorTypes={indicatorTypes}
                            dataEntryInputs={dataEntryInputs}
                            relationships={relationships}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                                Disaggregation:
                            </Typography>
                        </div>
                    </Grid>
                    {disaggregationGroupList.map((d: any, index: number) => (
                        <DisaggregationNode
                            dTypes={dTypes}
                            dValues={dValues}
                            nodeId={1}
                            key={index}
                            data={d}
                            openNewNode={() => setOpenNewDisaggregationForm(true)}
                            onChange={(item: any) => handleDisaggregation(index, item)}
                            deleteNode={() => deleteDisaggregationNode(index)}
                        />
                    ))}
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Fab
                            size="small"
                            color="secondary"
                            aria-label="add"
                            onClick={(e: any) =>
                                setDisaggregationGroupList([...disaggregationGroupList, { disaggregationList: [defaultDisaggregation] }])
                            }
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <DisaggregationForm
                        open={openNewDisaggregationForm}
                        handleClose={() => {
                            setOpenNewDisaggregationForm(false);
                            setNewTypeValues([]);
                        }}
                        options={newTypeValues}
                        onChange={(typeList: any) => setNewTypeValues(typeList)}
                        onSubmit={createNewDisaggregationType}
                    />

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" onClick={(e: any) => onSubmit()}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
        </Wrapper>
    );
};
export default IndicatorSettings;
