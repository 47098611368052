import { Autocomplete, Checkbox, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import React, { useEffect, Dispatch, SetStateAction } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SiteType, SiteDataType, GeoDataByParentListPayloadType, GeoDataType } from './Constant';
import ServiceList from '../../services';

interface LocationFormProps {
    node: SiteType;
    siteDataList: SiteDataType;
    setSiteDataList: Dispatch<SetStateAction<SiteDataType>>;
    valueKey: string;
    dataId: string | number;
    extra: any;
}

const LocationForm = ({ node, siteDataList, setSiteDataList, valueKey, dataId, extra }: LocationFormProps) => {
    const updateNodeData = async () => {
        const payload: GeoDataByParentListPayloadType = {
            geoType: node.id
        };
        if (node.nodeParent && siteDataList[node.nodeParent]) {
            payload.parent = siteDataList[node.nodeParent].selectedList.map((item: GeoDataType) => item.id);
            const sitesListResponse = await ServiceList.geoDataByParentList(payload, valueKey, dataId, extra);
            setSiteDataList((prev: SiteDataType) => {
                return { ...prev, [node.id]: { dataList: sitesListResponse, selectedList: [], parentId: node.nodeParent } };
            });
        }
    };

    useEffect(() => {
        updateNodeData();
    }, [node.nodeParent ? siteDataList[node.nodeParent] : node]);

    const hasKey = () => {
        return Object.prototype.hasOwnProperty.call(siteDataList, node.id);
    };

    return (
        <Grid item xs={12}>
            <InputLabel> {node.geoDefinition}: </InputLabel>
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    limitTags={2}
                    disableCloseOnSelect
                    id="checkboxes-tags-geo"
                    options={hasKey() ? siteDataList[node.id]?.dataList : []}
                    value={hasKey() ? siteDataList[node.id]?.selectedList : []}
                    getOptionLabel={(option: any) => option.fieldName}
                    renderOption={(_props: any, option: any, { selected }: any) => (
                        <li {..._props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.fieldName}
                        </li>
                    )}
                    onChange={(event: any, value: any) => {
                        setSiteDataList((prev: SiteDataType) => {
                            return {
                                ...prev,
                                [node.id]: {
                                    dataList: siteDataList[node.id]?.dataList,
                                    selectedList: value
                                }
                            };
                        });
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                />
            </FormControl>
        </Grid>
    );
};

export default LocationForm;
