
import {
    Grid,
    InputAdornment,
    TextField,
    FormHelperText,
    Select,
    MenuItem,
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { gridSpacing } from 'store/constant';

const BasicSource = (props: any) => {
    return (
        <Grid item xs={12}>
            <MainCard>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <InputLabel>Collection Period</InputLabel>
                        <Select
                            fullWidth
                            id="demo-simple-select"
                            label="Age"
                            onChange={() => console.log('')}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <FormHelperText>select collection period </FormHelperText>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel>Formula</InputLabel>
                        <Select
                            id="demo-simple-select"
                            fullWidth
                            label="Age"
                            onChange={() => console.log('')}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <FormHelperText>select formula</FormHelperText>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel>Data Source</InputLabel>
                        <Select
                            id="demo-simple-select"
                            fullWidth
                            label="Age"
                            onChange={() => console.log('')}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <FormHelperText>select data source</FormHelperText>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel>Columns</InputLabel>
                        <Select
                            id="demo-simple-select"
                            fullWidth
                            label="Age"
                            onChange={() => console.log('')}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <FormHelperText>select column</FormHelperText>
                    </Grid>
                </Grid>
            </MainCard>
        </Grid>
    )
}

export default BasicSource;