import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardContent, Grid, Typography, Stack, IconButton } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { CollectionFrequency } from 'types';
import { useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import CollectionFrequencyModal from './Modal';
import ClientPagingTable from 'components/Table/ClientPagingTable';
import Loader from 'ui-component/Loader';
import useGetCollectionFrequencyListByProjectId from 'hooks/consume_api/query/useGetCollectionFrequencyListByProjectId';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import useCollectionFrequencyDelete from 'hooks/consume_api/mutation/useCollectionFrequencyDelete';
import { columnListNameCollectionFrequency, dataColumns } from '../Constant';

const CollectionFrequencyTab = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const { collectionFrequencyList, isFetching } = useGetCollectionFrequencyListByProjectId(projectId, true);
    const { deleteCollectionFrequencyAsynchronously } = useCollectionFrequencyDelete();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedData, setSelectedData] = useState<CollectionFrequency | null>(null);

    const handleDeleteConfirm = async () => {
        await deleteCollectionFrequencyAsynchronously({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsEditMode(false);
    };

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx-={{ padding: 0 }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Collection Frequency List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Collection Frequency
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : collectionFrequencyList.length !== 0 ? (
                <CardContent>
                    <ClientPagingTable
                        data={collectionFrequencyList}
                        columns={dataColumns(
                            theme,
                            columnListNameCollectionFrequency,
                            setSelectedData,
                            setIsModalOpen,
                            setIsDeleteModalOpen,
                            setIsEditMode
                        )}
                        showSL
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Collection Frequency data found</Typography>
                </CardContent>
            )}

            {isModalOpen && (
                <CollectionFrequencyModal
                    collectionFrequency={selectedData}
                    isEditMode={isEditMode}
                    setIsModalOpen={setIsModalOpen}
                    handleModalClose={handleModalClose}
                    isModalOpen={isModalOpen}
                    projectId={projectId!}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Collection Frequency '${selectedData?.frequency}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default CollectionFrequencyTab;
