import { _deserailize } from 'utils/Deserialize';
import {
    deleteCollectionFrequencyOfProjectURL,
    deleteReportingPeriodOfProjectURL,
    deleteRPTOfProjectURL,
    deleteUOMOfProjectURL,
    formMediaCreateURL,
    formMediaDeleteURL,
    formMediaListURL,
    getCollectionFrequencyListOfProjectURL,
    getReportingPeriodListOfProjectURL,
    getRPTListOfProjectURL,
    getUOMListOfProjectURL,
    updateCollectionFrequencyOfProjectURL,
    updateReportingPeriodOfProjectURL,
    updateRPTOfProjectURL,
    updateUOMOfProjectURL
} from 'utils/serverUrls';
import axiosServices from 'utils/axiosServices';
import axiosFormServices from 'utils/axiosFormServices';
import axios from 'axios';

export const getGuardianListBySearch = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/administration/getGuardianList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};

export const getMenuList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getMenuList`,
        method: 'get'
    });
    return response?.data?._value;
};

export const deleteBatch = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/deleteBatch/${id}`,
        method: 'get'
    });

    return response?.data?._value;
};

export const getActivityListByProjectId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/list?projectId=${id}`,
        method: 'get'
    });
    return response.data;
};

export const createActivity = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/update`,
        method: 'post',
        data
    });
};

export const updateActivityResponsiblePerson = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/responsible-person/update`,
        method: 'post',
        data
    });
};

export const addIndicators = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/indicator/update`,
        method: 'post',
        data
    });
};
export const addExpenditure = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/add-expence`, // Adjust the endpoint according to your API
        method: 'post',
        data
    });
};
export const getActivityStatusList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/activity-status/list`,
        method: 'get'
    });
    return response?.data;
};
export const updateActivityStatus = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/update-activity-status`,
        method: 'post',
        data
    });
    return response?.data;
};
export const getActivityPhaseList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/activity-phase/list`,
        method: 'get'
    });
    return response?.data;
};
export const getTeamUserList = async (projectId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/responsible-person/list?projectId=${projectId}`,
        method: 'get'
    });
    return response?.data;
};
export const getActivityDetail = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/detail?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};
export const getIndicatorListByProjectId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/indicator/list?projectId=${id}&getAll=true`,
        method: 'get'
    });
    return response?.data;
};
export const getIndicatorListByActivityId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/indicator/list?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};
export const getExpenditureListByActivityId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/expense-list?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};

export const getUomListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getUOMListOfProjectURL}`, { params });
    return await _deserailize(response?.data);
};

export const createOrUpdateUOM = async (payload: any) => {
    const response = await axiosServices.post(`${updateUOMOfProjectURL}`, payload);
    return response?.data;
};

export const deleteUOM = async (payload: any) => {
    const response = await axiosServices.post(`${deleteUOMOfProjectURL}`, payload);
    return response?.data;
};

export const getCollectionFrequencyListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getCollectionFrequencyListOfProjectURL}`, { params });
    return await _deserailize(response?.data);
};

export const createOrUpdateCollectionFrequency = async (payload: any) => {
    const response = await axiosServices.post(`${updateCollectionFrequencyOfProjectURL}`, payload);
    return response?.data;
};

export const deleteCollectionFrequency = async (payload: any) => {
    const response = await axiosServices.post(`${deleteCollectionFrequencyOfProjectURL}`, payload);
    return response?.data;
};

export const getRPTListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getRPTListOfProjectURL}`, { params });
    return response?.data;
};

export const createOrUpdateRPT = async (payload: any) => {
    const response = await axiosServices.post(`${updateRPTOfProjectURL}`, payload);
    return response?.data;
};

export const deleteRPT = async (payload: any) => {
    const response = await axiosServices.post(`${deleteRPTOfProjectURL}`, payload);
    return response?.data;
};

export const getReportingPeriodListByProjectId = async (id: string) => {
    const params = { reportingPeriodTypeId: id };
    const response = await axiosServices.get(`${getReportingPeriodListOfProjectURL}`, { params });
    return response?.data;
};
export const createOrUpdateReportingPeriod = async (payload: any) => {
    const response = await axiosServices.post(`${updateReportingPeriodOfProjectURL}`, payload);
    return response?.data;
};
export const deleteReportingPeriod = async (payload: any) => {
    const response = await axiosServices.post(`${deleteReportingPeriodOfProjectURL}`, payload);
    return response?.data;
};

export const getMediaListByProjectFormId = async (id: string) => {
    const payload = { xform_id: id };
    const response = await axiosFormServices.post(`${formMediaListURL}`, payload);
    return response?.data;
};

export const createProjectFormMedia = async (payload: any) => {
    const response = await axiosFormServices.post(`${formMediaCreateURL}`, payload);
    return response?.data;
};

export const deleteProjectFormMedia = async (payload: any) => {
    const response = await axiosFormServices.post(`${formMediaDeleteURL}`, payload);
    return response?.data;
};
