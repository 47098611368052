import { responseErrorHandler } from 'services/master';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { getLogframeStructureDefinitionURL, updateLogframeStructureDefinitionURL, deleteLogframeStructureURL } from 'utils/serverUrls';

export const getLogDefinitionStructure = async (projectId: any) => {
    try {
        const params = {
            projectId
        };
        const response: any = await axiosServices.get(`${getLogframeStructureDefinitionURL}`, { params });
        const data = await _deserailize(response.data);
        return data;
    } catch (ex) {
        return null;
    }
};

export const saveLogDefinitionStructure = async (projectId: any, data: any) => {
    try {
        data.projectId = projectId;
        const response: any = await axiosServices.post(`${updateLogframeStructureDefinitionURL}`, data);
        const responseData = await _deserailize(response.data);
        return responseData;
    } catch (ex) {
        return null;
    }
};

export const deleteLogDefinitionStructure = async (projectId: any, data: any) => {
    try {
        data.projectId = projectId;
        const response: any = await axiosServices.post(`${deleteLogframeStructureURL}`, data);
        return response;
    } catch (ex: any) {
        return responseErrorHandler(ex);
    }
};

export default {};
