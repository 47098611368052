import React, {useCallback} from 'react';
import {Activity} from "../../../../types";
import SortableTree from 'react-sortable-tree';
import MaterialTheme from 'react-sortable-tree-theme-material-ui';
import {IconButton, Stack, Typography} from "@mui/material";
import {DragIndicator, ExpandMore, NavigateNext, Texture} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import {Link} from 'react-router-dom';
import {useTheme} from "@mui/material/styles";
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LibraryAddTwoToneIcon from '@mui/icons-material/LibraryAddTwoTone';


interface Props {
    data: Activity[];
    onChange: (treeData: Activity[]) => void
    updateTreeNode: (exp: boolean, rowInfo: any) => void
    handleAddSubActivity: (parentActivityId?: string) => void
    handleActivityEditClick: (activityId: string, parentActivityId: string) => void
}

const ActivityList: React.FC<Props> = ({
                                           updateTreeNode,
                                           data,
                                           onChange,
                                           handleAddSubActivity,
                                           handleActivityEditClick
                                       }) => {

    const navigationIcon = useCallback((rowInfo: any) => {
        if (rowInfo.node.children !== undefined && rowInfo.node.children.length > 0) {
            return rowInfo.node.expanded ? (
                <Tooltip placement="top" title="Collapse">
                    <ExpandMore style={{color: '#329aa8', cursor: 'pointer'}}
                                onClick={() => updateTreeNode(false, rowInfo)}/>
                </Tooltip>
            ) : (
                <Tooltip placement="top" title="Expand">
                    <NavigateNext style={{color: '#329aa8', cursor: 'pointer'}}
                                  onClick={() => updateTreeNode(true, rowInfo)}/>
                </Tooltip>
            );
        }
        return <Texture/>;
    }, []);

    const theme = useTheme();

    return (
        <SortableTree
            isVirtualized={false}
            treeData={data}
            onChange={onChange}
            theme={MaterialTheme}
            style={{height: 'fit-content', width: '100%'}}
            generateNodeProps={(rowInfo) => ({
                buttons: (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Tooltip placement="top" title="View">
                            <IconButton color="info" size="large" component={Link} to={`/activity/${rowInfo.node.id}`}>
                                <VisibilityTwoToneIcon sx={{fontSize: '1.3rem'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Edit">
                            <IconButton
                                color="primary"
                                sx={{
                                    color: theme.palette.warning.dark,
                                    borderColor: theme.palette.warning.main,
                                    '&:hover ': {background: theme.palette.warning.light}
                                }}
                                size="large"
                                onClick={() => {
                                    // console.log(rowInfo.node)
                                    handleActivityEditClick(rowInfo.node.id, rowInfo.node.parent ? rowInfo.node.parent.id : '')
                                }}
                            >
                                <BorderColorTwoToneIcon sx={{fontSize: '1.3rem'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Delete">
                            <IconButton
                                color="primary"
                                sx={{
                                    color: theme.palette.orange.dark,
                                    borderColor: theme.palette.orange.main,
                                    '&:hover ': {background: theme.palette.orange.light}
                                }}
                                size="large"
                            >
                                <DeleteTwoToneIcon sx={{fontSize: '1.3rem'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Add">
                            <IconButton color="success" aria-label="delete" size="large"
                                        onClick={() => {
                                            handleAddSubActivity(rowInfo.node.id)
                                        }}>
                                <LibraryAddTwoToneIcon sx={{fontSize: '1.3rem'}}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ),
                icons: (
                    <>
                        {navigationIcon(rowInfo)}
                        <DragIndicator style={{color: rowInfo.node.backgroundColor, marginLeft: '10px'}}/>
                    </>
                ),
                title: (
                    <Stack>
                        <Typography variant={'subtitle1'}>
                            <i>{rowInfo.node.title}</i>
                        </Typography>
                        {/*<Typography variant={'subtitle2'}>{rowInfo.node.description}</Typography>*/}
                    </Stack>
                )
            })}
        />
    );
};

export default ActivityList;