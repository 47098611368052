// ------------------------------ These API list associated with form --------------------------------
export const formInstanceUploadURL = `msurvey/forms/instance/submit/`;
export const formExportListURL = `msurvey/forms/export-list/`;
export const formExportCreateURL = `msurvey/forms/create-export/`;
export const formsInstanceValueListURL = `/msurvey/forms/instance-value/list/`;
export const formAttributesURL = `/msurvey/forms/form-attributes/`;
export const approvalUpdateURL = `/msurvey/forms/instance/approval/update/`;
export const formsDownloadURL = `/msurvey/forms/download/`;
export const formInstanceSubmitURL = `/msurvey/forms/instance/submit/`;
export const formCloneURL = `/msurvey/forms/clone/`;
export const formExportStatusURL = `/msurvey/forms/export-status/`;

export const formMediaCreateURL = `/msurvey/forms/media/submit/`;
export const formMediaListURL = `/msurvey/forms/media/list/`;
export const formMediaDeleteURL = `/msurvey/forms/media/delete/`;

// ----------------------------- Application API ----------------------------------------------------
export const loginURL = `msurvey/api/auth/login`;
export const sectorURL = `msurvey/api/master/sector/get-all`;
export const countryURL = `msurvey/api/master/country/get-all`;
export const signupURL = `msurvey/api/sign-up/create`;
export const privilegeURL = `msurvey/api/master/privilege/get-all`;
export const roleCreateURL = `msurvey/api/role/create`;
export const roleEditURL = `msurvey/api/role/update`;
export const roleListURL = `msurvey/api/role/get-all-by-org`;
export const roleByOrganizationListURL = `msurvey/api/role/get-role-by-org`;
export const roleBydIdURL = `msurvey/api/role/get-by-id`;
export const approveSignupURL = `msurvey/api/sign-up/approve`;
export const organizationChildListURL = `/msurvey/api/organization/get-all-child`;
export const organizationListURL = `/msurvey/api/organization/get-all`;
export const organizationParentListURL = `msurvey/api/organization/get-all-parent`;
export const organizationCreateURL = `msurvey/api/organization/create`;
export const organizationUpdateURL = `msurvey/api/organization/update`;
export const organizationDeleteURL = `msurvey/api/organization/delete`;
export const userCreateURL = `msurvey/api/user/create`;
export const userUpdateURL = `msurvey/api/user/update`;
export const userProfileURL = `msurvey/api/user/get-user-by-username`;
export const userProfileByKCIdURL = `msurvey/api/user/get-user-by-kcid`;
export const getUserByIdURL = `/msurvey/api/user/get-user-by-id`;
export const userListURL = `msurvey/api/user/get-user-by-org`;
export const userDeleteURL = `msurvey/api/user/delete`;
export const projectListURL = `msurvey/api/project/get-project-all`;
export const getProjectByOrgIdURL = `msurvey/api/project/get-project-by-orgId`;
export const projectAddURL = `msurvey/api/project/create`;
export const projectUpdateURL = `msurvey/api/project/update`;
export const projectGetURL = `msurvey/api/project/get-project-by-name-or-id`;
export const projectOrgListURL = `msurvey/api/project/get-project-organizations`;
export const projectTeamMemberURL = `msurvey/api/project/teams/get-project-users`;
export const projectPartnerUsersURL = `msurvey/api/project/teams/get-partner-org-users`;
export const removeProjectUserURL = `msurvey/api/project/teams/remove-user`;
export const addProjectUserURL = `msurvey/api/project/teams/add`;
export const projectUsersURL = `msurvey/api/project/teams/get-project-users`;
export const deleteProjectUserURL = `msurvey/api/project/teams/remove-user`;
export const projectFormUploadURL = `msurvey/forms/submit/`;
export const projectFormReplaceURL = `msurvey/forms/change/submit/`;
export const formListURL = `msurvey/api/project/forms/get-form-list-all`;
export const getAllFormConfigurationURL = `msurvey/api/project/forms/get-all-form-configuration`;
export const changeStatusURL = `msurvey/api/project/forms/change-status`;
export const getDashboardCountURL = `msurvey/api/project/forms/get-dashboard-count`;
export const createOrUpdateFormTargetURL = `msurvey/api/project/target-setting/create-or-update-form-target`;
export const settingsUpdateURL = `msurvey/api/project/settings-update`;
export const formsMediaUploadURL = `msurvey/forms/media/submit/`;
export const refreshTokenURL = `msurvey/api/auth/refresh-token`;
export const setUserTargetManualURL = `msurvey/api/project/target-setting/create-manual-user-target`;
export const autoUserTargetURL = `msurvey/api/project/target-setting/create-auto-user-target`;
export const getProjectOrganizationsURL = `/msurvey/api/project/get-project-organizations`;
export const getAllByOrgsURL = `/msurvey/api/role/get-all-by-orgs`;
export const getUserByOrgsAndRolesURL = `/msurvey/api/user/get-user-by-orgs-roles`;
export const templateListURL = `msurvey/api/project/forms/template-list`;
export const formsCloneURL = `/msurvey/forms/clone/`;
export const getUserListURL = `/msurvey/api/project/forms/get-users-list`;
export const createManualUserTargetURL = `/msurvey/api/project/target-setting/create-manual-user-target`;
export const getTargetDashboardCountURL = `/msurvey/api/project/forms/get-target-dashboard-count`;
export const updateFormsUserList = `/msurvey/api/project/forms/update-forms-users-list`;
export const getUserDashboardCountURL = `/msurvey/api/project/forms/get-user-target-dashboard-count`;
export const getFormByFormIdURL = `/msurvey/api/project/forms/get-form-by-formId`;
export const revertInstanceCreateURL = `/msurvey/api/project/forms/revert-instance-create`;
export const formsDeleteURL = `/msurvey/api/project/forms/delete`;
export const resetPasswordRequestURL = `/msurvey/api/user/reset-password-request`;
export const resetPasswordURL = `/msurvey/api/user/reset-password`;
export const saveColumnSettingsURL = `/msurvey/api/project/forms/update-columns-setting`;
export const getColumnSettingsURL = `/msurvey/api/project/forms/get-columns-setting`;
export const getAddFormUserListURL = `/msurvey/api/project/forms/get-add-form-user-list`;
export const createFormUserURL = `/msurvey/api/project/forms/create-form-user`;
export const projectFormDetailsURL = `/msurvey/api/project/forms/project-form-details`;
export const projectFormUpdateURL = `/msurvey/api/project/forms/project-form-update`;
export const createJsonInitURL = `/msurvey/api/form-builder/create-json-init`;
export const getLanguageURL = `/mSurvey/api/language/get`;
export const getCategory = `mSurvey/api/category/get`;
export const addCategoryURL = `mSurvey/api/category/create`;
export const deleteCategoryURL = `mSurvey/api/category/delete`;
export const updateCategoryURL = `mSurvey/api/category/update`;
export const getItemURL = `mSurvey/api/master/item/get`;
export const getParentURL = `mSurvey/api/category/getParent`;
export const addItemURL = `mSurvey/api/master/item/create`;
export const deleteItemURL = `mSurvey/api/master/item/delete`;
export const getItemUpdateURL = `mSurvey/api/master/item/getParent`;
export const itemUpdateURL = `mSurvey/api/master/item/update`;
export const getIfMultilingualURL = `/msurvey/forms/language-list/`;

// dashboard urls
export const dashboardListURL = `/msurvey/api/dashboard/list/`;
export const dashboardPublishedListURL = `/msurvey/api/dashboard/published-list/`;
export const dashboardDeleteURL = `/msurvey/api/dashboard/delete/`;
export const dashboardPublishStatusUpdateURL = `/msurvey/api/dashboard/update/publish-status/`;

// **************** geo ****************
export const geoTypeListURL = `/msurvey/api/geo/geo-type`;
export const geoDataByParentListURL = `/msurvey/api/geo/get-geo-by-parent`;

// for project
export const createProjectSitesURL = `/msurvey/api/project/addSites`;
export const getProjectSitesListURL = `/msurvey/api/project/findSites`;

// for activity of project
export const geoDataOfProjectByParentListURL = `/msurvey/api/logframe/geo/list`;
export const createActivitySitesURL = `/msurvey/api/logframe/activity/sites/create`;
export const getActivitySitesListURL = `/msurvey/api/logframe/activity/sites/list`;

// for member of project
export const createMemberSitesURL = `/msurvey/api/logframe/member/sites/create`;
export const getMemberSitesListURL = `/msurvey/api/logframe/member/sites/list`;

// *********************** logframe urls *************************************
export const getSdgListURL = `/msurvey/api/logframe/sdg/list`;
export const getLogframeStructureDefinitionURL = `/msurvey/api/logframe/structure`;
export const updateLogframeStructureDefinitionURL = `/msurvey/api/logframe/structure/define`;
export const getLogframeDefinitionTreeStructureURL = `/msurvey/api/logframe/structure/recursive`;
export const getLogframeTreeURL = `/msurvey/api/logframe/recursive`;
export const getLogframeDetailURL = `/msurvey/api/logframe/detail`;
export const createLogframeNodeURL = `/msurvey/api/logframe/create`;
export const updateLogframeParentURL = `/msurvey/api/logframe/update-parent`;
export const getProjectIndicatorListURL = `/msurvey/api/logframe/indicator/list`;

export const deleteLogframeStructureURL = `/msurvey/api/logframe/structure/delete`;
export const deleteLogframeNodeURL = `/msurvey/api/logframe/delete`;

// delete sites
export const deleteProjectSiteURL = `/msurvey/api/project/deleteSite`;
export const deleteMemberSiteURL = `/msurvey/api/logframe/member/sites/delete`;

// target settings
export const getReportingPeriodOfProjectURL = `/msurvey/api/logframe/get-reporting-period-of-project`;
export const getTargetVsCollectionDataOfIndicatorURL = `/msurvey/api/logframe/indicator/target-vs-collection`;
export const getReportingPeriodTypeListOfProjectURL = `/msurvey/api/logframe/get-reporting-period-type-of-project`;

// settings urls
export const createDisaggregationURL = `/msurvey/api/logframe/disaggregation/create`;
export const createDisaggregationItemURL = `/msurvey/api/logframe/disaggregation/item/create`;

export const getUOMListOfProjectURL = `/msurvey/api/logframe/uom/list`;
export const updateUOMOfProjectURL = `/msurvey/api/logframe/uom/update`;
export const deleteUOMOfProjectURL = `/msurvey/api/logframe/uom/delete`;

export const getCollectionFrequencyListOfProjectURL = `/msurvey/api/logframe/collection-frequency/list`;
export const updateCollectionFrequencyOfProjectURL = `/msurvey/api/logframe/collection-frequency/update`;
export const deleteCollectionFrequencyOfProjectURL = `/msurvey/api/logframe/collection-frequency/delete`;

export const getRPTListOfProjectURL = `/msurvey/api/logframe/get-reporting-period-type-of-project`;
export const updateRPTOfProjectURL = `/msurvey/api/logframe/reporting-period-type/create`;
export const deleteRPTOfProjectURL = `/msurvey/api/logframe/reporting-period-type/delete`;

export const getReportingPeriodListOfProjectURL = `/msurvey/api/logframe/get-reporting-period-of-project`;
export const updateReportingPeriodOfProjectURL = `/msurvey/api/logframe/reporting-period/create`;
export const deleteReportingPeriodOfProjectURL = `/msurvey/api/logframe/reporting-period/delete`;

export const getCrossCuttingIssueListOfProjectURL = `/msurvey/api/logframe/cross-cutting-issue/list`;
export const getActivityTypeListOfProjectURL = `/msurvey/api/logframe/activity-type/list`;
export const getDisaggregationListOfProjectURL = `/msurvey/api/logframe/disaggregation/list`;
export const getIndicatorSettingOfLogframeURL = `/msurvey/api/logframe/indicator-setting`;
export const getContributingIndicatorListURL = `/msurvey/api/logframe/contributing-indicator/list`;
export const getContributingIndicatorDetailURL = `/msurvey/api/logframe/contributing-indicator/detail`;
export const updateIndicatorSettingOfLogframeURL = `/msurvey/api/logframe/indicator-setting/update`;
export const getIndicatorTargetOfLogframeURL = `/msurvey/api/logframe/indicator-target`;

export const updateIndicatorTargetOfLogframeURL = `/msurvey/api/logframe/indicator-target/update`;
export const getIndicatorDataOfLogframeURL = `/msurvey/api/logframe/indicator-data`;
export const updateIndicatorDataOfLogframeURL = `/msurvey/api/logframe/indicator-data/update`;
export const getIndicatorProgressSummaryOfLogframeURL = `/msurvey/api/logframe/indicator-progress-summary`;
export const createUOMURL = `/msurvey/api/logframe/uom/create`;
export const createCrossCuttingIssueURL = `/msurvey/api/logframe/cross-cutting-issue/create`;
export const createActivityTypeURL = `/msurvey/api/logframe/activity-type/create`;
export const updateContributingIndicatorURL = `/msurvey/api/logframe/contributing-indicator/update`;

export default {};
