import {
    Button,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Select,
    MenuItem
} from '@mui/material';
import { updateContributingIndicator } from 'features/IndicatorSettings/Service';
import { getProjectIndicatorList, getProjectList } from 'features/Logframe/Service';
import { ContributingIndicator, defaultContributingIndicator } from './constant';
import React, { useEffect, useState } from 'react';

const ContributingIndicatorForm = (props: any) => {
    const [data, setData] = useState(props.contributingIndicatorData);
    const [projectList, setProjectList] = useState<any>([]);
    const [indicatorList, setIndicatorList] = useState<any>([]);

    useEffect(() => {
        const init = async () => {
            const projectListData = await getProjectList();
            setProjectList(projectListData);
        };
        init();
    }, []);

    useEffect(() => {
        setData(props.contributingIndicatorData);
    }, [props.contributingIndicatorData]);

    useEffect(() => {
        const updateIndicatorList = async () => {
            const projectListData = await getProjectIndicatorList(data.projectId);
            setIndicatorList(projectListData.data);
        };
        if (data.projectId !== '') {
            updateIndicatorList();
        }
    }, [data.projectId]);

    const handleChange = (e: any) => {
        setData((prev: ContributingIndicator) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const formComplete = () => {
        return data.indicatorId !== '' && data.projectId !== '' && data.weight !== '' ? true : false;
    };

    const handleClose = () => {
        setData(defaultContributingIndicator);
        props.handleClose();
    };

    const handleSubmit = async () => {
        const payload = {
            logframeId: props.logframeId,
            ...data
        };
        console.log('payload ::: ', payload);
        await updateContributingIndicator(payload);
        handleClose();
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle>Indicator Form</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="h4">Project: </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Select fullWidth name="projectId" onChange={handleChange} value={data.projectId}>
                            {projectList.map((item: any, index: number) => (
                                <MenuItem value={item.id} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="h4">Indicator: </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Select fullWidth name="indicatorId" onChange={handleChange} value={data.indicatorId}>
                            {indicatorList.map((item: any, index: number) => (
                                <MenuItem value={item.id} key={index}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="h4">Weight: </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <TextField fullWidth name="weight" value={data.weight} onChange={handleChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={!formComplete()}>
                    Submit
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContributingIndicatorForm;
