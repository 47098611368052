import React, { useEffect, useState } from 'react';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SubCard from 'ui-component/cards/SubCard';
import ServiceList from 'services';
import SitesFormDialog from './SitesFormDialog';
import { ObjectSiteType } from './Constant';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { deleteSites } from 'services/master';
import useSnackbar from 'hooks/useSnackbar';

interface SitesListType {
    title?: string;
    dataId: number | string;
    valueKey: string;
    extra?: any;
}

const SitesList = ({ title, dataId, valueKey, extra }: SitesListType) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState<boolean>(false);
    const [subTitle, setSubTitle] = useState<string>('');
    const [dataList, setDataList] = useState<ObjectSiteType[]>([]);
    const [toDeleteSiteId, setToDeleteSiteId] = useState<number>(0);

    const toastMsg = useSnackbar();

    const fetchData = async () => {
        const response = await ServiceList.geoSitesList(valueKey, dataId, extra);
        setDataList(response);
    };

    const handleDeleteConfirm = async () => {
        let extraPayload: any = { siteId: toDeleteSiteId };
        if (valueKey === 'memberId') {
            extraPayload = { ...extraPayload, ...extra.params };
        }
        const deleteResponse: any = await deleteSites(dataId, valueKey, extraPayload);
        if (deleteResponse.status === 200) {
            toastMsg('node deleted!');
            await fetchData();
        } else {
            toastMsg(deleteResponse?.text, 'error');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <SubCard
                title={title ? title : 'Sites:'}
                sx={{ marginY: 2 }}
                secondary={
                    <Button variant="outlined" onClick={() => setDialogOpen(true)} startIcon={<AddIcon />}>
                        Add
                    </Button>
                }
            >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>#</TableCell>
                                <TableCell>Location Type</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList.map((data: any, index: number) => (
                                <TableRow hover key={data.id}>
                                    <TableCell sx={{ pl: 3 }}>{index + 1}</TableCell>
                                    <TableCell>{data.locationType.typeName}</TableCell>
                                    <TableCell>{data.site}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="error"
                                            aria-label="delete"
                                            size="large"
                                            onClick={() => {
                                                setSubTitle(`Delete Location ${data.locationType.typeName}: ${data.site}`);
                                                setToDeleteSiteId(data.id);
                                                setDeleteConfirmDialogOpen(true);
                                            }}
                                        >
                                            <DeleteForeverIcon sx={{ fontSize: '1.1rem' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SubCard>
            {dialogOpen && (
                <SitesFormDialog
                    dataId={dataId}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    save={ServiceList.createSites}
                    reload={fetchData}
                    valueKey={valueKey}
                    extra={extra}
                />
            )}
            {deleteConfirmDialogOpen && (
                <ConfirmDeleteModal
                    open={deleteConfirmDialogOpen}
                    subTitle={subTitle}
                    setOpen={setDeleteConfirmDialogOpen}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </>
    );
};

export default SitesList;
