import React from 'react';
import { IReportingPeriodPayload } from 'types';
import * as Yup from 'yup';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Button, DialogActions, Divider, Grid, Stack, TextField, InputAdornment } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { MobileDatePicker, MobileDateRangePicker } from '@mui/lab';
import { gridSpacing } from 'store/constant';
import { yyyy_mm_dd } from 'utils/date';

const getInitialValues = (obj: FormikValues | null, reportingPeriodTypeId: string) => {
    if (obj) {
        return {
            id: obj.id,
            name: obj.name,
            reportingPeriodTypeId,
            startDate: `${yyyy_mm_dd(new Date(obj.startDate))}`,
            endDate: `${yyyy_mm_dd(new Date(obj.endDate))}`
        };
    }

    const newObj = {
        id: '',
        name: '',
        reportingPeriodTypeId,
        startDate: '',
        endDate: ''
    };

    return newObj;
};

export interface ReportingPeriodFormProps {
    obj: FormikValues | null;
    reportingPeriodTypeId: string;
    handleSave: (data: FormikValues) => void;
    onCancel: () => void;
    isEditMode: boolean;
}

const ReportingPeriodForm = ({ obj, handleSave, onCancel, reportingPeriodTypeId, isEditMode }: ReportingPeriodFormProps) => {
    const isCreating = !isEditMode;

    const ReportingPeriodSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required')
    });

    const formik = useFormik<IReportingPeriodPayload>({
        initialValues: getInitialValues(isEditMode ? obj : null, reportingPeriodTypeId),
        validationSchema: ReportingPeriodSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const toSubmitData = {
                    id: values.id,
                    reportingPeriodTypeId: values.reportingPeriodTypeId,
                    name: values.name,
                    startDate: yyyy_mm_dd(new Date(values.startDate)),
                    endDate: yyyy_mm_dd(new Date(values.endDate))
                };
                await handleSave(toSubmitData);
                setSubmitting(false);
                onCancel();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    const isFormComplete = () => {
        let isValid = values.name !== '';
        if (isCreating) return isValid;
        return isValid && (values.name !== obj?.name || values.startDate !== obj?.startDate || values.endDate !== obj?.endDate);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle>{isCreating ? 'Add Reporting Period' : 'Edit Reporting Period'}</DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Reporting Period"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MobileDateRangePicker
                                value={[values.startDate, values.endDate]}
                                inputFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                    setFieldValue('startDate', date[0]);
                                    setFieldValue('endDate', date[1]);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...getFieldProps('startDate')}
                                        {...params}
                                        fullWidth
                                        label="Start Date"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <DateRangeIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={Boolean(touched.startDate && errors.startDate)}
                                        helperText={touched.startDate && errors.startDate}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MobileDatePicker
                                label="End Date"
                                value={values.endDate}
                                inputFormat="dd/MM/yyyy"
                                onChange={(date) => setFieldValue('endDate', date)}
                                disabled={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...getFieldProps('endDate')}
                                        {...params}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <DateRangeIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={Boolean(touched.endDate && errors.endDate)}
                                        helperText={touched.endDate && errors.endDate}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button type="button" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" disabled={isSubmitting || !isFormComplete()}>
                                    {isCreating ? 'Add' : 'Edit'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Form>
        </FormikProvider>
    );
};

export default ReportingPeriodForm;
