import {useState} from 'react';
import {useMutation} from '@tanstack/react-query';

import {client} from '../../../react_query/query-client-configuration';
import {activityKeys} from '../../../react_query/query-keys';
import useSnackbar from "../../useSnackbar";
import {createActivity} from "../../../services/reactQueryservices";

const UseActivityCreation = () => {
    const [activityId, setActivityId] = useState('');
    const snackbar = useSnackbar()
    const {mutateAsync, mutate, error, isSuccess, isError, isLoading} = useMutation({
        mutationFn: createActivity,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to save Content', 'error');
            } else {
                console.log({data})
                client.invalidateQueries({queryKey: activityKeys.all});
                // setActivityId(data.);
                snackbar('successfully Created');
            }
        }
    });

    return {
        activityId,
        createActivity: mutate,
        createActivityAsynchronously: mutateAsync,
        isActivityCreationLoading: isLoading,
        activityCreationError: error,
        isActivityCreationError: isError,
        isActivityCreationSuccess: isSuccess
    };
};

export default UseActivityCreation;
