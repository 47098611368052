// third-party
// assets
import {
    IconBell,
    IconBuildingCommunity,
    IconClipboardList,
    IconDashboard,
    IconDatabase,
    IconFileCode,
    IconHelp,
    IconLayout2,
    IconSitemap,
    IconUpload,
    IconUser,
    IconUsers
} from '@tabler/icons';
import {FormattedMessage} from 'react-intl';
import LockIcon from '@mui/icons-material/Lock';
import DashboardIcon from '@mui/icons-material/Dashboard';

// constant
const icons = {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconUser,
    IconFileCode,
    IconBuildingCommunity,
    IconClipboardList,
    IconUpload,
    IconDatabase,
    IconLayout2
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'allprojects',
            title: <FormattedMessage id="allprojects"/>,
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'team_management',
            title: <FormattedMessage id="teamManagement"/>,
            type: 'collapse',
            url: '/',
            icon: icons.IconUsers,
            breadcrumbs: false,
            children: [
                {
                    id: 'role_management',
                    title: <FormattedMessage id="roleManagement"/>,
                    type: 'item',
                    url: '/roles',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false
                },
                {
                    id: 'user_management',
                    title: <FormattedMessage id="userManagement"/>,
                    type: 'item',
                    url: '/users',
                    icon: icons.IconUser,
                    breadcrumbs: false
                },
                {
                    id: 'org_management',
                    title: <FormattedMessage id="orgManagement"/>,
                    type: 'item',
                    url: '/organizations',
                    icon: icons.IconBuildingCommunity,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'forms',
            title: <FormattedMessage id="formdesignandupload"/>,
            type: 'item',
            url: '/forms',
            icon: icons.IconFileCode,
            breadcrumbs: false
        },
        {
            id: 'notifications',
            title: <FormattedMessage id="notifications"/>,
            type: 'item',
            url: '/notifications',
            icon: IconBell,
            breadcrumbs: false
        },
        {
            id: 'permission',
            title: <FormattedMessage id="permission"/>,
            type: 'item',
            url: '/permission',
            icon: LockIcon,
            breadcrumbs: false
        },
        {
            id: 'master_data',
            title: <FormattedMessage id="masterData"/>,
            type: 'collapse',
            url: '/',
            icon: icons.IconDatabase,
            breadcrumbs: false,
            children: [
                {
                    id: 'category',
                    title: <FormattedMessage id="Category"/>,
                    type: 'item',
                    url: '/category',
                    icon: icons.IconLayout2,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'dashboardRenderer',
            title: <FormattedMessage id="dashboardRenderer"/>,
            type: 'item',
            url: '/dashboards',
            icon: DashboardIcon,
            breadcrumbs: false
        }

        // {
        //     id: 'feedback',
        //     title: <FormattedMessage id="feedback" />,
        //     type: 'item',
        //     url: '/feedback',
        //     icon: icons.IconHelp,
        //     breadcrumbs: false
        // },
    ]
};
export const superUser = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'user-create',
            title: <FormattedMessage id="User Create"/>,
            type: 'item',
            url: '/super-user/users-create',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'acl',
            title: <FormattedMessage id="ACL"/>,
            type: 'item',
            url: '/super-user/acl',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-acl',
            title: <FormattedMessage id="Forum ACL"/>,
            type: 'item',
            url: '/super-user/forums',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default other;
