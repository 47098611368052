import React, {useEffect, useState} from 'react';
import MainCard from "../../../../ui-component/cards/MainCard";
import {Button, CardContent, Grid, Typography} from "@mui/material";
import {FormikValues} from "formik";
import ActivityList from "./ActivityList";
import {gridSpacing} from "../../../../store/constant";
// import {deserialize} from "../../../../utils/Helpers";
import {Activity} from "../../../../types";
import ActivityAdd from "./ActivityAdd";
import Dialog from "@mui/material/Dialog";
import useGetActivityListByProjectId from "../../../../hooks/consume_api/query/useGetActivityListByProjectId";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {changeNodeAtPath, TreeItem} from "react-sortable-tree";
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import useActivityCreation from "../../../../hooks/consume_api/mutation/useActivityCreation";

const Index = () => {
    const {id: projectId} = useParams()
    const {activityList, isFetching} = useGetActivityListByProjectId(projectId, true)
    const [parentActivityId, setParentActivityId] = useState('')
    const {createActivityAsynchronously} = useActivityCreation()
    const [treeData, setTreeData] = useState<Activity[]>(activityList);
    const [activity, setActivity] = React.useState<FormikValues | null>(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    useEffect(() => {
        setTreeData(expandAllNodes(activityList))
    }, [activityList]);

    const getNodeKey = ({treeIndex}: TreeItem) => treeIndex; // Using treeIndex as the unique key

    const onTreeChange = (treeData: Activity[]) => {
        setTreeData(treeData)
    }

    const expandAllNodes = (nodes: Activity[]): Activity[] => {
        return nodes.map((node) => ({
            ...node,
            expanded: true,
            children: node.children ? expandAllNodes(node.children) : undefined,
        }));
    };


    const updateTreeNode = (exp: boolean, rowInfo: any) => {
        const newNode = _.cloneDeep(rowInfo);
        newNode.node.expanded = exp;
        const result: any = changeNodeAtPath({
            treeData,
            path: rowInfo.path,
            newNode: newNode.node,
            getNodeKey
        } as any);

        setTreeData(result);
    };

    const handleActivityCreate = async (data: FormikValues) => {
        await createActivityAsynchronously(data);
        console.log('created', data)
    };

    const handleActivityUpdate = async (update: FormikValues) => {
        await createActivityAsynchronously(update);

        console.log('updated', {update});
    };

    const handleActivityDelete = async (id: string) => {
        // await deleteBatchAsynchronously(id);
        console.log('deleted', id);
    };

    const handleAddClick = (parentActivityId?: string) => {
        setParentActivityId(parentActivityId ?? '')
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setActivity(null);
    };

    function flattenActivities(activities: Activity[]): Activity[] {
        return activities.flatMap((activity) => {
            const flattenedChildren = activity.children ? flattenActivities(activity.children) : [];
            return [activity, ...flattenedChildren];
        });
    }

    const handleActivityEditClick = (activityId: string, parentActivityId: string) => {
        console.log("activity id in handleActivityEditClick", activityId)
        if (activityId) {
            const flattenedActivities: Activity[] = flattenActivities(activityList);
            const activity = flattenedActivities.find((activity) => activity.id === activityId);
            setActivity(activity as FormikValues);
        } else {
            setActivity(null);
        }
        setParentActivityId(parentActivityId ?? '')
        setIsModalOpen(true);
    };


    return (
        <MainCard
            content={false}
            contentSX={{padding: 0}}
            sx-={{padding: 0}}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Activity List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ml: -1}} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{mr: 0.75}}/>
                            Add New Activity
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {
                isFetching ? (
                    <CardContent>
                        <Typography variant="body1">Loading....</Typography>
                    </CardContent>
                ) : (treeData.length !== 0 ? (
                    <CardContent>
                        <ActivityList
                            onChange={onTreeChange} data={treeData}
                            updateTreeNode={updateTreeNode}
                            handleAddSubActivity={handleAddClick}
                            handleActivityEditClick={handleActivityEditClick}
                        />
                    </CardContent>
                ) : (
                    <CardContent>
                        <Typography variant="body1">No activity data found</Typography>
                    </CardContent>
                ))}

            <Dialog
                onBackdropClick={() => setIsModalOpen(true)}
                maxWidth="sm"
                fullWidth
                onClose={(event, reason) => {
                    if (reason && reason === 'backdropClick') return;
                    handleModalClose();
                }}
                open={isModalOpen}
                sx={{'& .MuiDialog-paper': {p: 0}}}
            >
                {isModalOpen && (
                    <ActivityAdd
                        parentActivityId={parentActivityId}
                        activity={activity}
                        projectId={projectId ?? ''}
                        onCancel={handleModalClose}
                        handleCreate={handleActivityCreate}
                        handleUpdate={handleActivityUpdate}
                        handleDelete={handleActivityDelete}
                    />
                )}
            </Dialog>
        </MainCard>
    );
};

export default Index;