import * as yup from 'yup';

const implemntationMeasures = [
    {
        label: 'Indicators',
        id: 1,
    },
    {
        label: 'Milestones',
        id: 2,
    },
    {
        label: 'Sub-Activities',
        id: 3,
    },
]

const measuringUnits = [
    {
        label: 'Dollar',
        id: 1
    },
    {
        label: 'Hectare',
        id: 2
    },
    {
        label: 'Kilometer',
        id: 3
    },
    {
        label: 'Number',
        id: 4
    },
    {
        label: 'Percentage',
        id: 5
    },
    {
        label: 'Person',
        id: 6
    },
];

const activityInitialValues: any = {
    name: '',
    code: '',
    description: '',
    dateRange: [null, null],
    activityType: '',
    crossCutting: '',
    responsible: '',
    implementation: '',
    implementationList: {
        data: [],
    }
}

const activityValidationSchema = yup.object({
    name: yup
        .string()
        .required('name is required'),
    activityType: yup
        .string()
        .required('Activity Type is required'),
    crossCutting: yup
        .string()
        .required('Cross Cutting  is required'),
    implementation: yup
        .string()
        .required('Implementation  is required'),
})

const defaultActivityProfile: any = {
    Activity: {
        "id": 6,
        "description": "",
        "logframe_activity": '',
        "ActivityImplementationMeasure": { id: '', name: '' },
        "ActivityType": { name: '' },
        "CrossCuttingIssue": { name: '' },
        "ActivityStatus": { id: 1, name: '' },
        "ActivityTargets": [],
        "ActivityProgresses": [],
        "ActivityTimelines": [
            {
                start: '',
                end: ''
            }
        ],
        "ActivityMilestones": [
        ],
        "SubActivities": []
    }
}

const dStyle = { padding: '7%', paddingLeft: '0%' }
const activityStatus = [
    {
        label: 'Not Started',
        id: 1
    },
    {
        label: 'Ongoing',
        id: 2
    },
    {
        label: 'Paused',
        id: 3
    },
    {
        label: 'Completed',
        id: 4
    }
]

const getAchieveColor = (row: any) => {
    return row.isAchieved === null ? 'grey' : 'green';
}

const requiredColor = {
    color: 'red',
}

const demoActivityProfile = { "name": "Activity 1 ", "code": "hausdyiu", "description": "kajsdh fkajsdhf jkhasdjfh a alsdhf kasjdfklahjsdf", "dateRange": [{ "start_date": "2022-02-28T18:00:00.000Z", "end_date": "2022-04-29T18:00:00.000Z" }], "activityType": { "type": "ActivityTypes", "id": "1", "name": "Survey", "project": 30, "links": { "self": "http://localhost:3000/activitytypebyid/1" } }, "crossCutting": { "type": "CrossCuttingIssues", "id": "2", "name": "Sustainablity", "project": 30, "links": { "self": "http://localhost:3000/crosscuttingissuebyid/undefined" } }, "responsible": "", "implementation": { "label": "Indicators", "id": 1 }, "implementationList": { "data": [{ "target": "45", "date": "2022-03-01" }, { "target": "65", "date": "2022-03-08" }, { "target": "70", "date": "" }], "measuring_unit": "{\"type\":\"UnitOfMeasurements\",\"id\":\"6\",\"name\":\"Dollar\",\"project\":30,\"links\":{\"self\":\"http://localhost:3000/unit_of_measurement/6\"}}" }, "parent": 65, "config": { "data": { "type": "logframeStructures", "id": "106", "attributes": { "id": 106, "color": "red", "node-json": { "id": "1_kytro2pfs6r6ugp6up_kytro9dv7rglhoqznz2_kytrogkem3m0n7vih_l055sm3py0yi7j83tfn", "data": { "name": "ACTIVITY", "color": "red", "level": 5, "width": "150", "parent": "output", "form_url": "", "isActivity": true, "description": "lololol" }, "type": "childNode", "children": [], "position": { "x": 300, "y": 608 } }, "child": [] } } }, "logframetype": "106" }

// const demoActivityProfile = {"name":"Activity 2","code":"hausdyiu","description":"kajsdh fkajsdhf jkhasdjfh a alsdhf kasjdfklahjsdf","dateRange":[{"start_date":"2022-02-28T18:00:00.000Z","end_date":"2022-04-29T18:00:00.000Z"}],"activityType":{"type":"ActivityTypes","id":"2","name":"Training","project":30,"links":{"self":"http://localhost:3000/activitytypebyid/2"}},"crossCutting":{"type":"CrossCuttingIssues","id":"1","name":"COVID","project":30,"links":{"self":"http://localhost:3000/crosscuttingissuebyid/undefined"}},"responsible":"","implementation":{"label":"Milestones","id":2},"implementationList":{"data":[{"description":"mPower is the social enterprise which is moving the development paradigm into the information age. Our global and multidisciplinary team codesigns with partners to iteratively learn and solve development challenges.","date":"2022-03-09"},{"description":"df dfdsf","date":"2022-03-02"}]},"parent":65,"config":{"data":{"type":"logframeStructures","id":"106","attributes":{"id":106,"color":"red","node-json":{"id":"1_kytro2pfs6r6ugp6up_kytro9dv7rglhoqznz2_kytrogkem3m0n7vih_l055sm3py0yi7j83tfn","data":{"name":"ACTIVITY","color":"red","level":5,"width":"150","parent":"output","form_url":"","isActivity":true,"description":"lololol"},"type":"childNode","children":[],"position":{"x":300,"y":608}},"child":[]}}},"logframetype":"106"};

// const demoActivityProfile = {"name":"Activity 3","code":"hausdyiu","description":"kajsdh fkajsdhf jkhasdjfh a alsdhf kasjdfklahjsdf","dateRange":[{"start_date":"2022-02-28T18:00:00.000Z","end_date":"2022-04-29T18:00:00.000Z"}],"activityType":{"type":"ActivityTypes","id":"3","name":"Planning","project":30,"links":{"self":"http://localhost:3000/activitytypebyid/3"}},"crossCutting":{"type":"CrossCuttingIssues","id":"2","name":"Sustainablity","project":30,"links":{"self":"http://localhost:3000/crosscuttingissuebyid/undefined"}},"responsible":"","implementation":{"label":"Sub-Activities","id":3},"implementationList":{"data":[{"name":"dfasdf ","start_date":"2022-03-02","end_date":"2022-03-03","weighting":"4"},{"name":" easdfa sdf","start_date":"2022-03-15","end_date":"2022-03-16","weighting":"5"}]},"parent":65,"config":{"data":{"type":"logframeStructures","id":"106","attributes":{"id":106,"color":"red","node-json":{"id":"1_kytro2pfs6r6ugp6up_kytro9dv7rglhoqznz2_kytrogkem3m0n7vih_l055sm3py0yi7j83tfn","data":{"name":"ACTIVITY","color":"red","level":5,"width":"150","parent":"output","form_url":"","isActivity":true,"description":"lololol"},"type":"childNode","children":[],"position":{"x":300,"y":608}},"child":[]}}},"logframetype":"106"}

export {
    requiredColor,
    implemntationMeasures,
    measuringUnits,
    activityInitialValues,
    activityValidationSchema,
    defaultActivityProfile,
    demoActivityProfile,
    dStyle,
    activityStatus,
    getAchieveColor,
};