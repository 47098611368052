import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IReportingPeriodType } from 'types';
import { useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ReportingPeriodTypeModal from './Modal';
import ClientPagingTable from 'components/Table/ClientPagingTable';
import Loader from 'ui-component/Loader';
import useGetRPTListByProjectId from 'hooks/consume_api/query/useGetRPTListByProjectId';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import useRPTDelete from 'hooks/consume_api/mutation/useRPTDelete';
import { columnListNameRPT, dataColumns } from '../Constant';

const ReportingPeriodType = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const { rptList, isFetching } = useGetRPTListByProjectId(projectId, true);
    const { deleteRPTAsynchronously } = useRPTDelete();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<IReportingPeriodType | null>(null);

    const handleDeleteConfirm = async () => {
        await deleteRPTAsynchronously({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsEditMode(false);
    };

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx-={{ padding: 0 }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Reporting Period Type List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Reporting Period Type
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : rptList.length !== 0 ? (
                <CardContent>
                    <ClientPagingTable
                        data={rptList}
                        columns={dataColumns(
                            theme,
                            columnListNameRPT,
                            setSelectedData,
                            setIsModalOpen,
                            setIsDeleteModalOpen,
                            setIsEditMode
                        )}
                        showSL
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Reporting Period Type data found</Typography>
                </CardContent>
            )}

            {isModalOpen && (
                <ReportingPeriodTypeModal
                    rpType={selectedData}
                    dataList={rptList}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setIsModalOpen={setIsModalOpen}
                    setSelectedData={setSelectedData}
                    handleModalClose={handleModalClose}
                    isModalOpen={isModalOpen}
                    projectId={projectId!}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Reporting Period Type '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default ReportingPeriodType;
