import { useEffect, useState } from 'react';
import { ActivityStatus } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { activityStatusKeys } from '../../../react_query/query-keys';
import { DEFAULT_STALE_TIME } from '../../../utils/Constants';
import { getActivityStatusList } from '../../../services/reactQueryservices';

const useGetActivityStatusList = (dependencies: boolean = true) => {
    const [initiateFetchingActivityStatusList, setInitiateFetchingActivityStatusList] = useState(true);
    const [activityStatusList, setActivityStatusList] = useState<ActivityStatus[]>([]);

    const result = useQuery<ActivityStatus[], Error>({
        queryKey: activityStatusKeys.all,
        queryFn: getActivityStatusList,
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingActivityStatusList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setActivityStatusList(result.data);
        }
    }, [result.data]);

    return {
        activityStatusList,
        ...result,
        setInitiateFetchingActivityStatusList
    };
};

export default useGetActivityStatusList;
