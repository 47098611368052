import React, { Dispatch, SetStateAction } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import langString from 'utils/langString';

interface ConfirmDeleteModalType {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    title?: string;
    subTitle?: string;
    handleConfirm: () => void;
}

const ConfirmDeleteModal = ({ open, setOpen, title, subTitle, handleConfirm }: ConfirmDeleteModalType) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 3 }}
        >
            <DialogTitle id="alert-dialog-title">{title || 'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="body2" component="span">
                        {subTitle && subTitle}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                <Button
                    sx={{
                        color: theme.palette.error.dark,
                        borderColor: theme.palette.error.dark
                    }}
                    onClick={() => setOpen(false)}
                    color="secondary"
                >
                    {langString('cancel')}
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        handleConfirm();
                        setOpen(false);
                    }}
                    autoFocus
                >
                    {langString('confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteModal;
