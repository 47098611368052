import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { UOM } from 'types';
import { useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import UnitOfMeasurementModal from './Modal';
import ClientPagingTable from 'components/Table/ClientPagingTable';
import Loader from 'ui-component/Loader';
import useGetUOMListByProjectId from 'hooks/consume_api/query/useGetUOMListByProjectId';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import useUOMDelete from 'hooks/consume_api/mutation/useUOMDelete';
import { columnListNameUOM, dataColumns } from '../Constant';

const UnitOfMeasurement = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const { uomList, isFetching } = useGetUOMListByProjectId(projectId, true);
    const { deleteUOMAsynchronously } = useUOMDelete();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedData, setSelectedData] = useState<UOM | null>(null);

    const handleDeleteConfirm = async () => {
        await deleteUOMAsynchronously({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsEditMode(false);
    };

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx-={{ padding: 0 }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Unit of Measurement List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Unit of Measurement
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : uomList.length !== 0 ? (
                <CardContent>
                    <ClientPagingTable
                        data={uomList}
                        columns={dataColumns(
                            theme,
                            columnListNameUOM,
                            setSelectedData,
                            setIsModalOpen,
                            setIsDeleteModalOpen,
                            setIsEditMode
                        )}
                        showSL
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Unit Of Measurement data found</Typography>
                </CardContent>
            )}

            {isModalOpen && (
                <UnitOfMeasurementModal
                    uom={selectedData}
                    isEditMode={isEditMode}
                    setIsModalOpen={setIsModalOpen}
                    handleModalClose={handleModalClose}
                    isModalOpen={isModalOpen}
                    projectId={projectId!}
                />
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Unit of Measurement '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default UnitOfMeasurement;
