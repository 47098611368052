// material-ui
import { Box, Grid, TextField, FormHelperText, Button } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { gridSpacing } from 'store/constant';
import React, { useEffect, useRef, useState } from 'react';
import { activityInitialValues, implemntationMeasures, activityValidationSchema, requiredColor } from '../constants';
import { AddImplementationMeasure } from '../Implementations';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { getActivityTypeListOfProject, getCrossCuttingIssueListOfProject, getUOMListOfProject } from 'features/IndicatorSettings/Service';
import { _deserailize } from 'utils/Deserialize';

interface ActivityAddProps {
    info: any;
    onSubmit: (values: any) => void;
    closeDialog: () => any;
}
const ActivityAdd: React.FC<ActivityAddProps> = (props) => {
    const [activityTypes, setActivityTypes] = useState<any>([]);
    const [crossCuttingIssues, setCrossCuttingIssues] = useState<any>([]);
    const [uom, setUOM] = useState<any>([]);
    const params = useParams();
    const auth = useAuth();

    const formik = useFormik({
        initialValues: activityInitialValues,
        validationSchema: activityValidationSchema,
        onSubmit: (values: any) => {
            console.log({ ...values, parent: props.info.node.node.uid });
            const result = {
                ...values,
                activityType: values.activityType,
                crossCutting: values.crossCutting,
                implementation: JSON.parse(values.implementation)
            };
            console.log('activity object: ', { result });
            props.onSubmit({ ...result, parent: props.info.node.node.uid });
        }
    });

    useEffect(() => {
        const init = async () => {
            const activityTypeResponse = await getActivityTypeListOfProject(params.id);
            const crossCuttingResponse = await getCrossCuttingIssueListOfProject(params.id);
            const uomResponse = await getUOMListOfProject(params.id);
            setUOM(uomResponse.data.data);
            setActivityTypes(activityTypeResponse.data.data);
            setCrossCuttingIssues(crossCuttingResponse.data.data);
        };
        init();
    }, []);

    const errorMsg = (field: string) => {
        return formik.errors[field] && formik.touched[field] ? formik.errors[field] : null;
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} lg={6}>
                                <InputLabel>
                                    Name <span style={requiredColor}>*</span>
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    name="name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    placeholder="Enter name"
                                />
                                <FormHelperText sx={requiredColor}>{errorMsg('name')}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InputLabel>Code</InputLabel>
                                <TextField
                                    type="text"
                                    name="code"
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    fullWidth
                                    placeholder="Enter Code"
                                />
                                <FormHelperText>Please enter code</FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    placeholder="Enter Description"
                                    rows={3}
                                    multiline
                                />
                                <FormHelperText>Please enter your description</FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <InputLabel>Planned Timeline</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateRangePicker
                                        calendars={2}
                                        onChange={(e) => formik.setFieldValue('dateRange', e)}
                                        value={formik.values.dateRange}
                                        renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField fullWidth {...startProps} />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField fullWidth {...endProps} />
                                            </>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={10}>
                                <InputLabel>
                                    Implementation Measure <span style={requiredColor}>*</span>
                                </InputLabel>
                                <Select
                                    fullWidth
                                    name="implementation"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('implementationList', { data: [] });
                                    }}
                                    value={formik.values.implementation}
                                >
                                    {implemntationMeasures.map((im: any, index: number) => (
                                        <MenuItem value={JSON.stringify(im)} key={index}>
                                            {im.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={requiredColor}>{errorMsg('implementationList')}</FormHelperText>
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={10}>
                                <AddImplementationMeasure
                                    type={formik.values.implementation}
                                    data={formik.values.implementationList}
                                    uom={uom}
                                    setData={(data: any) => {
                                        formik.setFieldValue('implementationList', data);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={12} lg={6}>
                                <InputLabel>
                                    Activity Types <span style={requiredColor}>*</span>
                                </InputLabel>
                                <Select fullWidth name="activityType" onChange={formik.handleChange} value={formik.values.activityType}>
                                    {activityTypes.map((item: any, index: number) => (
                                        <MenuItem value={item.id} key={index}>
                                            {item.attributes.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={requiredColor}>{errorMsg('activityType')}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InputLabel>
                                    Cross Cutting Issues <span style={requiredColor}>*</span>{' '}
                                </InputLabel>
                                <Select fullWidth name="crossCutting" onChange={formik.handleChange} value={formik.values.crossCutting}>
                                    {crossCuttingIssues.map((item: any, index: number) => (
                                        <MenuItem value={item.id} key={index}>
                                            {item.attributes.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={requiredColor}>{errorMsg('crossCutting')}</FormHelperText>
                            </Grid>
                            <Grid item xs={5} />
                            <Grid item xs={1}>
                                <Button type="submit" variant="outlined">
                                    {' '}
                                    Submit{' '}
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={props.closeDialog} color="error" variant="outlined">
                                    {' '}
                                    Cancel{' '}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default ActivityAdd;
