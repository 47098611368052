import React, { Dispatch, SetStateAction } from 'react';
import { UOM } from '../../../../../types';
import Dialog from '@mui/material/Dialog';
import UOMForm from './Form';
import { FormikValues } from 'formik';
import useUOMCreateOrUpdate from 'hooks/consume_api/mutation/useUOMCreateOrUpdate';

interface UnitOfMeasurementModalType {
    uom?: UOM | null;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    handleModalClose: () => void;
    isModalOpen: boolean;
    isEditMode: boolean;
    projectId: string;
}

const UnitOfMeasurementModal = ({
    uom = null,
    setIsModalOpen,
    handleModalClose,
    isModalOpen,
    isEditMode,
    projectId
}: UnitOfMeasurementModalType) => {
    const { createOrUpdateUOMAsynchronously } = useUOMCreateOrUpdate();

    const handleSave = async (data: FormikValues) => {
        await createOrUpdateUOMAsynchronously(data);
    };

    return (
        <Dialog
            onBackdropClick={() => setIsModalOpen(true)}
            maxWidth="sm"
            fullWidth
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleModalClose();
            }}
            open={isModalOpen}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
            <UOMForm uom={uom} handleSave={handleSave} onCancel={handleModalClose} projectId={projectId} isEditMode={isEditMode} />
        </Dialog>
    );
};

export default UnitOfMeasurementModal;
