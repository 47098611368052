import { _deserailize } from 'utils/Deserialize';
import axiosServices from 'utils/axiosServices';
import {
    createDisaggregationURL,
    createDisaggregationItemURL,
    getUOMListOfProjectURL,
    getCrossCuttingIssueListOfProjectURL,
    getCollectionFrequencyListOfProjectURL,
    getDisaggregationListOfProjectURL,
    updateIndicatorSettingOfLogframeURL,
    getIndicatorSettingOfLogframeURL,
    getIndicatorTargetOfLogframeURL,
    updateIndicatorTargetOfLogframeURL,
    getIndicatorDataOfLogframeURL,
    getIndicatorProgressSummaryOfLogframeURL,
    updateIndicatorDataOfLogframeURL,
    createUOMURL,
    createCrossCuttingIssueURL,
    getActivityTypeListOfProjectURL,
    createActivityTypeURL,
    updateContributingIndicatorURL,
    getContributingIndicatorListURL,
    getContributingIndicatorDetailURL,
    getReportingPeriodOfProjectURL,
    getTargetVsCollectionDataOfIndicatorURL
} from 'utils/serverUrls';

export const createDisaggregationType = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${createDisaggregationURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createDisaggregationItem = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${createDisaggregationItemURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const updateIndicatorSetting = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${updateIndicatorSettingOfLogframeURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const updateIndicatorTarget = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${updateIndicatorTargetOfLogframeURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const updateIndicatorData = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${updateIndicatorDataOfLogframeURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const updateContributingIndicator = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${updateContributingIndicatorURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createUOM = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${createUOMURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createCrossCuttingIssue = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${createCrossCuttingIssueURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createActivityType = async (payload: any) => {
    try {
        const response: any = await axiosServices.post(`${createActivityTypeURL}`, payload);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getUOMListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getUOMListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getCrossCuttingIssueListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getCrossCuttingIssueListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getContributingIndicatorList = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getContributingIndicatorListURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getContributingIndicatorDetail = async (dataId: any) => {
    try {
        const params = { indicatorId: dataId };
        const response: any = await axiosServices.get(`${getContributingIndicatorDetailURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getActivityTypeListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getActivityTypeListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getCollectionFrequencyListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getCollectionFrequencyListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getDisaggregationListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getDisaggregationListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getTimePeriodListOfProject = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getReportingPeriodOfProjectURL}`, { params });
        return response.data;
    } catch (ex) {
        console.log('error ::: ', ex);
        return [];
    }
};

export const getTargetVsCollectionDataOfIndicator = async (dataId: any) => {
    try {
        const params = { indicatorId: dataId };
        const response: any = await axiosServices.get(`${getTargetVsCollectionDataOfIndicatorURL}`, { params });
        return response.data;
    } catch (ex) {
        console.log('error ::: ', ex);
        return [];
    }
};

export const getIndicatorSettingOfLogframe = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getIndicatorSettingOfLogframeURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getIndicatorTargetOfLogframe = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getIndicatorTargetOfLogframeURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getIndicatorDataOfLogframe = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getIndicatorDataOfLogframeURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getIndicatorProgressSummaryOfLogframe = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getIndicatorProgressSummaryOfLogframeURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export default {};
