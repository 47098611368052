import React from 'react';
import { Grid, LinearProgress, Tooltip, Typography } from '@mui/material';

interface LinearProgressBarWithLabelProps {
    name?: string;
    value: number;
    designObj?: any;
}

const LinearProgressBarWithLabel = ({ name = 'Achieved', value, designObj = {} }: LinearProgressBarWithLabelProps) => {
    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item sm zeroMinWidth />
                <Grid item>
                    <Typography variant="body2" align="right">
                        {value}%
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={`${name}: ${value}%`}>
                        <LinearProgress variant="determinate" value={value} color="primary" sx={{ ...designObj }} />
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LinearProgressBarWithLabel;
