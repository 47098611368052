import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import useSnackbar from 'hooks/useSnackbar';

const useStyles = makeStyles((theme: any) => ({
    formControl: {
        sx: {
            margin: 3
        }
    },
    button: {
        sx: {
            mt: 1,
            ml: 1
        }
    }
}));

export default function FormRenderOption(props: any) {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const toastMsg = useSnackbar();

    const handleRadioChange = (event: any) => {
        setValue(event.target.value);
        setError(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!_.isEmpty(value)) props.onSubmit(value);
        else toastMsg('Please select an option', 'error');
    };

    console.log('i am rendering form render options. !!');
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" error={error} className={classes.formControl}>
                        <FormLabel component="legend">Select form option to continue</FormLabel>
                        <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                            {props.options.map((opt: any) => {
                                return (
                                    <FormControlLabel
                                        key={opt.data.id}
                                        value={opt.data.id}
                                        control={<Radio />}
                                        label={opt.data.attributes.nodeJson.data.name}
                                    />
                                );
                            })}
                        </RadioGroup>

                        <Button type="submit" variant="outlined" color="primary" className={classes.button}>
                            submit
                        </Button>
                    </FormControl>
                </form>
            </DialogContent>
        </Dialog>
    );
}
