import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from 'react_query/query-client-configuration';
import { rpKeys } from 'react_query/query-keys';
import { createOrUpdateReportingPeriod } from 'services/reactQueryservices';

const useReportingPeriodCreateOrUpdate = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: createOrUpdateReportingPeriod,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to save Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: rpKeys.all });
                snackbar('Successfully saved');
            }
        }
    });

    return {
        createOrUpdateReportingPeriodSynchronously: mutate,
        createOrUpdateReportingPeriodAsynchronously: mutateAsync,
        isReportingPeriodCreationOrUpdateLoading: isLoading,
        reportingPeriodCreationOrUpdateError: error,
        isReportingPeriodCreationOrUpdateError: isError,
        isReportingPeriodCreationOrUpdateSuccess: isSuccess
    };
};

export default useReportingPeriodCreateOrUpdate;
