import { _deserailize } from 'utils/Deserialize';
import axiosServices from 'utils/axiosServices';
import {
    geoTypeListURL,
    geoDataByParentListURL,
    createProjectSitesURL,
    getProjectSitesListURL,
    getActivitySitesListURL,
    createActivitySitesURL,
    geoDataOfProjectByParentListURL,
    createMemberSitesURL,
    getMemberSitesListURL,
    deleteProjectSiteURL,
    deleteMemberSiteURL,
    getReportingPeriodTypeListOfProjectURL,
    getReportingPeriodOfProjectURL
} from 'utils/serverUrls';
import { GeoDataByParentListPayloadType, CreateSitesPayloadType } from 'components/Sites/Constant';

export const responseErrorHandler = (ex: any) => {
    if (ex.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Server responded with status code:', ex.response.status);
        console.log('Error message from server:', ex.response.data);

        // You can return or handle the error message as needed
        return { status: ex.response.status, text: ex.response.data.message || 'Something went wrong' };
    }
};

export const getGeoTypeList = async () => {
    try {
        const response = await axiosServices.get(`${geoTypeListURL}`);
        const responseData = await _deserailize(response.data);
        return responseData;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const geoDataByParentList = async (payload: GeoDataByParentListPayloadType, key: string, dataId: number | string) => {
    try {
        let response;
        if (key === 'activityId') {
            response = await axiosServices.post(`${geoDataOfProjectByParentListURL}`, { ...payload, [key]: dataId });
        } else {
            response = await axiosServices.post(`${geoDataByParentListURL}`, payload);
        }
        return response.data;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const geoSitesList = async (key: string, dataId: number | string, extra: any = null) => {
    try {
        let url;
        let payload: any = {
            [key]: dataId,
            ...extra?.params
        };

        switch (key) {
            case 'projectId':
                url = getProjectSitesListURL;
                break;
            case 'memberId':
                url = getMemberSitesListURL;
                break;
            default:
                url = getActivitySitesListURL;
        }

        const response = await axiosServices.post(`${url}`, payload);
        return response.data;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createSites = async (payload: CreateSitesPayloadType, dataId: number, key: string, extra: any) => {
    try {
        let url;
        const requestPayload: any = {
            ...payload,
            [key]: dataId,
            ...extra?.params
        };

        switch (key) {
            case 'projectId':
                url = createProjectSitesURL;
                break;
            case 'memberId':
                url = createMemberSitesURL;
                break;
            default:
                url = createActivitySitesURL;
        }

        const response = await axiosServices.post(`${url}`, requestPayload);
        return response.status;
    } catch (ex) {
        console.log('error ::: ', ex);
        return 400;
    }
};

export const deleteSites = async (dataId: number | string, key: string, extra: any) => {
    try {
        let url;
        const payload: any = { siteId: extra.siteId };

        switch (key) {
            case 'projectId':
                url = deleteProjectSiteURL;
                payload.projectId = dataId;
                break;
            case 'memberId':
                url = deleteMemberSiteURL;
                payload.projectId = extra.projectId;
                payload.memberId = dataId;
                break;
            default:
                url = deleteMemberSiteURL;
        }

        const response = await axiosServices.post(`${url}`, payload);
        return response;
    } catch (ex: any) {
        return responseErrorHandler(ex);
    }
};

export const getReportingPeriodTypeListOfProject = async (dataId: string) => {
    try {
        const params = { projectId: dataId };
        const response = await axiosServices.get(`${getReportingPeriodTypeListOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        return responseErrorHandler(ex);
    }
};

export const getTimePeriodListOfProject = async (dataId?: any, year?: string) => {
    try {
        const params = { reportingPeriodTypeId: dataId, year };
        const response: any = await axiosServices.get(`${getReportingPeriodOfProjectURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return responseErrorHandler(ex);
    }
};

export default { getGeoTypeList, geoDataByParentList, geoSitesList, createSites };
