import { useEffect, useState } from 'react';
import { UOM } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { uomKeys } from '../../../react_query/query-keys';
import { getUomListByProjectId } from '../../../services/reactQueryservices';
import { DEFAULT_STALE_TIME } from 'utils/Constants';

const useGetUOMListByProjectId = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingList, setInitiateFetchingList] = useState(true);
    const [uomList, setUomList] = useState<UOM[]>([]);

    const result = useQuery<UOM[], Error>({
        queryKey: uomKeys.list({ projectId }),
        queryFn: () => getUomListByProjectId(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setUomList(result.data);
        }
    }, [result.data, result.isFetched]);

    return {
        uomList,
        ...result,
        setInitiateFetchingList
    };
};

export default useGetUOMListByProjectId;
