import IndicatorSettings from '../../features/IndicatorSettings';
import IndicatorTargets from '../../features/Targets';
import DataUploadConfig from '../../features/DataUploadConfig';
import IndicatorProfile from '../../features/IndicatorProfile';
import DataCollection from './DataCollection';

export default {
    IndicatorSettings,
    IndicatorTargets,
    DataUploadConfig,
    DataCollection,
    IndicatorProfile
};
