import { useTheme } from '@mui/material/styles';
import { Chip, Tooltip } from '@mui/material';

const SDGTag = ({ name }: any) => {
    const theme = useTheme();
    return (
        <Tooltip title={name}>
            <Chip
                label={name.length > 16 ? `${name.slice(0, 16)}...` : name}
                size="small"
                sx={{
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                    color: theme.palette.success.dark,
                    marginLeft: '6px'
                }}
            />
        </Tooltip>
    );
};

export default function SDGTagList({ sdgList }: any) {
    return sdgList.map((sdgData: any, index: number) => <SDGTag key={index} name={sdgData.sdg.sdgName} />);
}
