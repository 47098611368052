import React, { Dispatch, SetStateAction } from 'react';
import { CollectionFrequency } from 'types';
import Dialog from '@mui/material/Dialog';
import CollectionFrequencyForm from './Form';
import { FormikValues } from 'formik';
import useCollectionFrequencyCreateOrUpdate from 'hooks/consume_api/mutation/useCollectionFrequencyCreateOrUpdate';

interface CollectionFrequencyModalType {
    collectionFrequency?: CollectionFrequency | null;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    handleModalClose: () => void;
    isModalOpen: boolean;
    isEditMode: boolean;
    projectId: string;
}

const CollectionFrequencyModal = ({
    collectionFrequency = null,
    setIsModalOpen,
    handleModalClose,
    isModalOpen,
    isEditMode,
    projectId
}: CollectionFrequencyModalType) => {
    const { createOrUpdateCollectionFrequencyAsynchronously } = useCollectionFrequencyCreateOrUpdate();

    const handleSave = async (data: FormikValues) => {
        await createOrUpdateCollectionFrequencyAsynchronously(data);
    };

    return (
        <Dialog
            onBackdropClick={() => setIsModalOpen(true)}
            maxWidth="sm"
            fullWidth
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleModalClose();
            }}
            open={isModalOpen}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
            <CollectionFrequencyForm
                collectionFrequency={collectionFrequency}
                handleSave={handleSave}
                onCancel={handleModalClose}
                projectId={projectId}
                isEditMode={isEditMode}
            />
        </Dialog>
    );
};

export default CollectionFrequencyModal;
