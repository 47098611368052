import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from 'react_query/query-client-configuration';
import { uomKeys } from 'react_query/query-keys';
import { deleteUOM } from 'services/reactQueryservices';

const useUOMDelete = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: deleteUOM,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to delete Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: uomKeys.all });
                snackbar('Successfully deleted');
            }
        }
    });

    return {
        deleteUOMSynchronously: mutate,
        deleteUOMAsynchronously: mutateAsync,
        isUOMDeleteLoading: isLoading,
        uomDeleteError: error,
        isUOMDeleteError: isError,
        isUOMDeleteSuccess: isSuccess
    };
};

export default useUOMDelete;
