import React from 'react';
import { ActivityCreationPayload } from '../../../../types';
import * as Yup from 'yup';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { format } from 'date-fns';
import {
    Button,
    Checkbox,
    DialogActions,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField
} from '@mui/material';
import { gridSpacing } from '../../../../store/constant';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { MobileDatePicker } from '@mui/lab';
import useGetTeamUserList from '../../../../hooks/consume_api/query/useGetTeamUserList';
import useGetActivityStatusList from '../../../../hooks/consume_api/query/useGetActivityStatusList';
import useGetActivityPhaseList from '../../../../hooks/consume_api/query/useGetActivityPhaseList';
import _ from 'lodash';

const getInitialValues = (activity: FormikValues | null, projectId: string, parentActivityId: string) => {
    const newActivity = {
        id: '',
        parentId: parentActivityId,
        projectId: projectId,
        title: '',
        responsiblePerson: '',
        activityStatus: '',
        phase: '',
        isMilestone: false,
        startDate: '',
        dueDate: '',
        description: '',
        totalBudget: ''
    };

    if (activity) {
        // Extract the id from activity.responsiblePerson if available
        const responsiblePersonId = activity.responsiblePerson ? activity.responsiblePerson.id : '';
        const activityStatusId = activity.activityStatus ? activity.activityStatus.id : '';
        const phaseId = activity.activityPhase ? activity.activityPhase.id : '';

        // Pick only the properties defined in newActivity
        const pickedActivity = _.pick(activity, Object.keys(newActivity));

        // Use object spread syntax to create a new object with the merged values

        return {
            ...newActivity,
            ...pickedActivity,
            responsiblePerson: responsiblePersonId,
            activityStatus: activityStatusId,
            phase: phaseId
        };
    }

    return newActivity;
};

export interface ActivityAddFormProps {
    activity: FormikValues | null;
    parentActivityId: string;
    projectId: string;
    handleDelete: (id: string) => void;
    handleCreate: (data: FormikValues) => void;
    handleUpdate: (data: FormikValues) => void;
    onCancel: () => void;
}

const ActivityAdd = ({
    activity,
    handleDelete,
    handleCreate,
    handleUpdate,
    onCancel,
    projectId,
    parentActivityId
}: ActivityAddFormProps) => {
    const { userList, isFetching: isUserListFetching } = useGetTeamUserList(projectId);
    const { activityStatusList } = useGetActivityStatusList();
    const { activityPhaseList } = useGetActivityPhaseList();

    const isCreating = !activity;

    const ActivitySchema = Yup.object().shape({
        title: Yup.string().max(255, 'Title must be at most 255 characters').required('Title is required'),
        responsiblePerson: Yup.string().required('Responsible person is required'),
        activityStatus: Yup.string().required('Progress is required'),
        phase: Yup.string(),
        isMilestone: Yup.boolean(),
        startDate: Yup.string().required('Start date is required'),
        dueDate: Yup.string().required('Due date is required'),
        description: Yup.string(),
        totalBudget: Yup.string()
    });

    const formik = useFormik<ActivityCreationPayload>({
        initialValues: getInitialValues(activity, projectId, parentActivityId),
        validationSchema: ActivitySchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                // Format batchStartDate and batchEndDate
                const formattedStartDate = format(new Date(values.startDate), 'yyyy-MM-dd');
                const formattedDueDate = format(new Date(values.dueDate), 'yyyy-MM-dd');

                const activityData = {
                    id: values.id,
                    parentId: parentActivityId,
                    projectId: values.projectId,
                    title: values.title,
                    responsiblePerson: values.responsiblePerson,
                    activityStatus: values.activityStatus,
                    phase: values.phase,
                    isMilestone: values.isMilestone,
                    startDate: formattedStartDate,
                    dueDate: formattedDueDate,
                    description: values.description,
                    totalBudget: values.totalBudget
                };

                if (activity) {
                    handleUpdate(activityData);
                } else {
                    handleCreate(activityData);
                }

                resetForm();
                setSubmitting(false);
                onCancel();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle>{activity ? 'Edit Activity' : 'Add Activity'}</DialogTitle>
                <Divider />
                {!isUserListFetching && (
                    <DialogContent sx={{ p: 3 }}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    {...getFieldProps('title')}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    rows={3}
                                    fullWidth
                                    multiline
                                    label="Description"
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={userList}
                                    getOptionLabel={(option) => `${option.firstName}` + `${option.lastName ? ' ' + option.lastName : ''}`}
                                    onChange={(event, newValue) => {
                                        setFieldValue('responsiblePerson', newValue ? newValue.id : ''); // Set organizationId when an organization is selected
                                    }}
                                    value={userList.find((user) => user.id === values.responsiblePerson) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Responsible Person"
                                            error={Boolean(touched.responsiblePerson && errors.responsiblePerson)}
                                            helperText={touched.responsiblePerson && errors.responsiblePerson}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={activityStatusList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setFieldValue('activityStatus', newValue ? newValue.id : ''); // Set organizationId when an organization is selected
                                    }}
                                    value={activityStatusList.find((status) => status.id === values.activityStatus) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Progress"
                                            error={Boolean(touched.activityStatus && errors.activityStatus)}
                                            helperText={touched.activityStatus && errors.activityStatus}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={activityPhaseList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setFieldValue('phase', newValue ? newValue.id : ''); // Set organizationId when an organization is selected
                                    }}
                                    value={activityPhaseList.find((phase) => phase.id === values.phase) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Phase"
                                            error={Boolean(touched.phase && errors.phase)}
                                            helperText={touched.phase && errors.phase}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values?.isMilestone}
                                            onChange={(event) => {
                                                setFieldValue('isMilestone', event.target.checked);
                                            }}
                                        />
                                    }
                                    label="Is Milstone"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MobileDatePicker
                                    label="Start Date"
                                    value={values.startDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(date) => setFieldValue('startDate', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...getFieldProps('startDate')}
                                            {...params}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            error={Boolean(touched.startDate && errors.startDate)}
                                            helperText={touched.startDate && errors.startDate}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MobileDatePicker
                                    label="Due Date"
                                    value={values.dueDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(date) => setFieldValue('dueDate', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...getFieldProps('dueDate')}
                                            {...params}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            error={Boolean(touched.dueDate && errors.dueDate)}
                                            helperText={touched.dueDate && errors.dueDate}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Total Budget"
                                    {...getFieldProps('totalBudget')}
                                    error={Boolean(touched.totalBudget && errors.totalBudget)}
                                    helperText={touched.totalBudget && errors.totalBudget}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}

                <DialogActions sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {!isCreating && (
                                <Tooltip title="Delete Batch">
                                    <IconButton onClick={() => handleDelete(activity?.id)} size="large">
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button type="button" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" disabled={isSubmitting}>
                                    {activity ? 'Edit' : 'Add'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Form>
        </FormikProvider>
    );
};

export default ActivityAdd;
