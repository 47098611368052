import { useEffect, useState } from 'react';
import { CollectionFrequency } from 'types';
import { useQuery } from '@tanstack/react-query';
import { collectionFrequencyKeys } from 'react_query/query-keys';
import { getCollectionFrequencyListByProjectId } from 'services/reactQueryservices';
import { DEFAULT_STALE_TIME } from 'utils/Constants';

const useGetCollectionFrequencyListByProjectId = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingList, setInitiateFetchingList] = useState(true);
    const [collectionFrequencyList, setCollectionFrequencyList] = useState<CollectionFrequency[]>([]);

    const result = useQuery<CollectionFrequency[], Error>({
        queryKey: collectionFrequencyKeys.list(projectId),
        queryFn: () => getCollectionFrequencyListByProjectId(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setCollectionFrequencyList(result.data);
        }
    }, [result.data, result.isFetched]);

    return {
        collectionFrequencyList,
        ...result,
        setInitiateFetchingList
    };
};

export default useGetCollectionFrequencyListByProjectId;
