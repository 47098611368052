import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Button,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import LinearProgressBarWithLabel from 'components/common/LinearProgressBarWithLabel';
import { Link } from 'react-router-dom';

export default function TargetTable({
    dataList,
    setDataList,
    handleSubmit,
    selectOne,
    editMode,
    useAsDataCollectionModule,
    timePeriodData,
    targetVsCollectionData,
    backLink
}: any) {
    const navigate = useNavigate();
    const removeOption = (index: any) => {
        const opt = [...dataList];
        opt.splice(index, 1);
        setDataList(opt);
    };

    const getNameOfTimePeriod = (idtoCheck: any) => {
        for (const data of timePeriodData) {
            if (data.id == idtoCheck) {
                return data.name;
            }
        }
    };

    const getCollectionProgress = (idtoCheck: any) => {
        for (const data of targetVsCollectionData) {
            if (data.reportingPeriod.id == idtoCheck) {
                const target = parseFloat(data.target);
                const collection = parseFloat(data.collection);
                return {
                    collection: parseInt(data.collection, 10),
                    progress: target !== 0 ? (collection / target) * 100 : 0
                };
            }
        }
        return {
            collection: 0,
            progress: 0
        };
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>#</TableCell>
                            <TableCell align="center">Time Period</TableCell>
                            <TableCell align="center">{useAsDataCollectionModule ? 'Value' : 'Target'}</TableCell>
                            {!editMode && !useAsDataCollectionModule && (
                                <>
                                    <TableCell align="center">Collected</TableCell>
                                    <TableCell align="center">Progress</TableCell>
                                </>
                            )}

                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataList.map((row: any, i: number) => {
                            const { collection, progress } = getCollectionProgress(row.date);
                            return (
                                <TableRow hover key={i}>
                                    <TableCell sx={{ pl: 3 }}>{i + 1}</TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={useAsDataCollectionModule ? row.date : getNameOfTimePeriod(row.date)}
                                            fullWidth
                                            variant="standard"
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={row.data.total}
                                            name="value"
                                            fullWidth
                                            variant="standard"
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    {!editMode && !useAsDataCollectionModule && (
                                        <>
                                            <TableCell align="center">
                                                <TextField
                                                    value={collection}
                                                    fullWidth
                                                    variant="standard"
                                                    inputProps={{
                                                        style: { textAlign: 'center' }
                                                    }}
                                                    disabled={true}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <LinearProgressBarWithLabel name={'Progress'} value={progress} />
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell align="center">
                                        {editMode ? (
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <IconButton aria-label="delete" size="large" onClick={() => selectOne(row)}>
                                                    <ModeEditIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                                <Tooltip placement="top" title="Delete">
                                                    <IconButton
                                                        color="error"
                                                        aria-label="delete"
                                                        size="large"
                                                        onClick={() => removeOption(i)}
                                                    >
                                                        <DeleteForeverIcon sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        ) : (
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <IconButton aria-label="view" size="large" onClick={() => selectOne(row)}>
                                                    <PreviewIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {editMode && (
                <Grid container xs={12} justifyContent="center">
                    {dataList.length > 0 && (
                        <Grid item margin={1}>
                            <Button onClick={handleSubmit}>Save</Button>
                        </Grid>
                    )}
                    <Grid item margin={1}>
                        <Button color="error" component={Link} to={backLink}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
