import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// project imports
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import UploadDialog from 'views/Forms/UploadDialog';
import { useNavigate } from 'react-router-dom';

// Props type
export type ColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type ActionTableProps = {
    projectId: string;
    columns: ColumnType[];
    rows: any[];
    count: number;
    page: number;
    rowsPerPage: number;
    setPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    idField: string;
    statusField?: string;
    copyRow?: (id: number) => void;
    editRow?: (id: number) => void;
    updateStatus?: ({ row, projectForms, dispatch, snackbarMsg }: any) => Promise<void>;
    showSL?: boolean;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function ActionTable({
    projectId,
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    idField,
    statusField,
    updateStatus,
    copyRow,
    editRow,
    showSL
}: ActionTableProps) {
    const classes = useStyle();
    const navigate = useNavigate();
    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [isReplace, setIsReplace] = useState(false);
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const [chooseFormType, setChooseFormType] = useState(0);
    const [project, setProject] = useState<any>('');
    const handleChangePage = (event: React.ChangeEvent<unknown>, pageSize: number) => {
        setPage(pageSize);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    const init = async () => {
        //   try {
        //       const deployedResponse = await axiosServices.post(`${templateListURL}`);
        //       const deployedList = await _deserailize(deployedResponse.data);
        //       setDeployeds(deployedList);
        //       setCount(deployedResponse.data.meta.count);
        //       const draftsResponse = DraftsFormsData;
        //       setDrafts(draftsResponse);
        //   } catch (e) {
        //       console.log(e);
        //   }
    };

    const pageStatus = () => {
        if (count && rowsPerPage && page) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / rowsPerPage);
            if (page > 1) {
                start = (page - 1) * rowsPerPage + 1;
            }
            if (page < totalPage) {
                end = start + rowsPerPage - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table className="scrollBar" aria-label="action table">
                            <TableHead>
                                <TableRow>
                                    {showSL && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                SL
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {column.header}
                                            </Typography>
                                        </TableCell>
                                    ))}

                                    {statusField && idField && updateStatus && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {langString('changestatus')}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {idField && copyRow && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {langString('Action')}
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow key={i} className={classes.rowsHover}>
                                            {showSL && (
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: ColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}

                                            {idField && statusField && updateStatus && (
                                                <TableCell align="center">
                                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                                        {(popupState: any) => (
                                                            <>
                                                                <Button
                                                                    disabled={currentProject?.isArchived}
                                                                    size="small"
                                                                    color="success"
                                                                    sx={{
                                                                        backgroundColor: currentProject?.isArchived
                                                                            ? 'lightgray'
                                                                            : '#29CB4C',
                                                                        color: 'white'
                                                                    }}
                                                                    variant="contained"
                                                                    {...bindTrigger(popupState)}
                                                                >
                                                                    Change Status
                                                                </Button>
                                                                <Menu {...bindMenu(popupState)}>
                                                                    <MenuItem onClick={popupState.close}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={row?.isActive}
                                                                                    disabled={row?.isPublished === false}
                                                                                    onChange={(event) => {
                                                                                        updateStatus({
                                                                                            ...row,
                                                                                            isActive: event.target.checked
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Active"
                                                                        />
                                                                    </MenuItem>
                                                                    <MenuItem onClick={popupState.close}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={row?.isPublished}
                                                                                    onChange={(event: any) => {
                                                                                        if (event.target.checked === false) {
                                                                                            updateStatus({
                                                                                                ...row,
                                                                                                isPublished: event.target.checked,
                                                                                                isActive: event.target.checked
                                                                                            });
                                                                                        } else {
                                                                                            updateStatus({
                                                                                                ...row,
                                                                                                isPublished: event.target.checked
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Published"
                                                                        />
                                                                    </MenuItem>
                                                                </Menu>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </TableCell>
                                            )}
                                            {idField && (copyRow || editRow) && (
                                                <TableCell align="right" style={{ display: 'flex' }}>
                                                    {copyRow && (
                                                        <IconButton onClick={() => copyRow?.(row[idField])}>
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    )}
                                                    {copyRow && (
                                                        <IconButton
                                                            onClick={(e: any) =>
                                                                navigate(
                                                                    `/project/${projectId}/form/${row[idField]}/${row.xformId}/settings`
                                                                )
                                                            }
                                                        >
                                                            <SettingsIcon />
                                                        </IconButton>
                                                    )}
                                                    {editRow && (
                                                        <IconButton onClick={() => editRow?.(row[idField])}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    <IconButton
                                                        onClick={() => {
                                                            setOpenUploadDialog(true);
                                                            console.log('row', row);
                                                            setProject(row);
                                                        }}
                                                    >
                                                        <PublishedWithChangesIcon />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {page && count && rowsPerPage ? (
                        <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    showFirstButton
                                    showLastButton
                                />
                            </Grid>
                            <Grid item>
                                {' '}
                                <p>{pageStatus()}</p>{' '}
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                </MainCard>
            </Grid>
            <UploadDialog
                open={openUploadDialog}
                setOpen={setOpenUploadDialog}
                setChooseFormType={setChooseFormType}
                chooseFormType={chooseFormType}
                isReplace
                refresh={init}
                project={project}
            />
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
