import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import AddIcon from '@mui/icons-material/Add';
import ContributingIndicatorForm from './ContributingIndicatorForm';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { getContributingIndicatorDetail, getContributingIndicatorList } from 'features/IndicatorSettings/Service';
import { defaultContributingIndicator } from './constant';
import LinearProgressBarWithLabel from 'components/common/LinearProgressBarWithLabel';

export default function ContributingIndicator({ logframeId }: any) {
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);
    const [contributingIndicatorData, setContributingIndicatorData] = useState(defaultContributingIndicator);
    const [dataList, setDataList] = useState<any>([]);

    const dataUpdate = async () => {
        const response = await getContributingIndicatorList(logframeId);
        setDataList(response.data);
    };

    const fetchContributingIndicatorData = async (dataId: any) => {
        const response = await getContributingIndicatorDetail(dataId);
        const formattedData = {
            projectId: response?.data.project.id.toString(),
            indicatorId: response?.data.indicator.id.toString(),
            weight: response?.data.weight.toString()
        };
        console.log('response?.data ===>>> ', formattedData);

        setContributingIndicatorData(formattedData);
        setOpenAddModal(true);
    };

    useEffect(() => {
        dataUpdate();
    }, []);

    return (
        <>
            <SubCard
                title="Contributing Indicator:"
                sx={{ marginY: 2 }}
                secondary={
                    <Button variant="outlined" onClick={() => setOpenAddModal(true)} startIcon={<AddIcon />}>
                        Add
                    </Button>
                }
            >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>#</TableCell>
                                <TableCell>Project</TableCell>
                                <TableCell>Indicator</TableCell>
                                <TableCell>Weight</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList.map((data: any, index: number) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ pl: 3 }}>{index + 1}</TableCell>
                                    <TableCell>{data.project.name}</TableCell>
                                    <TableCell>{data.indicator.title}</TableCell>
                                    <TableCell>{data.weight}%</TableCell>
                                    <TableCell>
                                        <LinearProgressBarWithLabel name={'Achieved'} value={data.progress} />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="edit"
                                            size="large"
                                            onClick={() => {
                                                fetchContributingIndicatorData(data.id);
                                            }}
                                        >
                                            <ModeEditIcon sx={{ fontSize: '1.1rem' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SubCard>
            <ContributingIndicatorForm
                open={openAddModal}
                handleClose={() => {
                    dataUpdate();
                    setContributingIndicatorData(defaultContributingIndicator);
                    setOpenAddModal(false);
                }}
                logframeId={logframeId}
                contributingIndicatorData={contributingIndicatorData}
            />
        </>
    );
}
