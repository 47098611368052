import { useEffect, useState } from 'react';
import { IReportingPeriodType } from 'types';
import { useQuery } from '@tanstack/react-query';
import { rptKeys } from 'react_query/query-keys';
import { getRPTListByProjectId } from 'services/reactQueryservices';
import { DEFAULT_STALE_TIME } from 'utils/Constants';

const useGetRPTListByProjectId = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingList, setInitiateFetchingList] = useState(true);
    const [rptList, setRptList] = useState<IReportingPeriodType[]>([]);

    const result = useQuery<IReportingPeriodType[], Error>({
        queryKey: rptKeys.list(projectId),
        queryFn: () => getRPTListByProjectId(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setRptList(result.data);
        }
    }, [result.data, result.isFetched]);

    return {
        rptList,
        ...result,
        setInitiateFetchingList
    };
};

export default useGetRPTListByProjectId;
