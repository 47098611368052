import React, { Dispatch, SetStateAction } from 'react';
import { Stack, IconButton } from '@mui/material';
import { CollectionFrequency, IReportingPeriodType, UOM } from 'types';
import Tooltip from '@mui/material/Tooltip';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

export const columnListNameUOM = 'Unit of Measurement';
export const columnListNameCollectionFrequency = 'Collection Frequency';
export const columnListNameRPT = 'Reporting Period Type';
export const columnListNameReportingPeriod = 'Reporting Period';

const collectionFrequencyColumns = [
    { header: 'Collection Frequency', accessor: 'frequency' },
    { header: 'Definition Type', accessor: 'definitionType' }
];
const uomColumns = [{ header: 'Unit of Measurement', accessor: 'name' }];
const rptColumns = [{ header: 'Reporting Period Type', accessor: 'name' }];
const reportingPeriodColumns = [{ header: 'Reporting Period', accessor: 'name' }];

export const dataColumns = (
    theme: any,
    component: string,
    setSelectedData: Dispatch<SetStateAction<any>>,
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
    setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>,
    setIsEditMode: Dispatch<SetStateAction<boolean>>
) => {
    let columnData;
    switch (component) {
        case columnListNameUOM:
            columnData = uomColumns;
            break;
        case columnListNameCollectionFrequency:
            columnData = collectionFrequencyColumns;
            break;
        case columnListNameRPT:
            columnData = rptColumns;
            break;
        default:
            columnData = uomColumns;
    }

    return [
        ...columnData,
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Tooltip placement="top" title="Edit">
                        <IconButton
                            color="primary"
                            sx={{
                                color: theme.palette.warning.dark,
                                borderColor: theme.palette.warning.main,
                                '&:hover ': { background: theme.palette.warning.light }
                            }}
                            size="large"
                            onClick={() => {
                                setSelectedData(item);
                                setIsModalOpen(true);
                                setIsEditMode(true);
                            }}
                        >
                            <BorderColorTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <IconButton
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                            onClick={() => {
                                setSelectedData(item);
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            <DeleteTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    ];
};
