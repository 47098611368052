import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MaUTable from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
));

const defaultBaseline = {
    baseline_date: '',
    baseline_value: ''
};

const defaultProgressSummary = {
    indicator: { title: '' },
    target: { total: '' },
    progress: []
};

const customClone = (data: any) => {
    return JSON.parse(JSON.stringify(data));
};

const defaultCollectionBasic = {
    total: '',
    collection_period: '',
    other_input: ''
};

const filterDvalues = (disaggregation: any, dValues: any) => {
    const result = [];
    for (const d of disaggregation) {
        for (const value of dValues) {
            if (d.id == value.parent) result.push(value);
        }
    }
    return result;
};

const filterDtypes = (disaggregation: any, dTypes: any) => {
    const result = [];
    for (const d of disaggregation) {
        for (const type of dTypes) {
            if (d.id == type.id) result.push(type);
        }
    }
    return result;
};
const DefaultProgressTable = (props: any) => {
    return (
        <MaUTable>
            <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign: 'center', border: '1px solid lightgrey' }} rowSpan={2}>
                        {' '}
                        {props.message}{' '}
                    </TableCell>
                </TableRow>
            </TableHead>
        </MaUTable>
    );
};

export {
    defaultBaseline,
    defaultProgressSummary,
    defaultCollectionBasic,
    Transition,
    filterDvalues,
    filterDtypes,
    customClone,
    DefaultProgressTable
};
