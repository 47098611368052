import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from '../../../react_query/query-client-configuration';
import { uomKeys } from '../../../react_query/query-keys';
import { createOrUpdateUOM } from '../../../services/reactQueryservices';

const useUOMCreateOrUpdate = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: createOrUpdateUOM,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to save Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: uomKeys.all });
                snackbar('Successfully saved');
            }
        }
    });

    return {
        createOrUpdateUOMSynchronously: mutate,
        createOrUpdateUOMAsynchronously: mutateAsync,
        isUOMCreationOrUpdateLoading: isLoading,
        uomCreationOrUpdateError: error,
        isUOMCreationOrUpdateError: isError,
        isUOMCreationOrUpdateSuccess: isSuccess
    };
};

export default useUOMCreateOrUpdate;
