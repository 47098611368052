import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { addNodeUnderParent, changeNodeAtPath, removeNodeAtPath } from './tree-data-utils';
import MaterialTheme from 'react-sortable-tree-theme-material-ui';
import {
    AddCircleOutline,
    DeleteForever,
    Edit,
    DragIndicator,
    ExpandMore,
    NavigateNext,
    Settings,
    Texture,
    Visibility,
    Download
} from '@mui/icons-material';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import FormDialog from './Dialog';
import AlertDialog from './AlterDialog';
import FormRenderOption from './FormRenderOptionPopup';
import './custom-style.css';
import { useNavigate } from 'react-router-dom';
import TreeData, {
    confIconStyle,
    defaultConfig,
    deserialize,
    getFormattedDate,
    goToActivityProfile,
    goToDataCollection,
    goToProfile,
    goToSettings,
    goToTargets,
    IconBtnStyle,
    logHeaderCellStyle,
    logHeaderSectionStyle,
    rowCellBarStyle,
    rowCellStyle
} from './constant';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import _ from 'lodash';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { StatusCodes } from 'http-status-codes';
import {
    getProjectLogframeStructure,
    getProjectLogframe,
    createLogframe,
    createLogframeActivity,
    updateLogframeParent,
    deleteLogframe
} from './Service';
import { SDGType } from './Dialog/constant';
import SDGTagList from './SDGTagList';
import LinearProgressBarWithLabel from 'components/common/LinearProgressBarWithLabel';
import useQuery from 'hooks/useQuery';
import useSnackbar from 'hooks/useSnackbar';
import { scrollAndHighlight } from 'utils/Helpers';

export default function Logframe(props: any) {
    const [treeData, setTreeData] = useState<any>([]);
    const [metaDictionary, setMetaDictionary] = useState<any>({});
    const [newNodePopup, setNewNodePopup] = useState<boolean>(false);
    const [removeNodePopup, setRemoveNodePopup] = useState<boolean>(false);
    const [formRenderOptionPopup, setFormRenderOptionPopup] = useState<boolean>(false);
    const [formRenderOptions, setFormRenderOptions] = useState<any[]>([]);
    const [projectName, setProjectName] = useState<string>('');
    const [tempNode, setTempNode] = useState<any>(defaultConfig);
    const [treeConfig, setTreeConfig] = useState<any>([]);
    const [logframe, setLogframe] = useState<any>([]);
    const [isIndicatorNode, setIsIndicatorNode] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let nodeParentRef: any = useRef();
    const draggedNodeRef: any = useRef();

    const { lastVisited } = useQuery('lastVisited');

    const toastMsg = useSnackbar();

    useEffect(() => {
        if (lastVisited) {
            scrollAndHighlight(`item-${lastVisited}`, 2);
        }
    }, [lastVisited, treeData]);

    const init = async () => {
        const response = await getProjectLogframeStructure(props.id);
        if (response && 'data' in response.data) {
            setProjectName(response.data.data.attributes.nodeJson.data.name);
            setTreeConfig([response.data]);
        }

        const logframeResponse = await getProjectLogframe(props.id);
        if (logframeResponse) {
            const deserializeResult = [];
            for (const log of logframeResponse.data as any) {
                deserializeResult.push(deserialize(log));
            }
            setLogframe(deserializeResult);
        }

        const resultDictionary = prepareDictionary(TreeData.meta, {});
        setMetaDictionary(resultDictionary);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (!newNodePopup) {
            setIsIndicatorNode(false);
        }
    }, [newNodePopup]);

    /**
     * it will make the tree data, consumed from api
     */
    useEffect(() => {
        // console.log('metaDictionary ::::: ', Object.keys(metaDictionary), 'treeConfig ::::: ', { treeConfig });
        if (Object.keys(metaDictionary).length > 0) {
            // const result = printData(TreeData.log_frame[0], {});
            // const result = printData({}, {});
            const result = [];

            for (const log of logframe) result.push(printData(log, {}));

            setTreeData(result);
        }
    }, [metaDictionary]);

    /**
     * It is a recursive function. it will walk through every node in the tree
     * and will create another tree with new node so that sortable library could identify and print accordingly.
     * @param ob
     * @param parentOb
     * @returns
     */
    const printData = (ob: any, parentOb: any) => {
        let childNodes: any = [];
        if (ob.child !== undefined && ob.child.length > 0) {
            childNodes = ob.child.map((node: any) => {
                return printData(node, ob);
            });
        } else {
            return mapTreeData(ob, [], parentOb);
        }
        return mapTreeData(ob, childNodes, parentOb);
    };

    /**
     *  Creating a new node. which is recognizeable to the sortable-tree library.
     * @param node
     * @param children
     * @param parentOb
     * @returns
     */
    const mapTreeData = (node: any, children: any[], parentOb: any) => {
        return {
            title: node.title,
            uid: node.id,
            expanded: true,
            nodeTypeId: node.type.id,
            isAggregatedIndicator: node.isAggregatedIndicator,
            code: node.code,
            sdgList: node.logframeSDGs,
            parentNodeTypeId: 'lf_node_type_id' in parentOb ? parentOb.lf_node_type_id : -1,
            permission: {
                setting: node.setting,
                dataCollection: node.dataCollection,
                view: node.view
            },
            settingsData: {
                baseline: node?.baseline_value ? node.baseline_value : '--',
                target: node?.target ? node.target : '--',
                progress: node?.progress ? node.progress : 0
            },
            // backgroundColor: metaDictionary[node.lf_node_type_id] ? metaDictionary[node.lf_node_type_id].background_color : 'black',
            // textColor: metaDictionary[node.lf_node_type_id] ? metaDictionary[node.lf_node_type_id].text_color : 'black',
            children,
            percentage: Math.floor(Math.random() * 101),
            config: findNodeDefinition(treeConfig, node.type.id)
        };
    };
    /**
     *  this method is used for finding a node in a tree. as soon as it finds the node, it won't travel the tree.
     * @param treeConfigNodeDefinition
     * @param nodeId
     * @returns
     */
    const findNodeDefinition = (treeConfigNodeDefinition: any, nodeId: any): any => {
        for (let i = 0; i < treeConfigNodeDefinition.length; i += 1) {
            if (treeConfigNodeDefinition[i].data.id == nodeId) return treeConfigNodeDefinition[i];
            const found = findNodeDefinition(treeConfigNodeDefinition[i].data.attributes.child, nodeId);
            if (found) return found;
        }
        return null;
    };

    /**
     *  this method is used in several places. becauses it is required by the
     * sortable-tree.
     * @param param0
     * @returns
     */
    const getNodeKey = ({ treeIndex }: any) => treeIndex;

    /**
     * It will create a mapping for nodes meta-data.
     * the key is the nodeId and meta-data is the value.
     * @param meta
     * @param finalResult
     * @returns
     */
    const prepareDictionary = (meta: any, finalResult: any): any => {
        if (meta.child !== undefined && meta.child.length > 0) {
            for (const node of meta.child) {
                finalResult = prepareDictionary(node, finalResult);
            }
        }
        const { text_color, background_color, text_style } = meta;
        return {
            ...finalResult,
            [meta.lf_node_type_id]: {
                text_color,
                background_color,
                text_style
            }
        };
    };

    /**
     * It will add a new node under a specific node.
     * @param nodeProps
     * @param newNodeProps
     */
    const addNode = async (nodeProps: any, newNodeProps: any) => {
        const _nodeType = nodeProps.config.data.attributes.nodeJson.data;
        const _newNode = newNodeProps;

        const sdgList: number[] = _newNode.selectedSdgList.map((sdgData: SDGType) => sdgData.id);

        const dataDict: any = {};
        let isEditing = false;
        if ('id' in _newNode) {
            dataDict.id = _newNode.id;
            dataDict.parent = _newNode.parent;
            isEditing = true;
        } else {
            dataDict.parent = nodeProps.node.node ? nodeProps.node.node.uid : null;
        }

        const _node = {
            ...dataDict,
            lf_node_type: _nodeType.name,
            lf_node_type_id: nodeProps.config.data.id,
            lf_node: _newNode.name,
            lf_aggregatedIndicator: _newNode.aggregatedIndicator,
            lf_node_code: _newNode.code,
            sdgList
        };
        console.log({ id: props.id, _node });

        const response = await createLogframe(props.id, _node);

        if (response) {
            if (isEditing) {
                await init();
            } else {
                const result: any = addNodeUnderParent({
                    treeData: treeData,
                    parentKey: nodeProps.node.path[nodeProps.node.path.length - 1],
                    expandParent: true,
                    getNodeKey,
                    newNode: {
                        title: newNodeProps.name,
                        uid: response.data.data.id,
                        config: nodeProps.config,
                        isAggregatedIndicator: _newNode.aggregatedIndicator,
                        settingsData: {
                            baseline: '--',
                            target: '--',
                            progress: 0
                        },
                        permission: {
                            setting: true,
                            dataCollection: true,
                            view: true
                        },
                        code: _newNode.code,
                        sdgList: _newNode.selectedSdgList.map((sdgData: SDGType) => {
                            return { sdg: sdgData };
                        })
                    },
                    addAsFirstChild: false
                } as any);
                setTreeData(result.treeData);
            }
            setNewNodePopup(false);
        }
    };

    /**
     * It will add a new node under a specific node.
     * @param nodeProps
     * @param newNodeProps
     */
    const addActivityNode = async (nodeProps: any, newNodeProps: any) => {
        const _nodeType = nodeProps.config.data.attributes.nodeJson.data;
        const _newNode = newNodeProps;
        console.log('adding new node', nodeProps, _newNode);
        console.log(getFormattedDate(newNodeProps.dateRange[0]), '----', getFormattedDate(newNodeProps.dateRange[1]));

        const _node = {
            ...newNodeProps,
            logframetype: nodeProps.config.data.id,
            dateRange: [
                {
                    start_date: getFormattedDate(newNodeProps.dateRange[0]),
                    end_date: getFormattedDate(newNodeProps.dateRange[1])
                }
            ]
        };

        console.log('----> node', _node);
        const response = await createLogframeActivity(props.id, _node);

        if (response) {
            const result: any = addNodeUnderParent({
                treeData: treeData,
                parentKey: nodeProps.node.path[nodeProps.node.path.length - 1],
                expandParent: true,
                getNodeKey,
                newNode: {
                    title: newNodeProps.name,
                    uid: response.data.data.id,
                    config: nodeProps.config
                },
                addAsFirstChild: true
            } as any);
            setTreeData(result.treeData);
            setNewNodePopup(false);
        }
    };

    const openAddNodePopup = (_tempNode: any) => {
        try {
            if (
                _tempNode.node.config.data.attributes.child.length === 1 &&
                _tempNode.node.config.data.attributes.child[0].data.attributes.child.length === 0
            ) {
                setIsIndicatorNode(true);
            } else {
                setIsIndicatorNode(false);
            }
        } catch (ex) {
            console.log(ex);
            setIsIndicatorNode(false);
        }

        if (_tempNode.node.config.data.attributes.child && _tempNode.node.config.data.attributes.child.length > 1) {
            setFormRenderOptionPopup(true);
            setFormRenderOptions(_tempNode.node.config.data.attributes.child);
            setTempNode({ config: _tempNode.node.config, node: _tempNode });
        } else {
            setNewNodePopup(true);
            setTempNode({ config: _tempNode.node.config.data.attributes.child[0], node: _tempNode });
        }
    };

    const openEditNodePopup = (_tempNode: any) => {
        try {
            if (_tempNode.node.config.data.attributes.child.length === 0) {
                setIsIndicatorNode(true);
            } else {
                setIsIndicatorNode(false);
            }
        } catch (ex) {
            console.log(ex);
            setIsIndicatorNode(false);
        }
        setNewNodePopup(true);
        setTempNode({ config: _tempNode.node.config, node: _tempNode, edit: true });
    };

    const openRemoveNodePopup = (_tempNode: any) => {
        setTempNode(_tempNode);
        setRemoveNodePopup(true);
    };

    const removeNode = async (node: any) => {
        if (node?.node?.uid) {
            const response = await deleteLogframe({ nodeId: node?.node?.uid });
            if (response.status === 200) {
                setTreeData(
                    removeNodeAtPath({
                        treeData,
                        path: node.path,
                        getNodeKey
                    } as any)
                );
                toastMsg('node deleted!');
            } else {
                toastMsg(response?.text, 'error');
            }
        }

        setRemoveNodePopup(false);
    };

    /**
     * it is used to show and hide the child nodes.
     * by clicking on the expand and collapsed icon.
     * @param exp
     * @param rowInfo
     */
    const updateNode = (exp: boolean, rowInfo: any) => {
        const newNode = _.cloneDeep(rowInfo);
        newNode.node.expanded = exp;
        const result: any = changeNodeAtPath({
            treeData,
            path: rowInfo.path,
            newNode: newNode.node,
            getNodeKey
        } as any);

        setTreeData(result);
    };

    const navigationIcon = useCallback(
        (rowInfo: any) => {
            if (rowInfo.node.children !== undefined && rowInfo.node.children.length > 0) {
                return rowInfo.node.expanded ? (
                    <ExpandMore style={{ color: '#329aa8' }} onClick={() => updateNode(false, rowInfo)} />
                ) : (
                    <NavigateNext style={{ color: '#329aa8' }} onClick={() => updateNode(true, rowInfo)} />
                );
            }
            return <Texture />;
        },
        [treeData]
    );

    const isSameParent = ({ prevParent, nextParent }: any): boolean => {
        const sameParent = prevParent.config.data.attributes.nodeJson.data.name === nextParent.config.data.attributes.nodeJson.data.name;
        return sameParent;
    };

    const canDrop = ({ prevParent, nextParent }: any): boolean => {
        if (prevParent === null || nextParent === null) return false;
        console.log(
            'can drop: ',
            prevParent.config.data.attributes.nodeJson.data.name,
            nextParent.config.data.attributes.nodeJson.data.name
        );
        nodeParentRef = { prevParent, nextParent };
        return isSameParent({ prevParent, nextParent });
    };

    const changeNodeParent = async (payload: any) => {
        const response: any = await updateLogframeParent(draggedNodeRef.current.uid, payload);

        if (response.status == StatusCodes.OK) {
            showToast('Successfully Updated', 'success');
        } else {
            showToast('Server Error', 'error');
        }
    };

    const onDragStateChanged = ({ isDragging, draggedNode }: any) => {
        if (isDragging) {
            draggedNodeRef.current = { uid: draggedNode.uid };
        } else {
            if (nodeParentRef.nextParent && isSameParent(nodeParentRef))
                changeNodeParent({ newParentId: nodeParentRef.nextParent.uid, oldParentId: nodeParentRef.prevParent.uid });
            else showToast('can not move the node. it can be drop only under its parent', 'error');
        }
    };
    const openNewNodePopup = (configId: string) => {
        const config: any = formRenderOptions.find((opt: any) => opt.data.id === configId);
        try {
            if (config.data.attributes.child.length === 0) {
                setIsIndicatorNode(true);
            } else {
                setIsIndicatorNode(false);
            }
        } catch (ex) {
            console.log(ex);
            setIsIndicatorNode(false);
        }

        setTempNode({ ...tempNode, config });
        setNewNodePopup(true);
        setFormRenderOptionPopup(false);
    };
    const closeNewNodePopup = () => {
        setNewNodePopup(false);
    };

    const addRootChild = () => {
        const _treeConfig = treeConfig[0]?.data.attributes.child;
        if (treeConfig.length > 1) {
            setFormRenderOptionPopup(true);
            const options = _treeConfig;
            setFormRenderOptions(options);
            setTempNode({ ...tempNode, node: { title: '--project name', path: '' } });
        } else {
            setFormRenderOptionPopup(false);
            setNewNodePopup(true);
            setTempNode({ config: _treeConfig[0], node: { title: '--project name', path: '' } });
        }
    };

    const showIcon = useCallback((row: any) => {
        try {
            return row.node.config && row.node.config.data.attributes.child && row.node.config.data.attributes.child.length > 0;
        } catch (ex) {
            console.log(ex);
        }
    }, []);

    const isActivity = (row: any): boolean => {
        const _isActivity = row.node.config?.data.attributes.nodeJson.data.isActivity;
        return _isActivity;
    };

    const isIndicator = (row: any): boolean => {
        return row.node.config?.data.attributes.child.length === 0;
    };

    const isContributingIndicator = (row: any): boolean => {
        return isIndicator(row) && row.node.isAggregatedIndicator;
    };

    const getProfileIcon = (row: any) => {
        if (isActivity(row)) {
            return (
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => goToActivityProfile(navigate, props.id, row)}
                >
                    <Visibility style={{ fontSize: '14px' }} />
                </IconButton>
            );
        }
        return (
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => goToProfile(navigate, props.id, row)}>
                <Visibility style={{ fontSize: '14px' }} />
            </IconButton>
        );
    };

    const showToast = (message: string, alertSeverity: string) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: alertSeverity,
            transition: 'Fade'
        });
    };

    console.log('-- render time: ', new Date());

    const formRenderOptComponent = useMemo(
        () => (
            <FormRenderOption
                open={formRenderOptionPopup}
                options={formRenderOptions}
                onSubmit={openNewNodePopup}
                onClose={() => setFormRenderOptionPopup(false)}
            />
        ),
        [formRenderOptions, formRenderOptionPopup]
    );
    const formDialog = useMemo(
        () => (
            <FormDialog
                open={newNodePopup}
                isIndicatorNode={isIndicatorNode}
                nodeProps={tempNode}
                onSubmit={(newNodeProps: any) => addNode(tempNode, newNodeProps)}
                closeNewNodePopup={closeNewNodePopup}
                onActivitySubmit={(newNodeProps: any) => addActivityNode(tempNode, newNodeProps)}
            />
        ),
        [newNodePopup, tempNode]
    );

    const alertDialog = useMemo(
        () => (
            <AlertDialog
                open={removeNodePopup}
                nodeProps={tempNode}
                onYesClick={() => removeNode(tempNode)}
                onNoClick={() => setRemoveNodePopup(false)}
            />
        ),
        [removeNodePopup, tempNode]
    );

    return (
        <>
            <Button variant="outlined" endIcon={<AddCircleOutline />} onClick={addRootChild}>
                {projectName}
            </Button>
            {formDialog}
            {formRenderOptComponent}
            {alertDialog}
            <div style={logHeaderSectionStyle}>
                <div />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={logHeaderCellStyle}>Baseline</div>
                    <div style={logHeaderCellStyle}>Target</div>
                    <div style={logHeaderCellStyle}>Progress</div>
                    <div style={logHeaderCellStyle}>
                        <IconButton href="https://www.cmu.edu/blackboard/files/evaluate/tests-example.xls" sx={{ padding: 0, margin: 0 }}>
                            <Download />
                        </IconButton>
                    </div>
                </div>
            </div>
            <SortableTree
                isVirtualized={false}
                style={{ height: 'fit-content', width: '100%' }}
                treeData={treeData}
                onChange={(_treeData: any) => {
                    console.log({ _treeData });
                    setTreeData([..._treeData]);
                }}
                theme={MaterialTheme}
                innerStyle={{ margin: '10px' }}
                scaffoldBlockPxWidth={50}
                canDrop={canDrop}
                onDragStateChanged={onDragStateChanged}
                generateNodeProps={(rowInfo) => ({
                    buttons: (
                        <>
                            <div style={rowCellStyle}>
                                <Typography component="h4">
                                    {isIndicator(rowInfo) && rowInfo.node.permission.view ? rowInfo?.node?.settingsData?.baseline : null}
                                </Typography>
                            </div>
                            <div style={rowCellStyle}>
                                <Typography component="h4">
                                    {isIndicator(rowInfo) && rowInfo.node.permission.view ? rowInfo?.node?.settingsData?.target : ''}
                                </Typography>
                            </div>
                            <div style={{ ...rowCellStyle, ...rowCellBarStyle }}>
                                <Typography component="h4">
                                    {isIndicator(rowInfo) && rowInfo.node.permission.view ? (
                                        <LinearProgressBarWithLabel value={rowInfo?.node?.settingsData?.progress} />
                                    ) : null}
                                </Typography>
                            </div>
                            <div style={{ minWidth: '18vh', textAlign: 'right' }}>
                                {showIcon(rowInfo) ? (
                                    <Tooltip title="Add">
                                        <IconButton sx={{ padding: 0, margin: 0 }} onClick={() => openAddNodePopup(rowInfo)}>
                                            <AddCircleOutline style={confIconStyle} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {/* {!isActivity(rowInfo) && !showIcon(rowInfo) ? (
                                    <Tooltip title="Data Upload">
                                        <IconButton sx={IconBtnStyle} onClick={() => goToDataUploadConfig(navigate, props.id, rowInfo)}>
                                            <Construction style={confIconStyle} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null} */}
                                {!isActivity(rowInfo) && !showIcon(rowInfo) && rowInfo.node.permission.setting ? (
                                    <Tooltip title="Targets">
                                        <IconButton sx={IconBtnStyle} onClick={() => goToTargets(navigate, props.id, rowInfo)}>
                                            <TrackChangesIcon style={confIconStyle} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {!isActivity(rowInfo) && !showIcon(rowInfo) && rowInfo.node.permission.setting ? (
                                    <Tooltip title="Settings">
                                        <IconButton sx={IconBtnStyle} onClick={() => goToSettings(navigate, props.id, rowInfo)}>
                                            <Settings style={confIconStyle} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {isActivity(rowInfo) ? (
                                    <Tooltip title="Activity Profile">
                                        <IconButton sx={IconBtnStyle} onClick={() => goToActivityProfile(navigate, props.id, rowInfo)}>
                                            <PreviewTwoToneIcon style={confIconStyle} />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                <Tooltip title="Edit">
                                    <IconButton sx={IconBtnStyle} onClick={() => openEditNodePopup(rowInfo)}>
                                        <Edit style={confIconStyle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton sx={IconBtnStyle} onClick={() => openRemoveNodePopup(rowInfo)}>
                                        <DeleteForever style={confIconStyle} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    ),
                    icons: (
                        <>
                            {navigationIcon(rowInfo)}
                            <DragIndicator
                                style={{ color: rowInfo?.node?.config?.data?.attributes?.color, marginLeft: '10px' }}
                                id={`item-${rowInfo?.node?.uid}`}
                            />
                        </>
                    ),
                    title: (
                        <>
                            <div>
                                <p>
                                    <b>
                                        <i>{rowInfo.node.config && rowInfo.node.config.data.attributes.nodeJson.data.name}</i>
                                    </b>{' '}
                                    : {rowInfo.node?.code} {rowInfo.node.title}
                                    {rowInfo.node.sdgList && <SDGTagList sdgList={rowInfo.node.sdgList} />}
                                    {!showIcon(rowInfo) && !isActivity(rowInfo) && rowInfo.node.permission.view ? (
                                        <>
                                            <Tooltip title="Profile">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    onClick={() => goToProfile(navigate, props.id, rowInfo)}
                                                >
                                                    <Visibility style={{ fontSize: '14px' }} />
                                                </IconButton>
                                            </Tooltip>
                                            {!isContributingIndicator(rowInfo) && rowInfo.node.permission.dataCollection && (
                                                <Tooltip title="Data Collection">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={() => goToDataCollection(navigate, props.id, rowInfo)}
                                                    >
                                                        <NoteAltIcon style={{ fontSize: '14px' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : null}
                                </p>
                            </div>
                        </>
                    )
                })}
            />
        </>
    );
}
