import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import './app.css';
// auth provider
// import { FirebaseProvider } from 'contexts/FirebaseContext';
import { JWTProvider } from 'contexts/JWTContext';
import { SidebarProvider } from 'contexts/SidebarContext';
import NavigationScroll from 'layout/NavigationScroll';
import { useSelector } from 'react-redux';
// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';
// store
import { DefaultRootStateProps } from 'types';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
// project imports
import Locales from 'ui-component/Locales';
import { client } from './react_query/query-client-configuration';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    return (
        <DndProvider backend={HTML5Backend}>
            <QueryClientProvider client={client}>
                <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={themes(customization)}>
                            <CssBaseline />
                            <Locales>
                                <NavigationScroll>
                                    <JWTProvider>
                                        <SidebarProvider>
                                            <>
                                                <Routes />
                                                <Snackbar />
                                            </>
                                        </SidebarProvider>
                                    </JWTProvider>
                                </NavigationScroll>
                            </Locales>
                        </ThemeProvider>
                    </LocalizationProvider>
                </StyledEngineProvider>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </DndProvider>
    );
};

export default App;
