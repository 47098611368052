import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Box, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import SubCard from "ui-component/cards/SubCard";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const IndicatorActivity = (props: any) => {

    const deleteData = (index: number) => {
        const list = [...props.data.data];
        list.splice(index, 1);
        props.setData({ ...props.data, data: list });
    }

    const addData = () => {
        const list = [...props.data.data];
        list.push({ name: '', start_date: '', end_date: '', weighting: '', type: '' });
        props.setData({ ...props.data, data: list });
    }

    const editData = (event: any, index: number) => {
        const list = [...props.data.data];
        const item = list[index];
        list.splice(index, 1, { ...item, [event.target.name]: event.target.value });
        props.setData({ ...props.data, data: list });
    }

    return (
        <SubCard>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Type</TableCell>
                            <TableCell align='center'>Start Date</TableCell>
                            <TableCell align='center'>End Date</TableCell>
                            <TableCell align='center'>Weighting</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data.data.map((row: any, i: number) => (
                                <TableRow hover key={i}>
                                    <TableCell align='center'>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            variant="standard"
                                            value={row.target}
                                            onChange={(e: any) => editData(e, i)}
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Select
                                            value={row.type}
                                            name="type"
                                            fullWidth
                                            variant="standard"
                                            onChange={(e: any) => editData(e, i)}
                                        >
                                            <MenuItem value={1}>Indicator</MenuItem>
                                            <MenuItem value={2}>Milestone</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            name="start_date"
                                            type="date"
                                            fullWidth
                                            variant="standard"
                                            onChange={(e: any) => editData(e, i)}
                                            value={row.date}
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            name="end_date"
                                            type="date"
                                            fullWidth
                                            variant="standard"
                                            onChange={(e: any) => editData(e, i)}
                                            value={row.date}
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            name="weighting"
                                            fullWidth
                                            variant="standard"
                                            onChange={(e: any) => editData(e, i)}
                                            value={row.date}
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Delete">
                                                <IconButton color="error" aria-label="delete" size="large" onClick={() => deleteData(i)}>
                                                    <DeleteForeverIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <IconButton color="success" aria-label="delete" size="large" onClick={addData}>
                    <AddIcon sx={{ fontSize: '1.3rem' }} />
                </IconButton>
            </div>
        </SubCard>
    );
}

export default IndicatorActivity;