import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IReportingPeriodType } from 'types';
import Dialog from '@mui/material/Dialog';
import ReportingPeriodTypeForm from './Form';
import { FormikValues } from 'formik';
import useRPTCreateOrUpdate from 'hooks/consume_api/mutation/useRPTCreateOrUpdate';
import { Divider } from '@mui/material';
import ReportingPeriod from '../ReportingPeriod';

interface ReportingPeriodTypeModalType {
    rpType?: IReportingPeriodType | null;
    dataList: IReportingPeriodType[];
    setIsEditMode: Dispatch<SetStateAction<boolean>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedData: Dispatch<SetStateAction<IReportingPeriodType | null>>;
    handleModalClose: () => void;
    isModalOpen: boolean;
    isEditMode: boolean;
    projectId: string;
}

const ReportingPeriodTypeModal = ({
    rpType = null,
    dataList,
    setIsEditMode,
    setIsModalOpen,
    setSelectedData,
    handleModalClose,
    isModalOpen,
    isEditMode,
    projectId
}: ReportingPeriodTypeModalType) => {
    const { createOrUpdateRPTAsynchronously } = useRPTCreateOrUpdate();
    const [createdData, setCreatedData] = useState<any>(null);
    const [isCreated, setIsCreated] = useState<boolean>(false);

    useEffect(() => {
        if (isCreated && createdData) {
            const getData = dataList.find((obj) => obj.name === createdData.name);
            if (getData) {
                setSelectedData(getData);
                setIsEditMode(true);
                setIsCreated(false);
            }
        }
    }, [dataList, isCreated, createdData]);

    const handleSave = async (data: FormikValues) => {
        await createOrUpdateRPTAsynchronously(data);

        if (!isEditMode) {
            setCreatedData(data);
            setIsCreated(true);
        }
    };

    return (
        <Dialog
            onBackdropClick={() => setIsModalOpen(true)}
            maxWidth={isEditMode ? 'lg' : 'sm'}
            fullWidth
            onClose={handleModalClose}
            open={isModalOpen}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
            <ReportingPeriodTypeForm
                rpType={rpType}
                handleSave={handleSave}
                onCancel={handleModalClose}
                projectId={projectId}
                isEditMode={isEditMode}
            />
            {isEditMode && (
                <>
                    <Divider />
                    <ReportingPeriod reportingPeriodTypeId={rpType?.id!} />
                </>
            )}
        </Dialog>
    );
};

export default ReportingPeriodTypeModal;
