import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Divider, FormHelperText, Grid, IconButton, TextField, Typography } from '@mui/material';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const Disaggregations = ({ disaggregations }: any) => {
    console.log('in disaggregations: ', disaggregations);
    return (
        <Grid item xs={12}>
            <MainCard title="Disaggregations">
                {disaggregations.map((disaggregationGroup: any, index: any) => (
                    <React.Fragment key={index}>
                        <SubCard>
                            <Grid container spacing={3} alignItems="center">
                                {disaggregationGroup.map((d: any, groupIndex: number) => (
                                    <Grid item xs={12} key={groupIndex}>
                                        <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                                            {d.name}:
                                        </Typography>
                                        <Grid item xs={4}>
                                            <TextField fullWidth placeholder="Enter full name" />
                                        </Grid>
                                        <br />
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <br />
                                        {d.DisaggregationItems.map((dItem: any, itemIndex: number) => (
                                            <Grid container spacing={2} alignItems="center" key={`${itemIndex}-${dItem.id}`}>
                                                <Grid item xs={12} sm={3} lg={2} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                                                    <InputLabel
                                                        horizontal
                                                        sx={{ textAlign: { xs: 'left', sm: 'right' }, whiteSpace: 'normal' }}
                                                    >
                                                        {dItem.item} :
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item xs={12} sm={9} lg={4}>
                                                    <TextField fullWidth />
                                                    <br />
                                                    <br />
                                                </Grid>
                                                <Grid item xs={12} sm={9} lg={6} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </SubCard>
                        <br />
                    </React.Fragment>
                ))}
            </MainCard>
        </Grid>
    );
};

export default Disaggregations;
