import { useEffect, useState } from 'react';
import { IReportingPeriodType } from 'types';
import { useQuery } from '@tanstack/react-query';
import { rpKeys } from 'react_query/query-keys';
import { getReportingPeriodListByProjectId } from 'services/reactQueryservices';
import { DEFAULT_STALE_TIME } from 'utils/Constants';

const useGetReportingPeriodListByProjectId = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingList, setInitiateFetchingList] = useState(true);
    const [reportingPeriodList, setReportingPeriodList] = useState<IReportingPeriodType[]>([]);

    const result = useQuery<IReportingPeriodType[], Error>({
        queryKey: rpKeys.list(projectId),
        queryFn: () => getReportingPeriodListByProjectId(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setReportingPeriodList(result.data);
        }
    }, [result.data, result.isFetched]);

    return {
        reportingPeriodList,
        ...result,
        setInitiateFetchingList
    };
};

export default useGetReportingPeriodListByProjectId;
