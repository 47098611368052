// personal details table
/** names Don&apos;t look right */
function createData(name: string, calories?: string, fat?: string, carbs?: string, protein?: string) {
    return { name, calories, fat, carbs, protein };
}

export const indicatorInfoRowData = [
    createData('type', ':', 'Activity Indicator'),
    createData('Name', ':', 'Develop family planning curriculum for nurses'),
    createData(
        'Output',
        ':',
        'Nurses have better understanding of family planning practices and thery share their knowledge with community members '
    ),
    createData('OutCome', ':', 'Community member use effective means of contraceptives '),
    createData('Goal', ':', 'improve health')
];

export const baseLineRowData = [
    {
        id: 1,
        description: 'teach nurses',
        value: 23,
        variation: 'mPower Social',
        target: '01911111111',
        baseline: 'rana@gmail.com'
    },
    {
        id: 2,
        description: 'arrange meetings',
        value: 12,
        variation: 'mPower Social',
        target: '01911111111',
        baseline: 'rana@gmail.com'
    }
];
export const progressRowData = [
    {
        id: 1,
        date: '1-Jan-2020',
        data: 'mPower Social',
        percentage: '10%'
    },
    {
        id: 2,
        date: '12-Jan-2020',
        data: 'mPower Social',
        percentage: '80%'
    }
];

export interface IndicatorInfoType {
    settings: any;
}

export const defaultIndicatorInfoData = {
    settings: {},
    uomList: []
};

export const indicatorSettingsDataConverter = (data: any, uomList: any, frequencyList: any) => {
    const formattedData = [];
    const defaultValue = 'N/A';
    const dataDictionary = {
        uom: {
            id: 1,
            name: 'Unit of Measurement'
        },
        frequency: {
            id: 2,
            name: 'Frequency'
        },
        dataSource: {
            id: 3,
            name: 'Data Source'
        },
        baselineTime: {
            id: 4,
            name: 'Baseline Time'
        },
        baselineValue: {
            id: 5,
            name: 'Baseline Value'
        },
        dataCollectionMethod: {
            id: 6,
            name: 'Data Collection Method'
        },
        indicatorType: {
            id: 7,
            name: 'Indicator Type'
        },
        dataEntryType: {
            id: 8,
            name: 'Data Entry Type'
        },
        progressVsCumulative: {
            id: 9,
            name: 'Progress vs Cumulative Type'
        }
    };

    if (data && 'uom' in data) {
        formattedData.push({ id: dataDictionary.uom.id, name: dataDictionary.uom.name, value: getDataById(uomList, data.uom) });
    } else {
        formattedData.push({ id: dataDictionary.uom.id, name: dataDictionary.uom.name, value: defaultValue });
    }

    if (data && 'frequency' in data) {
        formattedData.push({
            id: dataDictionary.frequency.id,
            name: dataDictionary.frequency.name,
            value: getDataById(frequencyList, data.frequency)
        });
    } else {
        formattedData.push({ id: dataDictionary.frequency.id, name: dataDictionary.frequency.name, value: defaultValue });
    }

    if (data && 'data_source' in data) {
        formattedData.push({ id: dataDictionary.dataSource.id, name: dataDictionary.dataSource.name, value: data.data_source });
    } else {
        formattedData.push({ id: dataDictionary.dataSource.id, name: dataDictionary.dataSource.name, value: defaultValue });
    }

    if (data && 'baseline_time' in data) {
        formattedData.push({
            id: dataDictionary.baselineTime.id,
            name: dataDictionary.baselineTime.name,
            value: getDateExtractedValue(data.baseline_time)
        });
    } else {
        formattedData.push({ id: dataDictionary.baselineTime.id, name: dataDictionary.baselineTime.name, value: defaultValue });
    }

    if (data && 'baseline_value' in data) {
        formattedData.push({ id: dataDictionary.baselineValue.id, name: dataDictionary.baselineValue.name, value: data.baseline_value });
    } else {
        formattedData.push({ id: dataDictionary.baselineValue.id, name: dataDictionary.baselineValue.name, value: defaultValue });
    }

    if (data && 'data_collection_method' in data) {
        formattedData.push({
            id: dataDictionary.dataCollectionMethod.id,
            name: dataDictionary.dataCollectionMethod.name,
            value: data.data_collection_method
        });
    } else {
        formattedData.push({
            id: dataDictionary.dataCollectionMethod.id,
            name: dataDictionary.dataCollectionMethod.name,
            value: defaultValue
        });
    }

    if (data && 'indicator_type' in data) {
        formattedData.push({
            id: dataDictionary.indicatorType.id,
            name: dataDictionary.indicatorType.name,
            value: data.indicator_type.label
        });
    } else {
        formattedData.push({ id: dataDictionary.indicatorType.id, name: dataDictionary.indicatorType.name, value: defaultValue });
    }

    if (data && 'data_entry_type' in data) {
        formattedData.push({
            id: dataDictionary.dataEntryType.id,
            name: dataDictionary.dataEntryType.name,
            value: data.data_entry_type.label
        });
    } else {
        formattedData.push({ id: dataDictionary.dataEntryType.id, name: dataDictionary.dataEntryType.name, value: defaultValue });
    }

    if (data && 'progress_vs_cumulative' in data) {
        formattedData.push({
            id: dataDictionary.progressVsCumulative.id,
            name: dataDictionary.progressVsCumulative.name,
            value: data.progress_vs_cumulative.label
        });
    } else {
        formattedData.push({
            id: dataDictionary.progressVsCumulative.id,
            name: dataDictionary.progressVsCumulative.name,
            value: defaultValue
        });
    }

    return formattedData;
};

const getDataById = (dataList: any, id: any) => {
    for (const data of dataList) {
        if (id == data.id) {
            if ('name' in data.attributes) {
                return data.attributes.name;
            }
            if ('frequency' in data.attributes) {
                return data.attributes.frequency;
            }
        }
    }
    return id;
};

const getDateExtractedValue = (value: any) => {
    return value.split('T')[0];
};

export interface ContributingIndicator {
    projectId: string;
    indicatorId: string;
    weight: string;
}

export const defaultContributingIndicator: ContributingIndicator = {
    projectId: '',
    indicatorId: '',
    weight: ''
};
