import {
    Button,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useEffect, useState } from 'react';

const BasicFormDialog = (props: any) => {
    const [title, setTitle] = useState('Set Target');
    console.log('dataBasic: ', props.dataBasic);

    const createData = () => {
        if (props.dataBasic.data.total) props.handleSave();
        else props.showToast('Please add targets and total value in the target table', 'error');
    };
    useEffect(() => {
        let cardTitle = '';
        if (props.editMode && props.useAsDataCollectionModule) {
            cardTitle = 'Collect Data';
        } else if (!props.editMode && props.useAsDataCollectionModule) {
            cardTitle = 'View Data Collection';
        } else if (props.editMode && !props.useAsDataCollectionModule) {
            cardTitle = 'Set Target';
        } else {
            cardTitle = 'View Target';
        }
        setTitle(cardTitle);
    }, [props.editMode, props.useAsDataCollectionModule]);

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    {props.useAsDataCollectionModule ? (
                        <>
                            <Grid item xs={2}>
                                <Typography variant="h4">Date: </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    type="date"
                                    fullWidth
                                    name="date"
                                    value={props.dataBasic.date}
                                    onChange={props.handleBasic}
                                    disabled={!props.editMode}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={2}>
                                <Typography variant="h4">Date: </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <InputLabel htmlFor="outlined-adornment-timeframe">Select Timeperiod</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="select-timeframe"
                                    id="timeframe"
                                    name="date"
                                    value={props.dataBasic.date}
                                    label="Time Period"
                                    onChange={props.handleBasic}
                                    disabled={!props.editMode}
                                >
                                    {props.timePeriodData &&
                                        props.timePeriodData.map((item: any, index: number) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={2}>
                        <Typography variant="h4">{props.useAsDataCollectionModule ? 'Collection' : 'Target'} Value: </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField fullWidth name="value" value={props.dataBasic.data.total} onClick={props.openDisaggregationTable} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.editMode && <Button onClick={createData}>Create</Button>}
                <Button onClick={props.handleClose} style={{ color: 'red' }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BasicFormDialog;
