import React, {useEffect, useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Modal,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useFormik} from 'formik';
import useSnackbar from 'hooks/useSnackbar';
import {useSelector} from 'react-redux';
import {gridSpacing} from 'store/constant';
import langString from 'utils/langString';
import {checkedIcon, icon, projectAddStyle, ProjectEditPropsType, projectInitialValues} from '../Constants';
import {getProjectById, updateProject, validationSchema} from '../Service';
import {fetchOrgList} from 'views/TeamManagement/OrgManagement/Service';

export default function ProjectEdit({projectId, refresh, open, setOpen}: ProjectEditPropsType) {
    const theme = useTheme();
    const [partners, setPartners] = useState([]);
    const [project, setProject] = useState<any>(projectInitialValues);
    const snackbarMsg = useSnackbar();
    const account: any = useSelector((state: any) => state.account);

    const saveProject = async (values: any) => {
        await updateProject(values, snackbarMsg);
        refresh('');
        setOpen(false);
    };

    const init = async () => {
        const existingOrg: any = [];
        const organization: any = await fetchOrgList(account.user.organization.id, 1, 100, snackbarMsg);
        const projectOb: any = await getProjectById(projectId, snackbarMsg);

        projectOb.partnerOrganization.forEach((org: any) => {
            const result = organization.data.find((o: any) => o.id == org.id);
            if (result) existingOrg.push(result);
        });

        setPartners(organization.data);
        setProject({
            ...projectOb,
            startDate: new Date(Date.parse(projectOb.startDate)),
            endDate: new Date(Date.parse(projectOb.endDate)),
            partner: existingOrg
        });
    };

    useEffect(() => {
        if (account.user) init();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: project,
        validationSchema,
        onSubmit: saveProject
    });

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={projectAddStyle}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{borderBottom: '1px solid #000', marginBottom: 3}}
                    >
                        <Grid item xs={6}>
                            <Typography variant="h4">
                                <EditIcon/> {langString('edit')} {langString('project')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="secondary"
                                onClick={() => setOpen(false)}
                                aria-label="closeModal"
                                component="span"
                                sx={{color: 'black', float: 'right'}}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between"
                                  sx={{padding: 2}}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{...theme.typography.customInput}}>
                                        <InputLabel
                                            htmlFor="outlined-adornment-name">{`${langString('name')} *`}</InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            inputProps={{}}
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            sx={{...theme.typography.customInput}}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                        />
                                        {formik.errors.name && formik.touched.name && (
                                            <FormHelperText error>{formik.errors.name}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth sx={{...theme.typography.customInput}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Start Date *"
                                                    inputFormat="dd-MM-yyyy"
                                                    value={formik.values.startDate}
                                                    onChange={(value: any) => formik.setFieldValue('startDate', value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        {formik.errors.startDate && formik.touched.startDate && (
                                            <FormHelperText error>{formik.errors.startDate}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth sx={{...theme.typography.customInput}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="End Date *"
                                                    inputFormat="dd-MM-yyyy"
                                                    value={formik.values.endDate}
                                                    onChange={(value: any) => formik.setFieldValue('endDate', value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        {formik.errors.startDate && formik.touched.startDate && (
                                            <FormHelperText error>{formik.errors.startDate}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between"
                                  sx={{padding: 2}}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h5" sx={{marginBottom: 1}}>
                                        {`${langString('description')} *`}
                                    </Typography>
                                    <TextField
                                        multiline
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        aria-label="description"
                                        minRows={3}
                                        name="description"
                                        placeholder={langString('description')}
                                        style={{width: '100%'}}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.description && formik.touched.description && (
                                        <FormHelperText error>{formik.errors.description}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                spacing={gridSpacing}
                                justifyContent="space-between"
                                sx={{marginBottom: 3, padding: 2}}
                            >
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="h5" sx={{marginBottom: 1}}>
                                        {`${langString('partner')} *`}
                                    </Typography>
                                    <FormControl fullWidth sx={{...theme.typography.customInput}}>
                                        <Autocomplete
                                            multiple
                                            options={partners}
                                            onChange={(e: any, value: any) => formik.setFieldValue('partner', value)}
                                            value={formik.values.partner}
                                            disableCloseOnSelect
                                            getOptionLabel={(option: any) => option.name}
                                            onBlur={formik.handleBlur}
                                            renderOption={(props: any, option: any, {selected}: any) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params: any) => (
                                                <TextField {...params}
                                                           error={formik.touched.partner && Boolean(formik.errors.partner)}/>
                                            )}
                                        />
                                        {formik.errors.partner && formik.touched.partner && (
                                            <FormHelperText error>{formik.errors.partner}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>

                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Button
                                    sx={{
                                        color: theme.palette.error.dark,
                                        borderColor: theme.palette.error.dark
                                    }}
                                    onClick={() => setOpen(false)}
                                    color="secondary"
                                >
                                    {langString('cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="contained" size="small" type="submit" autoFocus>
                                    {langString('save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
