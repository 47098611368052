import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from '../../../react_query/query-client-configuration';
import { collectionFrequencyKeys } from '../../../react_query/query-keys';
import { createOrUpdateCollectionFrequency } from '../../../services/reactQueryservices';

const useCollectionFrequencyCreateOrUpdate = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: createOrUpdateCollectionFrequency,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to save Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: collectionFrequencyKeys.all });
                snackbar('Successfully saved');
            }
        }
    });

    return {
        createOrUpdateCollectionFrequencySynchronously: mutate,
        createOrUpdateCollectionFrequencyAsynchronously: mutateAsync,
        isCollectionFrequencyCreationOrUpdateLoading: isLoading,
        collectionFrequencyCreationOrUpdateError: error,
        isCollectionFrequencyCreationOrUpdateError: isError,
        isCollectionFrequencyCreationOrUpdateSuccess: isSuccess
    };
};

export default useCollectionFrequencyCreateOrUpdate;
