import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from 'react_query/query-client-configuration';
import { rptKeys } from 'react_query/query-keys';
import { createOrUpdateRPT } from 'services/reactQueryservices';

const useRPTCreateOrUpdate = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: createOrUpdateRPT,
        onSettled: (data, error: any) => {
            if (error) {
                snackbar(error?.response?.data?.message || 'Failed to save Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: rptKeys.all });
                snackbar('Successfully saved');
            }
        }
    });

    return {
        createOrUpdateRPTSynchronously: mutate,
        createOrUpdateRPTAsynchronously: mutateAsync,
        isRPTCreationOrUpdateLoading: isLoading,
        rptCreationOrUpdateError: error,
        isRPTCreationOrUpdateError: isError,
        isRPTCreationOrUpdateSuccess: isSuccess
    };
};

export default useRPTCreateOrUpdate;
