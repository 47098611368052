import { useEffect, useState } from 'react';
import { Activity } from '../../../types';
import { useQuery } from '@tanstack/react-query';
import { activityKeys } from '../../../react_query/query-keys';
import { getActivityListByProjectId } from '../../../services/reactQueryservices';
import { DEFAULT_STALE_TIME } from '../../../utils/Constants';

const UseGetActivityListByProjectId = (projectId: string = '0', dependencies: boolean = true) => {
    const [initiateFetchingActivityList, setInitiateFetchingActivityList] = useState(true);

    const [activityList, setActivityList] = useState<Activity[]>([]);

    const result = useQuery<Activity[], Error>({
        queryKey: activityKeys.list(projectId),
        queryFn: () => getActivityListByProjectId(projectId),
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingActivityList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setActivityList(result.data);
        }
    }, [result.data, result.isFetched]);

    return {
        activityList,
        ...result,
        setInitiateFetchingActivityList
    };
};

export default UseGetActivityListByProjectId;
