import React from 'react';
import { IReportingPeriodTypePayload } from 'types';
import * as Yup from 'yup';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Button, DialogActions, Divider, Grid, Stack, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const getInitialValues = (obj: FormikValues | null, projectId: string) => {
    if (obj) {
        return {
            id: obj.id,
            name: obj.name,
            projectId
        };
    }

    const newObj = {
        id: '',
        name: '',
        projectId
    };

    return newObj;
};

export interface ReportingPeriodTypeFormProps {
    rpType: FormikValues | null;
    projectId: string;
    handleSave: (data: FormikValues) => void;
    onCancel: () => void;
    isEditMode: boolean;
}

const ReportingPeriodTypeForm = ({ rpType, handleSave, onCancel, projectId, isEditMode }: ReportingPeriodTypeFormProps) => {
    const isCreating = !isEditMode;

    const ReportingPeriodTypeSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
    });

    const formik = useFormik<IReportingPeriodTypePayload>({
        initialValues: getInitialValues(isEditMode ? rpType : null, projectId),
        validationSchema: ReportingPeriodTypeSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const toSubmitData = {
                    id: values.id,
                    projectId: values.projectId,
                    name: values.name
                };
                await handleSave(toSubmitData);
                setSubmitting(false);
                if (!isCreating) {
                    onCancel();
                }
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const isFormComplete = () => {
        let isValid = values.name !== '';
        if (isCreating) return isValid;
        return isValid && values.name !== rpType?.name;
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle>{isCreating ? 'Add Reporting Period Type' : 'Edit Reporting Period Type'}</DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Reporting Period Type"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button type="button" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" disabled={isSubmitting || !isFormComplete()}>
                                    {isCreating ? 'Add' : 'Edit'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Form>
        </FormikProvider>
    );
};

export default ReportingPeriodTypeForm;
