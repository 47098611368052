import { InputLabel, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import AddIcon from '@mui/icons-material/Add';
import TargetTable from './targetTable';
import BasicFormDialog from './BasicFormDialog';
import DisaggregationTargetTable from '../DataCollection/DialogTable';
import { getDtypes, getDvalues } from 'features/IndicatorSettings/constants';
import Jsona from 'jsona';
import _ from 'lodash';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import {
    // getCollectionFrequencyListOfProject,
    getDisaggregationListOfProject,
    getIndicatorSettingOfLogframe,
    getIndicatorTargetOfLogframe,
    getIndicatorDataOfLogframe,
    updateIndicatorTarget,
    updateIndicatorData,
    getTimePeriodListOfProject,
    getTargetVsCollectionDataOfIndicator
} from 'features/IndicatorSettings/Service';
import { getTodayDate } from 'utils/date';
import Wrapper from 'components/Card/Wrapper';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import QueueIcon from '@mui/icons-material/Queue';

const dataFormatter = new Jsona();

const defaultDataBasic = {
    date: getTodayDate(),
    data: {
        data: {}
    }
};

export default function Targets({ editMode = true, useAsDataCollectionModule = false }) {
    const dispatch = useDispatch();
    const { projectId, logframeId } = useParams();
    const [openBasicFormDialog, setOpenBasicFormDialog] = useState<boolean>(false);
    // const [frequencyList, setFrequencyList] = useState<any>([]);
    // const [frequency, setFrequency] = useState<any>({});
    const [dataList, setDataList] = React.useState<any>([]);
    const [disaggregationGroups, setDisaggregationGroups] = useState<any>([]);
    const [dTypes, setDtypes] = useState<any>([]);
    const [dValues, setDvalues] = useState<any>([]);
    const [openDisaggregationDialog, setOpenDisaggregationDialog] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(useAsDataCollectionModule ? defaultDataBasic : { ...defaultDataBasic, date: '' });
    const [timePeriodData, setTimePeriodData] = useState<any[]>([]);
    const [targetVsCollectionData, setTargetVsCollectionData] = useState<any[]>([]);
    const [lop, setLOP] = useState<any>('');

    useEffect(() => {
        const init = async () => {
            const indicatorSettings = await getIndicatorSettingOfLogframe(logframeId);
            const dTypeRespose = await getDisaggregationListOfProject(projectId);
            const disaggregations: any = dataFormatter.deserialize(dTypeRespose.data);
            console.log('useAsDataCollectionModule ::: ', useAsDataCollectionModule);
            setDtypes(getDtypes(disaggregations));
            setDvalues(getDvalues(disaggregations));
            try {
                setDisaggregationGroups(indicatorSettings.data.setting.disaggregation);
            } catch (ex) {
                console.log(' Exception: ', ex);
            }
            try {
                if (!useAsDataCollectionModule) {
                    const timePeriedResponse = await getTimePeriodListOfProject(projectId);
                    setTimePeriodData(timePeriedResponse);
                    if (timePeriedResponse.length > 0) {
                        setSelectedData((prev: any) => {
                            return { ...prev, date: timePeriedResponse[0]?.id };
                        });
                    }
                    if (!editMode) {
                        const targetVsCollectionResponse = await getTargetVsCollectionDataOfIndicator(logframeId);
                        setTargetVsCollectionData(targetVsCollectionResponse);
                    }
                    const targetResponse = await getIndicatorTargetOfLogframe(logframeId);
                    setLOP(targetResponse.data.jsonData.lopValue);
                    setDataList(targetResponse.data.jsonData.targets);
                } else {
                    const dataCollectionResponse = await getIndicatorDataOfLogframe(logframeId);
                    setDataList(dataCollectionResponse.data.jsonData.collectedData);
                }
                // const frequencyResponse = await getCollectionFrequencyListOfProject(projectId);
                // if ((frequencyResponse.status = StatusCodes.OK)) {
                //     const _frequencies = dataFormatter.deserialize(frequencyResponse.data);
                //     setFrequencyList(_frequencies);
                //     setFrequency(_frequencies.find((f: any) => f.id == indicatorSettings.data.setting.frequency));
                // }
            } catch (ex) {
                console.log(' Exception: ', ex);
            }
        };
        init();
    }, []);

    const handleBasic = (e: any) => {
        setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        console.log('dataList', dataList);
        let response;
        if (!useAsDataCollectionModule) {
            response = await updateIndicatorTarget({ logframeId, lopValue: lop, targets: dataList });
        } else {
            response = await updateIndicatorData({ logframeId, collectedData: dataList });
        }

        if (response.status === StatusCodes.OK) {
            console.log(response);
            showToast('Saved Successfully !', 'success');
        }
    };

    const showToast = (message: string, alertSeverity: string) => {
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: message,
            variant: 'alert',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            alertSeverity: alertSeverity,
            transition: 'Fade'
        });
    };

    const saveData = () => {
        console.log('selectedData ::: ', selectedData);
        console.log('dataList ::: ', dataList);
        const tIndex = dataList.findIndex((t: any) => selectedData.date == t.date);
        if (tIndex > -1) {
            const newDataList = _.cloneDeep(dataList);
            newDataList.splice(tIndex, 1, selectedData);
            setDataList(newDataList);
        } else {
            setDataList([...dataList, { ...selectedData }]);
        }

        setOpenBasicFormDialog(false);
        setSelectedData(defaultDataBasic);
    };

    const handleDataSubmission = async (dialogTable: any) => {
        console.log(dialogTable);
        setSelectedData({ ...selectedData, data: dialogTable });
        setOpenDisaggregationDialog(false);
        setOpenBasicFormDialog(true);
    };

    const selectOneData = (data: any) => {
        setSelectedData(data);
        setOpenBasicFormDialog(true);
    };

    return (
        <Wrapper
            title={useAsDataCollectionModule ? 'Data Collection' : 'Indicator Target'}
            icon={useAsDataCollectionModule ? <QueueIcon /> : <TrackChangesIcon />}
            backLink={`/project/${projectId}?tabIndex=7&lastVisited=${logframeId}`}
        >
            <MainCard>
                {!useAsDataCollectionModule && (
                    <>
                        <SubCard>
                            <div style={{ display: 'flex' }}>
                                <InputLabel style={{ marginTop: '1%', marginRight: '2%' }}> LOP: </InputLabel>
                                <TextField value={lop} onChange={(e: any) => setLOP(e.target.value)} disabled={!editMode} />
                            </div>
                        </SubCard>
                        <br />
                    </>
                )}
                <SubCard
                    title={useAsDataCollectionModule ? 'Data Collection' : 'Periodic Targets'}
                    secondary={
                        editMode && (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setSelectedData(defaultDataBasic);
                                    setOpenBasicFormDialog(true);
                                }}
                                startIcon={<AddIcon />}
                            >
                                Add
                            </Button>
                        )
                    }
                >
                    <TargetTable
                        dataList={dataList}
                        setDataList={setDataList}
                        handleSubmit={handleSubmit}
                        selectOne={selectOneData}
                        editMode={editMode}
                        useAsDataCollectionModule={useAsDataCollectionModule}
                        timePeriodData={timePeriodData}
                        targetVsCollectionData={targetVsCollectionData}
                        backLink={`/project/${projectId}?tabIndex=7&lastVisited=${logframeId}`}
                    />
                </SubCard>
                <BasicFormDialog
                    open={openBasicFormDialog}
                    dataBasic={selectedData}
                    // frequencies={frequencyList}
                    handleSave={saveData}
                    handleClose={() => setOpenBasicFormDialog(false)}
                    handleBasic={handleBasic}
                    showToast={showToast}
                    editMode={editMode}
                    useAsDataCollectionModule={useAsDataCollectionModule}
                    timePeriodData={timePeriodData}
                    openDisaggregationTable={(e: any) => {
                        setOpenDisaggregationDialog(true);
                        setOpenBasicFormDialog(false);
                    }}
                />

                <DisaggregationTargetTable
                    settings="target"
                    open={openDisaggregationDialog}
                    dGroups={disaggregationGroups}
                    dTypes={dTypes}
                    dValues={dValues}
                    // frequency={frequency}
                    handleSubmit={handleDataSubmission}
                    rootTree={selectedData.data}
                    showToast={showToast}
                    editMode={editMode}
                    handleClose={() => {
                        setOpenDisaggregationDialog(false);
                        setOpenBasicFormDialog(true);
                    }}
                />
            </MainCard>
        </Wrapper>
    );
}
