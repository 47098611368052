// assets
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupsIcon from '@mui/icons-material/Groups';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import LogDefinitionIcon from '@mui/icons-material/Description';
import LogframeIcon from '@mui/icons-material/Timeline';
import ActivityIcon from '@mui/icons-material/AssignmentTwoTone';
import LocationIcon from '@mui/icons-material/LocationCity';
import SettingsIcon from '@mui/icons-material/Settings';
import ConfirmButton from 'components/common/ConfirmButton';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import langString from 'utils/langString';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

// tabs option
export const tabsOption: { label: string; icon: JSX.Element }[] = [
    {
        label: langString('description'),
        icon: <InsertDriveFileIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('teams'),
        icon: <GroupsIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('allforms'),
        icon: <BackupTableIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('dataSheet'),
        icon: <ListAltIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('allreports'),
        icon: <SummarizeIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('module'),
        icon: <ViewModuleIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('logDefinition'),
        icon: <LogDefinitionIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('logFrame'),
        icon: <LogframeIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('sites'),
        icon: <LocationIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('activity'),
        icon: <ActivityIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: langString('configuration'),
        icon: <SettingsIcon sx={{ fontSize: '1.3rem' }} />
    }
];

export const tabStyles = (theme: any) => ({
    mb: 3,
    '& a': {
        minHeight: 'auto',
        minWidth: 10,
        py: 1.5,
        px: 1,
        mr: 2.25,
        color: theme.palette.grey[600],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& a.Mui-selected': {
        color: theme.palette.primary.main
    },
    '& .MuiTabs-indicator': {
        bottom: 2
    },
    '& a > svg': {
        marginBottom: '0px !important',
        mr: 1.25
    }
});

export type ProjectType = {
    id: string | number;
    name: string;
    startDate: string | null;
    endDate: string | null;
    partnerOrganization: any;
    description: string;
    isActive: boolean;
    isArchived: boolean;
    isPublished: boolean;
};
export type Category = {
    categoryValue: string;
    id?: string;
    categoryName: any[];
    parent: any;
};

export type Item = {
    itemValue: string;
    id?: string;
    itemName: any[];
    parent: any;
};

export type Language = {
    id: string;
    name: string;
    code: string;
    isDefault?: boolean;
};

export const ProjectInitialValues = {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    partnerOrganization: [],
    description: '',
    isActive: false,
    isArchived: false,
    isPublished: false
};

export const teamColumns = ({ deleteListItem }: any) => [
    { header: 'User Name', accessor: 'username' },
    { header: 'Organization', accessor: 'orgName' },
    { header: 'role', accessor: 'roleName' },
    {
        header: 'Action',
        accessor: 'action',
        content: (item: any) => (
            <>
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    component={Link}
                    to={`/projects/${item.projectId}/member/${item.userId}/sites`}
                    size={'small'}
                    sx={{ marginLeft: 0.5 }}
                >
                    <LocationCityOutlinedIcon />
                </Button>
                <ConfirmButton
                    variant="outlined"
                    buttonText={<DeleteForeverIcon />}
                    subTitle={`Delete Team Member: ${item.username}`}
                    confirmed={() => deleteListItem(item.userId)}
                />
            </>
        )
    }
];

export const teamNonMemberColumns = () => [
    { header: 'User Name', accessor: 'username' },
    { header: 'Organization', accessor: 'orgName' },
    {
        header: langString('Role'),
        accessor: 'id'
        // content: (item: any) => (<p> {item.roles.map((r: any) => r.name).join(',  ')} </p>)
    },
    {
        header: 'Action',
        accessor: 'action'
    }
];

export default {};
