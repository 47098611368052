import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Chip,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    TablePagination
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
// import axios from 'utils/axios';
// import { UserProfile } from '_mockApis/user-profile/types';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import { checkNullInfo } from 'utils/Helpers';

const ClientPagingTable = ({ data, columns, showSL }: any) => {
    const theme = useTheme();

    const [page, setPage] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    useEffect(() => {
        setCount(data?.length);
    }, [data]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setPage(0);
    };

    const getRowKey = (targetId: string | number) => {
        return data.findIndex((row: any) => row.id === targetId) + 1;
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return checkNullInfo(item[column.accessor]);
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {showSL && <TableCell sx={{ pl: 3 }}>#</TableCell>}
                            {columns.map((column: any, index: number) => (
                                <>
                                    {column.accessor === 'action' ? (
                                        <TableCell align="center" key={index}>
                                            {column.header}
                                        </TableCell>
                                    ) : (
                                        <TableCell key={index}>{column.header}</TableCell>
                                    )}
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                                <TableRow hover key={getRowKey(row.id)}>
                                    {showSL && <TableCell sx={{ pl: 3 }}>{getRowKey(row.id)}</TableCell>}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index}>{renderCell(row, column)}</TableCell>
                                    ))}
                                    {/* <TableCell>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs zeroMinWidth>
                                                <Typography align="left" variant="subtitle1" component="div">
                                                    {row.name}{' '}
                                                    {row.status === 'Active' && (
                                                        <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
                                                    )}
                                                </Typography>
                                                <Typography align="left" variant="subtitle2" noWrap>
                                                    {row.email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>{row.location}</TableCell>
                                    <TableCell>{row.friends}</TableCell>
                                    <TableCell>{row.followers}</TableCell>
                                    <TableCell>
                                        {row.status === 'Active' && (
                                            <Chip
                                                label="Active"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.success.light + 60,
                                                    color: theme.palette.success.dark
                                                }}
                                            />
                                        )}
                                        {row.status === 'Rejected' && (
                                            <Chip
                                                label="Rejected"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.orange.light + 80,
                                                    color: theme.palette.orange.dark
                                                }}
                                            />
                                        )}
                                        {row.status === 'Pending' && (
                                            <Chip
                                                label="Pending"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.warning.light,
                                                    color: theme.palette.warning.dark
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell align="center" sx={{ pr: 3 }}>
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Message">
                                                <IconButton color="primary" aria-label="delete" size="large">
                                                    <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="Block">
                                                <IconButton
                                                    color="primary"
                                                    sx={{
                                                        color: theme.palette.orange.dark,
                                                        borderColor: theme.palette.orange.main,
                                                        '&:hover ': { background: theme.palette.orange.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <BlockTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default ClientPagingTable;
