import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DisaggregationTable from './DisaggregationTable';
import { defaultCollectionBasic, filterDtypes, filterDvalues, Transition } from './constants';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import _ from 'lodash';
// import useSnackbar from 'hooks/useSnackbar';

export default function DialogTable(props: any) {
    const [collectionBasic, setCollectionBasic] = useState<typeof defaultCollectionBasic>(defaultCollectionBasic);
    const [refTree, setRefTree] = useState<any>(props.rootTree.data);
    // const toastMsg = useSnackbar();

    useEffect(() => {
        setRefTree(props.rootTree.data);
        console.log(' root tree is getting called ', props.rootTree);
        if (props.rootTree.total) {
            setCollectionBasic((prev) => {
                return { ...prev, total: props.rootTree.total };
            });
        } else {
            setCollectionBasic(defaultCollectionBasic);
        }
    }, [props.rootTree.data]);

    const handleCollectionEntry = (key: string, value: any) => {
        if (key == 'collection_period') {
            const result = props.dataEntries.find((d: any) => d.collectionPeriod === value);
            if (result) {
                setCollectionBasic({ ...collectionBasic, ...result.jsonData.collection_data_entry, [key]: value });
                // result.json_data.collection_data_entry.data;
                setRefTree(result.jsonData.collection_data_entry.data);
            } else {
                setCollectionBasic({ ...defaultCollectionBasic, [key]: value });
                setRefTree({});
            }
        } else {
            setCollectionBasic({ ...collectionBasic, [key]: value });
        }
    };

    const handleSubmit = () => {
        console.log({ collectionBasic });
        console.log('ref tree', refTree);
        const collectionData = {
            ...collectionBasic,
            data: refTree
        };

        console.log({ collectionData });
        if (collectionData.total) props.handleSubmit(collectionData);
        else props.showToast('Please add value in the total field', 'error');
    };

    const handleTreeChange = (key: any, data: any) => {
        console.log('tree change', data);
        const _cloneTree = _.cloneDeep(refTree);
        _cloneTree[key] = data;
        setRefTree(_cloneTree);
    };

    // const showFrequency = () => {
    //     try {
    //         return props.frequency.setting && props.frequency.setting.option ? true : false;
    //     } catch (ex) {
    //         toastMsg(
    //             'Settings has not been defined for this indicator. please add frequency, units of measurements and other related setti',
    //             'error'
    //         );
    //     }
    // };

    const getBasicInput = (setting: string) => {
        if (setting !== undefined && setting == 'target') {
            return (
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Total"
                            value={collectionBasic.total}
                            disabled={!props.editMode}
                            onChange={(e: any) => handleCollectionEntry('total', e.target.value)}
                        />
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container spacing={gridSpacing}>
                {/* {showFrequency() ? (
                    <Grid item xs={4} md={4}>
                        <Select
                            labelId="frequencyLabel"
                            id="frequency_label"
                            label="Frequency"
                            fullWidth
                            name="collection_period"
                            onChange={(e: any) => handleCollectionEntry('collection_period', e.target.value)}
                        >
                            {props.frequency.setting &&
                                props.frequency.setting.option &&
                                props.frequency.setting.option.map((f: any) => {
                                    return (
                                        <MenuItem value={f.key} key={f.key}>
                                            {f.value}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Grid>
                ) : null} */}
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Total"
                        value={collectionBasic.total}
                        onChange={(e: any) => handleCollectionEntry('total', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Input"
                        value={collectionBasic.other_input}
                        onChange={(e: any) => handleCollectionEntry('other_input', e.target.value)}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.handleClose} maxWidth={'xl'}>
                <DialogTitle>{'Disaggregation Table'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                            <SubCard title="">{getBasicInput(props.settings)}</SubCard>
                        </Grid>
                        {props.dGroups.map((dgroup: any, index: number) => (
                            <Grid item xs={12} md={12} key={index}>
                                <SubCard title="Disaggregations">
                                    <DisaggregationTable
                                        disaggregationIndex={index}
                                        refTree={refTree}
                                        collectionBasic={collectionBasic}
                                        dTypes={filterDtypes(dgroup, props.dTypes)}
                                        dValues={filterDvalues(dgroup, props.dValues)}
                                        editMode={props.editMode}
                                        handleTreeChange={(data: any) => handleTreeChange(index, data)}
                                    />
                                </SubCard>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {props.editMode && <Button onClick={handleSubmit}>Submit</Button>}
                    <Button onClick={props.handleClose} sx={{ color: 'red' }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
