import {useEffect, useState} from 'react';
import {ActivityPhase} from '../../../types';
import {useQuery} from '@tanstack/react-query';
import {activityPhaseKeys} from '../../../react_query/query-keys';
import {DEFAULT_STALE_TIME} from '../../../utils/Constants';
import {getActivityPhaseList} from '../../../services/reactQueryservices';

const UseGetActivityPhaseList = (dependencies: boolean = true) => {
    const [initiateFetchingActivityPhaseList, setInitiateFetchingActivityPhaseList] = useState(true);
    const [activityPhaseList, setActivityPhaseList] = useState<ActivityPhase[]>([]);

    const result = useQuery<ActivityPhase[], Error>({
        queryKey: activityPhaseKeys.all,
        queryFn: getActivityPhaseList,
        staleTime: DEFAULT_STALE_TIME,
        enabled: initiateFetchingActivityPhaseList && dependencies
    });

    useEffect(() => {
        if (result.data && !result.isError) {
            setActivityPhaseList(result.data);
        }
    }, [result.data]);

    return {
        activityPhaseList,
        ...result,
        setInitiateFetchingActivityPhaseList
    };
};

export default UseGetActivityPhaseList;
