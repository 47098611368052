import { Grid, TextField, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DisaggregationForm(props: any) {
    const classes: any = {};
    const [newTypeName, setNewTypeName] = useState<string>('');
    const [createButtonDisable, setCreateButtonDisable] = useState(true);

    useEffect(() => {
        const hasLabelAndCode = props.options.some((obj: any) => obj.label !== '' && 'code' in obj && obj.code !== '');
        if (hasLabelAndCode) {
            setCreateButtonDisable(false);
        } else {
            setCreateButtonDisable(true);
        }
    }, [props.options]);

    const optionValueChange = (index: number, e: any) => {
        const tempOptions = [...props.options];
        let opt = tempOptions[index];
        opt = { ...opt, [e.target.name]: e.target.value };
        tempOptions[index] = opt;
        props.onChange(tempOptions);
    };

    const removeOption = (index: any) => {
        const opt = [...props.options];
        opt.splice(index, 1);
        props.onChange(opt);
    };

    const addOption = () => {
        const opt = [...props.options];
        opt.push({ label: '', id: new Date().getTime() });
        props.onChange(opt);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Create Disaggregation type</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <h6>Type:</h6>
                    </Grid>
                    <Grid item xs={10}>
                        <TextField
                            name="option"
                            value={newTypeName}
                            style={{ width: '100%' }}
                            onChange={(e: any) => setNewTypeName(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    {props.options.map((data: any, i: number) => {
                        return (
                            <React.Fragment key={data.id}>
                                <Grid item xs={1} />
                                <Grid item xs={1}>
                                    <h6>{i + 1}: </h6>{' '}
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label={'item'}
                                        value={data.option}
                                        name="label"
                                        style={{ width: '100%' }}
                                        onChange={(e: any) => optionValueChange(i, e)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={'code'}
                                        value={data.option}
                                        name="code"
                                        style={{ width: '100%' }}
                                        onChange={(e: any) => optionValueChange(i, e)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.formField}>
                                    <DeleteForeverIcon style={{ color: 'red', marginTop: '12px' }} onClick={() => removeOption(i)} />
                                </Grid>
                                <Grid item xs={1} />
                            </React.Fragment>
                        );
                    })}
                    <Grid item xs={2} />
                    <Grid item xs={10} className={classes.formField}>
                        <Button variant="outlined" color="primary" style={{ width: '83%' }} onClick={addOption}>
                            <AddIcon /> Click to add another
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSubmit(newTypeName)} disabled={createButtonDisable}>
                    Create
                </Button>
                <Button onClick={props.handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
