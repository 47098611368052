import _ from 'lodash';
import Jsona from 'jsona';
import * as yup from 'yup';

const dataFormatter = new Jsona();

const disaggregationTypes = [
    { label: 'Age group', id: 2 },
    { label: 'Area', id: 3 },
    { label: 'Gender', id: 1 }

    // { label: 'ward', id: 4 },
];

const disaggregationValues = [
    { label: 'Male', id: 1, parent: 1 },
    { label: 'Female', id: 2, parent: 1 },
    { label: '10-19', id: 3, parent: 2 },
    { label: '20-29', id: 4, parent: 2 },
    { label: '30-39', id: 5, parent: 2 },
    { label: '40-49', id: 6, parent: 2 },
    { label: 'adabor', id: 7, parent: 3 },
    { label: 'dhanmondi', id: 8, parent: 3 }
    // { label: ' ward 1', id: 6, parent: 4 },
    // { label: ' ward 2', id: 7, parent: 4 },
    // { label: ' ward 3', id: 8, parent: 4 },
];

const indicatorTypes = [
    { label: 'Quantative', id: 1 },
    { label: 'Qualitative', id: 2 },
    { label: 'Percentage', id: 3 },
    { label: 'Number / Denominator', id: 4 }
];

const dataEntryInputs = [
    { label: 'Enter progress only', id: 1 },
    { label: 'Enter overall cumulative only', id: 1 },
    { label: 'Enter either', id: 1 }
];

const relationships = [
    { label: 'Dependent', id: 1 },
    { label: 'Same', id: 1 },
    { label: 'Independent', id: 1 }
];

const validationSchema = yup.object({
    name: yup.string().required('name is required'),
    description: yup.string().required('description is required'),
    duration: yup.array().required('duration is required')
});

const initialValues = {
    uom: '',
    frequency: '',
    indicator_type: '',
    data_entry_type: '',
    progress_vs_cumulative: '',
    baseline_time: '',
    baseline_value: '',
    data_source: '',
    data_collection_method: ''
};

const defaultDisaggregation = { id: 0, label: '', index: -1 };

const ADD_NEW_DISAGGREGATION_ID = -999;

const getDtypes = (data: any) => {
    return data.map((d: any) => ({ id: d.id, label: d.name }));
};

const getDvalues = (data: any) => {
    const result = data.map((d: any) => {
        if (d.DisaggregationItems.length > 0) {
            return d.DisaggregationItems.map((i: any) => ({ label: i.item, id: i.id, parent: d.id }));
        }
        return [];
    });
    return _.flatten(result);
};

const serializeDisaggregationOb = (rest: any) => {
    const data = {
        type: 'Disaggregations',
        ...rest
    };
    return dataFormatter.serialize({ stuff: data });
};

const sampleItem = [
    {
        item: 'kajdiuy',
        code: '76',
        disaggregation: '9'
    },
    {
        item: 'kajdiuy',
        code: '76',
        disaggregation: '9'
    }
];

const serializeDisaggregationItemOb = (itemList: any, disaggregation: any) => {
    const serialize = (item: any): any => {
        const data = {
            type: 'DisaggregationItems',
            ...item,
            disaggregation
        };
        return dataFormatter.serialize({ stuff: data });
    };

    itemList = itemList.map((i: any) => {
        return {
            ...serialize({ item: i.label, code: i.code }).data
        };
    });
    console.log('----------- || disaggregation item list || ----------------------');
    console.log(itemList);
    return { data: itemList };
};

export {
    disaggregationTypes,
    disaggregationValues,
    defaultDisaggregation,
    ADD_NEW_DISAGGREGATION_ID,
    indicatorTypes,
    dataEntryInputs,
    relationships,
    validationSchema,
    initialValues,
    getDtypes,
    getDvalues,
    serializeDisaggregationOb,
    serializeDisaggregationItemOb
};
