import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box, Button, Grid, Typography } from '@mui/material';
import ActionTable from 'components/Table/ActionTable';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setFormList } from 'store/formsReducer';
import { setCurrentProject } from 'store/projectReducer';
import { TRootState } from 'store/reducer';
import Loading from 'ui-component/Loding';
import axiosServices from 'utils/axiosServices';
import { CURRENT_PAGE, PAGE_SIZE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { projectGetURL } from 'utils/serverUrls';
import ProjectFormsCards from './Cards';
import { columns } from './constants';
import ProjectFormsFilter from './Filter';
import ProjectFormImport from './Import';
import ProjectFormEdit from './ProjectFormEdit';
import { fetchForms, updateStatus } from './Service';

type ProjectFormsDataType = {
    projectID: number;
};
export default function ProjectForms({ projectID }: ProjectFormsDataType) {
    const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
    const [published, setPublished] = useState(false);
    const [active, setActive] = useState(false);
    const [name, setName] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(CURRENT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
    const [formId, setFormId] = useState();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const snackbarMsg = useSnackbar();
    const { list: projectForms } = useSelector((state: TRootState) => state.form);
    const dispatch = useDispatch();
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const { id: projectId } = useParams();

    const init = async () => {
        const forms: any = await fetchForms({
            fromDate: dateRange[0],
            toDate: dateRange[1],
            projectId: projectID,
            formName: name,
            isPublished: published,
            isActive: active,
            currentPage: page,
            pageSize: rowsPerPage,
            snackbarMsg,
            dispatch
        });
        setCount(parseInt(forms?.count, 10));
        dispatch(setFormList(forms?.data));
    };

    const getCurrentProject = async () => {
        const response = await axiosServices.get(`${projectGetURL}`, { params: { id: projectId } });
        dispatch(setCurrentProject(await _deserailize(response.data)));
    };

    useEffect(() => {
        getCurrentProject();
    }, []);

    useEffect(() => {
        init();
    }, [projectID, page, rowsPerPage, dateRange, name, published, active]);

    return (
        <>
            <ProjectFormsCards projectID={projectID} />
            <Box sx={{ padding: 1.5, border: '1px solid #DDD', borderRadius: 2, marginTop: 2, marginBottom: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 600, marginBottom: 2 }}>{langString('formlist')}</Typography>
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <ProjectFormsFilter
                            dateRange={dateRange}
                            setDateRange={(value: DateRange<Date>) => setDateRange(value)}
                            published={published}
                            setPublished={(value: boolean) => setPublished(value)}
                            active={active}
                            setActive={(value: boolean) => setActive(value)}
                            name={name}
                            setName={(value: string) => setName(value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ textAlign: 'right' }}>
                        <Button disabled={currentProject?.isArchived} color="success" variant="contained" onClick={() => setOpen(true)}>
                            <Typography sx={{ color: '#FFF', fontSize: 15 }}> {langString('importform')}</Typography>
                        </Button>

                        {open && <ProjectFormImport open={open} setOpen={setOpen} refreshData={init} />}

                        {/* <Button disabled={currentProject?.isArchived} color="error" variant="contained" sx={{ marginLeft: 1 }}>
                            <NotificationsActiveIcon /> {langString('send')}
                        </Button> */}
                    </Grid>
                </Grid>
            </Box>

            {/* eslint-disable-next-line no-nested-ternary */}
            {typeof projectForms === 'undefined' ? (
                <Loading />
            ) : projectForms === null ? (
                <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                    Data not found
                </Typography>
            ) : (
                <ActionTable
                    projectId={projectId!}
                    rows={projectForms}
                    columns={columns({ projectID, projectForms, dispatch, snackbarMsg, navigate, disabled: currentProject?.isArchived })}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={(value: number) => setPage(value)}
                    setRowsPerPage={(value: number) => setRowsPerPage(value)}
                    idField="id"
                    statusField="active"
                    updateStatus={(row) => updateStatus({ row, projectForms, dispatch, snackbarMsg })}
                    copyRow={(id: number) => console.log(id)}
                    editRow={(id: any) => {
                        setOpenEdit(true);
                        setFormId(id);
                    }}
                />
            )}

            {openEdit && <ProjectFormEdit open={openEdit} setOpen={setOpenEdit} refreshData={init} formId={formId} />}
        </>
    );
}
