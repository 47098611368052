import { Grid } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';
import BasicSource from './BasicSource';
import Conditions from './conditions';
import axios from 'axios';
import deserialize from './constants';
import { StatusCodes } from 'http-status-codes';
import Disaggregations from './disaggregations';
import { useParams } from 'react-router-dom';
import { getDisaggregationListOfProject, getIndicatorSettingOfLogframe } from 'features/IndicatorSettings/Service';

const DataUploadConfig = () => {
    const { projectId, logframeId } = useParams();
    const auth = useAuth();
    const [disaggregations, setDisaggregations] = useState<any>([]);

    useEffect(() => {
        const init = async () => {
            const dResponse = await getDisaggregationListOfProject(projectId);
            // const dResponse = await axios.get(`${DISAGGREGATION_LIST}/${projectId}`, auth.headers());
            // const indicatorSettings = await axios.get(`${GET_INDICATOR_SETTINGS}/${logframeId}`, auth.headers());
            const indicatorSettings = await getIndicatorSettingOfLogframe(logframeId);
            const allDisaggregations = deserialize(dResponse.data);

            if (dResponse.status == StatusCodes.OK && indicatorSettings.status == StatusCodes.OK) {
                indicatorSettings.data.setting && assignDisaggregations(allDisaggregations, indicatorSettings.data.setting.disaggregation);
            }
        };
        init();
    }, []);

    const assignDisaggregations = (allDisaggregations: any, disaggreagtionGroups: any) => {
        const _dResult = disaggreagtionGroups.map((dg: any, index: number) => {
            return dg.map((dItem: any, dIndex: number) => {
                return allDisaggregations.find((d: any) => d.id == dItem.id);
            });
        });
        setDisaggregations(_dResult);
        console.log(_dResult);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <BasicSource />
            <Conditions />
            <Disaggregations disaggregations={disaggregations} />
        </Grid>
    );
};

export default DataUploadConfig;
