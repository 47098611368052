import React from 'react';
import { CollectionFrequencyCreationPayload } from 'types';
import * as Yup from 'yup';
import { Form, FormikProvider, FormikValues, useFormik } from 'formik';
import { Button, DialogActions, Divider, Grid, Stack, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const getInitialValues = (collectionFrequency: FormikValues | null, projectId: string) => {
    if (collectionFrequency) {
        return {
            id: collectionFrequency.id,
            frequency: collectionFrequency.frequency,
            definitionType: collectionFrequency.definitionType,
            projectId
        };
    }

    const newObj = {
        id: '',
        frequency: '',
        definitionType: '',
        projectId
    };

    return newObj;
};

export interface CollectionFrequencyFormProps {
    collectionFrequency: FormikValues | null;
    projectId: string;
    handleSave: (data: FormikValues) => void;
    onCancel: () => void;
    isEditMode: boolean;
}

const CollectionFrequencyForm = ({ collectionFrequency, handleSave, onCancel, projectId, isEditMode }: CollectionFrequencyFormProps) => {
    const isCreating = !isEditMode;

    const CollectionFrequencySchema = Yup.object().shape({
        frequency: Yup.string().required('Collection Frequency is required'),
        definitionType: Yup.string()
    });

    const formik = useFormik<CollectionFrequencyCreationPayload>({
        initialValues: getInitialValues(isEditMode ? collectionFrequency : null, projectId),
        validationSchema: CollectionFrequencySchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const toSubmitData = {
                    id: values.id,
                    projectId: values.projectId,
                    frequency: values.frequency,
                    definitionType: values.definitionType
                };
                await handleSave(toSubmitData);
                setSubmitting(false);
                onCancel();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const isFormComplete = () => {
        let isValid = values.frequency !== '';
        if (isCreating) return isValid;
        return (
            isValid &&
            (values.frequency !== collectionFrequency?.frequency || values.definitionType !== collectionFrequency?.definitionType)
        );
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <DialogTitle>{isCreating ? 'Add Collection Frequency' : 'Edit Collection Frequency'}</DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 3 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Collection Frequency"
                                {...getFieldProps('frequency')}
                                error={Boolean(touched.frequency && errors.frequency)}
                                helperText={touched.frequency && errors.frequency}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Definition Type"
                                {...getFieldProps('definitionType')}
                                error={Boolean(touched.definitionType && errors.definitionType)}
                                helperText={touched.definitionType && errors.definitionType}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button type="button" variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" disabled={isSubmitting || !isFormComplete()}>
                                    {isCreating ? 'Add' : 'Edit'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Form>
        </FormikProvider>
    );
};

export default CollectionFrequencyForm;
