import React, { Dispatch, SetStateAction } from 'react';
import { IReportingPeriod } from 'types';
import Dialog from '@mui/material/Dialog';
import ReportingPeriodForm from './Form';
import { FormikValues } from 'formik';
import useReportingPeriodCreateOrUpdate from 'hooks/consume_api/mutation/useReportingPeriodCreateOrUpdate';

interface ReportingPeriodTypeModalType {
    obj?: IReportingPeriod | null;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    handleModalClose: () => void;
    isModalOpen: boolean;
    isEditMode: boolean;
    reportingPeriodTypeId: string;
}

const ReportingPeriodTypeModal = ({
    obj = null,
    setIsModalOpen,
    handleModalClose,
    isModalOpen,
    isEditMode,
    reportingPeriodTypeId
}: ReportingPeriodTypeModalType) => {
    const { createOrUpdateReportingPeriodAsynchronously } = useReportingPeriodCreateOrUpdate();

    const handleSave = async (data: FormikValues) => {
        await createOrUpdateReportingPeriodAsynchronously(data);
    };

    return (
        <Dialog
            onBackdropClick={() => setIsModalOpen(true)}
            maxWidth="sm"
            fullWidth
            onClose={(event, reason) => {
                handleModalClose();
            }}
            open={isModalOpen}
            sx={{ '& .MuiDialog-paper': { p: 0 } }}
        >
            <ReportingPeriodForm
                obj={obj}
                handleSave={handleSave}
                onCancel={handleModalClose}
                reportingPeriodTypeId={reportingPeriodTypeId}
                isEditMode={isEditMode}
            />
        </Dialog>
    );
};

export default ReportingPeriodTypeModal;
