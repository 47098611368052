import React from 'react';
import langString from 'utils/langString';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SitesList from 'components/Sites';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

const MemberSites = () => {
    const { projectId, memberId } = useParams();

    const kwargs = {
        params: {
            projectId
        }
    };

    return (
        <>
            <Button
                sx={{ float: 'right' }}
                size="small"
                component={Link}
                to={`/project/${projectId}?tabIndex=1`}
                variant="contained"
                color="info"
            >
                <ArrowBackIosIcon />
                {langString('back')}
            </Button>
            <br />
            <SitesList title={'Project Members Sites:'} dataId={memberId!} valueKey={'memberId'} extra={kwargs} />
        </>
    );
};

export default MemberSites;
