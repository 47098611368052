import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useSnackbar from '../../hooks/useSnackbar';
import { gridSpacing } from '../../store/constant';
import LocationForm from './LocationForm';
import { GeoDataType, SiteDataType, SiteType } from './Constant';
import { StatusCodes } from 'http-status-codes';
import ServiceList from '../../services/index';

export default function SitesFormDialog({ dataId, dialogOpen, setDialogOpen, save, reload, valueKey, extra }: any) {
    const [siteTypeList, setSiteTypeList] = useState<SiteType[]>([]);
    const [siteSelectionNodeList, setSiteSelectionNodeList] = useState<SiteType[]>([]);
    const [selectedSiteType, setSelectedSiteType] = useState<number | string>('');
    const [siteDataList, setSiteDataList] = useState<SiteDataType>({});

    const toastMsg = useSnackbar();

    const init = async () => {
        const sitesListResponse = await ServiceList.getGeoTypeList();
        if (sitesListResponse) {
            setSiteTypeList(sitesListResponse);
        }
    };

    useEffect(() => {
        init();
    }, []);

    const makeSelectionNodesList = async (nodeId: number | string) => {
        const node: SiteType | undefined = siteTypeList.find((item) => item.id == nodeId);
        if (node) {
            setSiteSelectionNodeList((prev: SiteType[]) => {
                return [node, ...prev];
            });

            if (node.nodeParent) {
                setSiteDataList((prev: SiteDataType) => {
                    return { [node.id]: { dataList: [], selectedList: [] }, ...prev };
                });
                makeSelectionNodesList(node.nodeParent);
            } else {
                const payload = {
                    geoType: node.id
                };
                const sitesListResponse = await ServiceList.geoDataByParentList(payload, valueKey, dataId, extra);
                setSiteDataList((prev: SiteDataType) => {
                    return { [node.id]: { dataList: sitesListResponse, selectedList: [] }, ...prev };
                });
            }
        }
    };

    useEffect(() => {
        setSiteSelectionNodeList([]);
        setSiteDataList({});
        if (selectedSiteType !== '') {
            makeSelectionNodesList(selectedSiteType);
        }
    }, [selectedSiteType]);

    useEffect(() => {
        console.log('siteDataList ::: ', siteDataList);
    }, [siteDataList]);

    const isLeafChildSelected = () => {
        return selectedSiteType !== '' && siteDataList[selectedSiteType]?.selectedList.length > 0 ? false : true;
    };

    const handleSave = async () => {
        if (selectedSiteType !== '') {
            const geoDataList = siteDataList[selectedSiteType]?.selectedList.map((geoData: GeoDataType) => geoData.id);
            const payload = {
                sites: geoDataList
            };
            const status = await save(payload, dataId, valueKey, extra);
            if (status === StatusCodes.OK) {
                toastMsg('Successfully created!');
                await reload();
                setDialogOpen(false);
            } else {
                toastMsg('Something went wrong!', 'error');
                setDialogOpen(false);
            }
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add Site</DialogTitle>

                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ marginTop: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                id="outlined-select-site-type"
                                select
                                fullWidth
                                label="Site Type"
                                value={selectedSiteType}
                                onChange={(event: any) => setSelectedSiteType(event.target.value)}
                            >
                                <MenuItem value={''}>{'Select One'}</MenuItem>
                                {siteTypeList &&
                                    siteTypeList.map((option: any) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.geoDefinition}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        {siteSelectionNodeList.map((node: SiteType) => (
                            <Grid item xs={12}>
                                <LocationForm
                                    key={node.id}
                                    node={node}
                                    siteDataList={siteDataList}
                                    setSiteDataList={setSiteDataList}
                                    valueKey={valueKey}
                                    dataId={dataId}
                                    extra={extra}
                                />
                            </Grid>
                        ))}

                        <Grid item xs={4} />
                        <Grid item xs={2}>
                            <Button disabled={isLeafChildSelected()} onClick={handleSave} color="primary" variant="outlined">
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button onClick={() => setDialogOpen(false)} color="error" variant="outlined">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
