import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { sampleForm } from '../constant';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Grid, InputLabel, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Autocomplete } from '@mui/material';
import { gridSpacing } from 'store/constant';
import _ from 'lodash';
import useSnackbar from 'hooks/useSnackbar';
import ActivityAdd from 'features/Activity/Add';
import { SDGType } from './constant';
import { getSdgList } from '../Service';

export default function FormDialog(props: any) {
    const [simpleName, setSimpleName] = useState<any>('');
    const [nodeCode, setNodeCode] = useState<any>('');
    const [oneSubmit, setOneSubmit] = useState<boolean>(false);
    const [aggregatedIndicatorSelected, setAggregatedIndicatorSelected] = useState<boolean>(false);
    const [sdgList, setSdgList] = useState<SDGType[]>([]);
    const [selectedSdgList, setSelectedSdgList] = useState<SDGType[]>([]);
    const [selectedSdgIdList, setSelectedSdgIdList] = useState<number[]>([]);

    // const selectedSdgIdList: number[] = selectedSdgList.map((item: SDGType) => item.id);

    const toastMsg = useSnackbar();

    const handleSubmit = () => {
        console.log(props.nodeProps);
        if (!_.isEmpty(simpleName)) {
            //  this state has used to prevent multiple clicks;
            setOneSubmit(true);
            if (!oneSubmit) {
                const editDict: any = {};
                if (props.nodeProps.edit) {
                    editDict.id = props.nodeProps.node.node.uid;
                    editDict.parent = props.nodeProps.node?.parentNode?.uid;
                }
                props.onSubmit({
                    ...editDict,
                    name: simpleName,
                    code: nodeCode,
                    config: props.nodeProps.config,
                    aggregatedIndicator: props.isIndicatorNode && aggregatedIndicatorSelected,
                    selectedSdgList
                });
            }
            setTimeout(() => {
                setOneSubmit(false);
            }, 1000);
        } else toastMsg('Please enter a name', 'error');
    };

    const handleActivitySubmit = (activityOb: any) => {
        console.log('Activity Submit: ', props.nodeProps);
        props.onActivitySubmit({ ...activityOb, config: props.nodeProps.config });
    };

    useEffect(() => {
        if (props?.nodeProps?.edit) {
            setNodeCode(props.nodeProps.node?.node?.code);
            setSimpleName(props.nodeProps.node?.node?.title);
            setAggregatedIndicatorSelected(props.nodeProps.node?.node?.isAggregatedIndicator);
            const initialSdgList: SDGType[] = props.nodeProps.node?.node?.sdgList?.map((sdgItem: any) => {
                return { id: sdgItem.sdg.id, sdgName: sdgItem.sdg.sdgName };
            });
            setSelectedSdgList(initialSdgList);
        } else {
            setNodeCode('');
            setSimpleName('');
            setAggregatedIndicatorSelected(false);
            setSelectedSdgList([]);
        }
    }, [props.nodeProps]);

    useEffect(() => {
        setSelectedSdgIdList(selectedSdgList.map((item: SDGType) => item.id));
    }, [selectedSdgList]);

    const updateSdgList = async () => {
        const response = await getSdgList();
        if (response) {
            const dataList: SDGType[] = response.data.map((data: any) => {
                return {
                    id: data.id,
                    sdgName: data.sdgName
                };
            });
            setSdgList(dataList);
        }
    };

    useEffect(() => {
        if (props.open) {
            updateSdgList();
        } else {
            setSdgList([]);
            setSelectedSdgList([]);
        }
    }, [props.open]);

    const isActivity = () => {
        return props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.isActivity;
    };

    const hasSdgSelection = () => {
        return props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.isSdgBased;
    };

    const removeDuplicates = (array: any[], property: string) => {
        const counts: any = {};
        const resultArray: any = [];

        // Count occurrences of each property value
        array.forEach((item) => {
            const key = item[property];
            counts[key] = (counts[key] || 0) + 1;
        });

        // Filter the array to keep only items that appear once
        array.forEach((item) => {
            const key = item[property];
            if (counts[key] === 1) {
                resultArray.push(item);
            }
        });

        return resultArray;
    };

    console.log(' form dialog is getting called ', props);
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={isActivity() ? 'lg' : 'sm'}
                open={props.open}
                onClose={() => props.closeNewNodePopup()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {props.nodeProps.edit ? 'Edit' : 'New'}{' '}
                    {props.nodeProps.config && props.nodeProps.config.data && props.nodeProps.config.data.attributes.nodeJson.data.name}
                </DialogTitle>

                <DialogContent>
                    {isActivity() ? (
                        <ActivityAdd info={props.nodeProps} onSubmit={handleActivitySubmit} closeDialog={() => props.closeNewNodePopup()} />
                    ) : (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <InputLabel> Code: </InputLabel>
                                <TextField fullWidth value={nodeCode} onChange={(e: any) => setNodeCode(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel> Name: </InputLabel>
                                <TextField fullWidth value={simpleName} onChange={(e: any) => setSimpleName(e.target.value)} />
                            </Grid>
                            {hasSdgSelection() && (
                                <Grid item xs={12}>
                                    <InputLabel> SDG: </InputLabel>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            disableCloseOnSelect
                                            id="checkboxes-tags-role"
                                            options={sdgList}
                                            value={selectedSdgList}
                                            getOptionLabel={(option: any) => option.sdgName}
                                            renderOption={(_props: any, option: any, { selected }: any) => {
                                                // console.log(' ::::::new render::::::: ', { selected, selectedSdgIdList });

                                                return (
                                                    <li {..._props}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected || selectedSdgIdList.includes(option.id)}
                                                        />
                                                        {option.sdgName}
                                                    </li>
                                                );
                                            }}
                                            onChange={(event: any, value: any) => {
                                                setSelectedSdgList((prev: SDGType[]) => {
                                                    return removeDuplicates(value, 'id');
                                                });
                                            }}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            {props.isIndicatorNode && (
                                <Grid item={true} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={aggregatedIndicatorSelected}
                                                    onChange={(e) => {
                                                        setAggregatedIndicatorSelected(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label="Aggregated Indicator"
                                        />
                                    </FormGroup>
                                </Grid>
                            )}
                            <Grid item xs={4} />
                            <Grid item xs={2}>
                                <Button onClick={handleSubmit} color="primary" variant="outlined">
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={() => props.closeNewNodePopup()} color="error" variant="outlined">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
