import { Card, IconButton, Typography } from '@mui/material';
import React, { memo, CSSProperties } from 'react';
import { Handle, Position, NodeProps } from 'react-flow-renderer';
import './style.css';
import AddIcon from '@mui/icons-material/Add';
import UpdateDialog from './UpdateNodeDialogue';
import EditIcon from '@mui/icons-material/Edit';

const targetHandleStyle: CSSProperties = { background: '#555' };
const sourceHandleStyleA: CSSProperties = { ...targetHandleStyle, margin: 'auto' };

const uid = function () {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const getId = (id: any) => `${id}_${uid()}`;

const DefaultNode: React.FC<NodeProps> = (data: any) => {
    const [nodeEntry, setNodeEntry] = React.useState<{ [key: string]: any }>({
        level: null,
        parent: null,
        id: '',
        name: '',
        description: '',
        color: '',
        isActivity: false,
        isSdgBased: false
    });
    const [editable, setEditable] = React.useState<boolean>(false);
    const [faqModal, setFaqModal] = React.useState<boolean>(false);
    const { onRemove, onElementsAdd, onEdit, level, name, description } = data.data;
    const { yPos, id } = data;

    const onSubmit = async () => {
        console.log('-------->', nodeEntry);
        const formattedData: any = {
            parent: nodeEntry.parent,
            level: nodeEntry.level,
            name: nodeEntry.name,
            form_url: nodeEntry.form_url,
            description: nodeEntry.description,
            isActivity: nodeEntry.isActivity,
            isSdgBased: nodeEntry.isSdgBased,
            onRemove: onRemove,
            onEdit: onEdit,
            color: nodeEntry.color,
            onElementsAdd: onElementsAdd
        };

        if (editable) {
            onEdit(id, formattedData);
        } else {
            const newNode = {
                id: getId(id),
                type: 'childNode',
                data: formattedData,
                position: { x: 300, y: parseInt(yPos, 10) + 90 }
            };
            onElementsAdd(id, newNode);
        }
        setFaqModal(false);
        setNodeEntry({ ...nodeEntry, level: null, parent: '', id: '', name: '', description: '', isActivity: false });
    };

    const setValue = (event: any) => {
        console.log('name: ', event.target.name, '   value: ', event.target.value);
        setNodeEntry({ ...nodeEntry, [event.target.name]: event.target.value });
    };

    return (
        <>
            {
                <UpdateDialog
                    open={faqModal}
                    // editable={editable}
                    onOkay={onSubmit}
                    onClose={(e: any) => {
                        setFaqModal(false);
                        setNodeEntry({ ...nodeEntry, id: '', name: '', description: '', isActivity: false });
                    }}
                    closeText={`Close`}
                    headerText={editable ? `Update Node` : `Create Node`}
                    nodeInfo={nodeEntry}
                    setValue={setValue}
                />
            }
            <Card className={'default'}>
                <div className={'cardUpdateAction'}>
                    <IconButton
                        aria-label="add to favorites"
                        onClick={(e) => {
                            console.log(level);
                            e.preventDefault();
                            setEditable(true);
                            setNodeEntry({
                                ...nodeEntry,
                                level: level,
                                id: id,
                                name: name,
                                description: description
                            });
                            setFaqModal(true);
                        }}
                    >
                        <EditIcon className={'updateIcon'} />
                    </IconButton>
                </div>
                <div className={'defaultBody'}>
                    <Typography variant="caption">{name}</Typography>
                </div>
                <div className={'defaultAddAction'}>
                    <IconButton
                        aria-label="add to favorites"
                        style={{ marginLeft: -2 }}
                        onClick={(e) => {
                            e.preventDefault();
                            setEditable(false);
                            setNodeEntry({ ...nodeEntry, parent: name, level: level + 1, name: '', description: '', isActivity: false });
                            setFaqModal(true);
                        }}
                    >
                        <AddIcon className={'addIcon'} />
                    </IconButton>
                </div>
                <Handle type="source" position={Position.Bottom} style={sourceHandleStyleA} />
            </Card>
        </>
    );
};

export default memo(DefaultNode);
