import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { client } from 'react_query/query-client-configuration';
import { rptKeys } from 'react_query/query-keys';
import { deleteRPT } from 'services/reactQueryservices';

const useRPTDelete = () => {
    const snackbar = useSnackbar();
    const { mutateAsync, mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: deleteRPT,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Failed to delete Content', 'error');
            } else {
                client.invalidateQueries({ queryKey: rptKeys.all });
                snackbar('Successfully deleted');
            }
        }
    });

    return {
        deleteRPTSynchronously: mutate,
        deleteRPTAsynchronously: mutateAsync,
        isRPTDeleteLoading: isLoading,
        rptDeleteError: error,
        isRPTDeleteError: isError,
        isRPTDeleteSuccess: isSuccess
    };
};

export default useRPTDelete;
